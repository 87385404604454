import React, { useState } from "react"
import { Formik, Field, Form, ErrorMessage } from "formik"
import * as yup from 'yup'
import { Heading, Sub } from "../../../Components/Typography";
import { SecondaryButton, LinkButton } from "../../../Components/Button";
import { IconLoader } from "../../../Components/Icons";
import suscription from "../../../integrations/suscription";
import { ToastError, ToastSuccess } from "../../../Components/Toast";
import moment from "moment"

let schema = yup.object().shape({
    fullName: yup.string().min(2, "Mínimo dos caracteres").required("Campo requerido"),
    email: yup.string().email('Correo no válido').required("Campo requerido"),
    birthDate: yup.mixed().transform((value, originalValue) => new Date(originalValue)).required("Campo requerido")
})

const EmailSuscribe = () => {
    const INITIAL_FORM_DATA = {
        fullName: '',
        birthDate: "",
        email: '',
    }

    const handleSubmit = async (values, actions) => {
        const data = { ...values, birthDate: moment(values.birthDate).toDate() }
        try {
            const res = await suscription.suscribre(data)
            actions.resetForm({ values: INITIAL_FORM_DATA })
            ToastSuccess('Suscripción exitosa.')
        } catch (error) {
            if (error && error.response && error.response.status && error.response.status===409) {
                ToastError("El correo ya está suscrito")
            } else {
                ToastError('Se produjo un error, intente más tarde.')
            }
        }
        actions.setSubmitting(false);
    }

    const isDate = (value) => {
        let temp = new Date(value);
        if (isNaN(temp.getTime()))
            return 'fecha no valida'
    }

    const MyErrMsg = msg => <span className="h-px text-xs text-red-500">{msg}</span>

    return <section className="lg:overflow-hidden lg:-mx-fullScreen py-10"
        style={{ backgroundColor: "rgb(244, 246, 253)" }}>
        <Heading>Recibe tu horóscopo diariamiente y promociones exclusivas</Heading>
        <div className="lg:px-fullScreen">
            <Formik
                initialValues={INITIAL_FORM_DATA}
                onSubmit={(values, actions) => { handleSubmit(values, actions) }}
                validationSchema={schema}
            >
                {({ isSubmitting, errors, touched, resetForm, dirty, values }) => (
                    <Form className="text-black md:flex md:flex-row px-5 justify-between items-end	my-10">
                        <div className="mb-3 md:mb-0 md:min-w-1/4 md:mr-5">
                            <label className="block mb-1 text-xs font-semibold"
                                htmlFor="fullName">Nombre(s)</label>
                            <ErrorMessage name="fullName" render={MyErrMsg} />
                            <Field
                                className={`relative text-sm rounded-md w-full px-3 py-2 border ${errors.fullName && touched.fullName ? 'border-red-500' : ''} h-10 focus:outline-none focus:border-secondary`}
                                name="fullName"
                                maxLength={19}
                                disabled={isSubmitting}
                            />
                        </div>
                        <div className="mb-3 md:mb-0 md:min-w-1/4 md:mr-5">
                            <label className="block mb-1 text-xs font-semibold"
                                htmlFor="email">Correo</label>
                            <ErrorMessage name="email" render={MyErrMsg} />
                            <Field
                                className={`relative text-sm rounded-md w-full px-3 py-2 border ${errors.email && touched.email ? 'border-red-500' : ''} h-10 focus:outline-none focus:border-secondary`}
                                name="email"
                                disabled={isSubmitting}
                            />
                        </div>
                        <div className="mb-3 md:mb-0 md:min-w-1/4 md:mr-5">
                            <label
                                className="block mb-1 text-xs font-semibold"
                                htmlFor="birthDate">Fecha de nacimiento </label>
                            <ErrorMessage name="birthDate" render={MyErrMsg} />
                            <Field
                                className={`relative text-sm rounded-md w-full px-3 py-2 border ${errors.birthDate && touched.birthDate ? 'border-red-500' : ''} h-10 focus:outline-none focus:border-secondary`}
                                type='date'
                                name="birthDate"
                                max={new Date().toISOString().substring(0, 10)}
                                disabled={isSubmitting}
                                validate={isDate}
                            />
                        </div>
                        <div>
                            {isSubmitting
                                ? <SecondaryButton
                                    type="button"
                                    className="px-5 py-3 text-sm w-26">
                                    <IconLoader
                                        className="text-white fill-current animate-spin"
                                        style={{ height: 20 }}
                                    />
                                </SecondaryButton>
                                : <SecondaryButton
                                    className="px-5 py-3 text-sm text-white"
                                    label="Suscribirse"
                                />
                            }
                        </div>
                    </Form>
                )}
            </Formik>
            <p className="text-gray-500 md:text-center">Como parte del servicio, también recibirás promociones exclusivas de PortalMístico. Puedes eliminar tu suscripción cuando lo desees.</p>
            <p className="text-gray-500 md:text-center">Al presionar sobre el botón de Suscribirse, aceptas haber leído y estar de acuerdo con nuestros <LinkButton className="ml-1 contents" to="/terminos-y-condiciones" label="términos y políticas de privacidad" /></p>
        </div>
    </section>
}

export default EmailSuscribe