import { useState } from "react"
import DropdownList from "../Dropdown/DropdownList"
import TextField from "../TextField"

const NumberList = ({ items, defaultValue, onChange, maxLength, label, placeholder, validate }) => {
   const [value, setValue] = useState(defaultValue)

   const handleChange = (newvalue) => {
      setValue(newvalue)
      if (typeof onChange === 'function') {
         onChange(newvalue)
      }
   }

   return (
      <div className="flex flex-row">
         <DropdownList items={items} onChange={handleChange}>
            <TextField defaultValue={value} onChange={handleChange} maxLength={maxLength} label={label} placeholder={placeholder} validate={validate} />
         </DropdownList>
      </div>
   )
}

export default NumberList