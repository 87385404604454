import assets from './../../Assets' 

const Glossary = () => {
   return (
      <div className="grid grid-cols-1 md:grid-cols-5">
         <div className="col-span-2 lg:pr-10 pb-3">
            <div className="mb-8">
               <span className="font-semibold" style={{ color: '#C0C9FF' }}>
                  Glosario
               </span>
            </div>
            <h1 className="text-3xl font-bold mb-3">Glosario</h1>
            <sub className="block text-base formatted-text-container">
               Conoce más de los términos psíquicos que usan nuestros expertos.
            </sub>
         </div>
         <div className="col-span-3">
            <img className="rounded mx-auto" src={assets.GlossaryImage} />
            <div>
               <div className="mt-10">
                  <h2>
                     Aceptación del cambio / Salir adelante
                  </h2>
                  <p>
                     Todos debemos afrontar cambios, más o menos inesperados, a
                     lo largo de nuestra vida. Nuestro entorno personal y profesional
                     se modifica constantemente y debemos aprender a adaptarnos y
                     aceptarlos para poder continuar de forma correcta. Aunque
                     no desees alguna de las cosas que puedan ocurrirte debes
                     modificar tu punto de vista y sacar el lado positivo o
                     constructivo de cualquier obstáculo para sacar provecho a
                     tu nueva situación.
                  </p>
               </div>
               <div>
                  <h2>
                     Ángeles
                  </h2>
                  <p>
                     En algunas religiones, ser espiritual, servidor y mensajero de Dios, que convencionalmente se representa como un joven o un niño bello y alado.
                  </p>
               </div>
               <div>
                  <h2>
                     Astrología
                  </h2>
                  <p>
                     Estudio de la posición y el movimiento de los astros, a través de cuya interpretación se pretende conocer el destino de las personas y pronosticar los sucesos terrestres.
                  </p>
               </div>
               <div>
                  <h2>
                     Aura
                  </h2>
                  <p>
                     Irradiación luminosa que algunas personas dicen percibir alrededor de los seres vivos.
                  </p>
               </div>
               <div>
                  <h2>
                     Carta astral
                  </h2>
                  <p>
                     La carta astral, también llamada carta natal, mapa natal, radix, o natividad es un diagrama usado por los astrólogos que representa, desde una perspectiva geocéntrica, las posiciones planetarias en signos y las casas astrológicas que ocuparon en determinado lugar (latitud y longitud) y tiempo (hora sidérea local) en relación a la fecha de nacimiento de cada persona, con la finalidad de realizar, bajo un cierto número de técnicas,1 interpretaciones psicológicas o incluso predecir el futuro del consultante.               </p>
               </div>
               <div>
                  <h2>
                     Cartomancia o lectura de cartas
                  </h2>
                  <p>
                     Procedimiento adivinatorio que consiste en predecir el futuro por medio de la interpretación de los naipes.
                  </p>
               </div>
               <div>
                  <h2>Chakras</h2>
                  <p>
                     Cada uno de los siete centros energéticos situados a lo largo de la columna vertebral que, según el hinduismo y otras filosofías orientales, ponen en contacto e interacción el cuerpo físico y el espíritu
                  </p>
               </div>
               <div>
                  <h2>Clariaudiente</h2>
                  <p>
                     Persona a la que se considera dotada de facultades paranormales de percepción extrasensorial, que le permitirían actuar de mediadora en la consecución de fenómenos parapsicológicos o comunicaciones con los espíritus.
                  </p>
               </div>

               <div>
                  <h2>Clarisintiente</h2>
                  <p>
                     Persona a la que se considera dotada de facultades paranormales de percepción extrasensorial, que le permitirían actuar de mediadora en la consecución de fenómenos parapsicológicos o comunicaciones con los espíritus.
                  </p>
               </div>

               <div>
                  <h2>Clarividente</h2>
                  <p>
                     Persona a la que se considera dotada de facultades paranormales de percepción extrasensorial, que le permitirían actuar de mediadora en la consecución de fenómenos parapsicológicos o comunicaciones con los espíritus.
                  </p>
               </div>

               <div>
                  <h2>Coach de vida</h2>
                  <p>
                     El coaching de vida es el arte de facilitar el desarrollo potencial de las personas para alcanzar objetivos coherentes y cambios en profundidad. En este proceso, el Coach ayuda a las personas a esclarecer sus metas, ya sean personales o laborales, y a ponerse en camino para alcanzarlas.
                  </p>
               </div>

               <div>
                  <h2>Compatibilidad astrológica</h2>
                  <p>
                     En Astrología la sinastría es un análisis de compatibilidad que hay entre una o más personas, basándose en sus cartas astrales. Dicho análisis se enfoca en explicar el comportamiento de la relación que hay entre esas personas, ayudando a entender (y en ocasiones predecir) los posibles comportamientos que estas seguirán; pudiéndose sacar partido de los puntos en que armonizarán y previniendo o superando los aspectos en los que desarmonizarán.
                  </p>
               </div>

               <div>
                  <h2>Conexión con ángeles</h2>
                  <p>
                     Todos tenemos la capacidad de comunicarnos y conectar con los ángeles, tanto para hacer peticiones como para recibir información.
                  </p>
               </div>

               <div>
                  <h2>Consulta sin instrumentos</h2>
                  <p>
                     No usa ningún instrumento o herramienta específica para realizar las consultas.
                  </p>
               </div>

               <div>
                  <h2>Consultas espirituales</h2>
                  <p>
                     Consultas relacionadas a cultivar tu espiritualidad y ayudarte a descubrir que es más significativo en tu vida.
                  </p>
               </div>

               <div>
                  <h2>Cristales de cuarzo</h2>
                  <p>
                     El cuarzo es un mineral compuesto de sílice. Dependiendo de su composición y color, los cuarzos tienen distintas propiedades.
                  </p>
               </div>

               <div>
                  <h2>Decodificación de emociones atrapadas</h2>
                  <p>
                     Busca en tus heridas del pasado las dolencias que padeces hoy.
                  </p>
               </div>

               <div>
                  <h2>Despedidas pendientes</h2>
                  <p>
                     Cuando un ser querido se marcha y las despedidas quedan pendientes, quedan muchos sentimientos atrapados y quizás necesites un espacio, acompañamiento, apoyo para expresar y compartir tu dolor ante la perdida.
                  </p>
               </div>

               <div>
                  <h2>Duelo de mascotas</h2>
                  <p>
                     El duelo por mascotas está poco reconocido socialmente, pero también existe. Cuando una mascota muere, su propietario puede sentir tristeza y culpa, y quizás necesites un espacio, acompañamiento, apoyo para expresar y compartir tu dolor ante la perdida.
                  </p>
               </div>

               <div>
                  <h2>Empático</h2>
                  <p>
                     Empático es el adjetivo que se utiliza para calificar a las personas que tienen la habilidad social de la empatía, la cual garantiza una interacción exitosa con las demás personas. Empatía es la capacidad para ponerse en el lugar del otro y hacérselo saber, de sentir como el otro siente comprendiendo sus motivos aún sin aceptarlos o discrepando con ellos.
                  </p>
               </div>
               <div>
                  <h2>Enfrentar a la muerte</h2>
                  <p>
                     Cuando un ser querido se marcha y las despedidas quedan pendientes, quedan muchos sentimientos atrapados y quizás necesites un espacio, acompañamiento, apoyo para expresar y compartir tu dolor ante la perdida.
                  </p>
               </div>

               <div>
                  <h2>Estilo de consulta: Compasivo</h2>
                  <p>
                     Dirigido a eliminar el sufrimiento y a producir bienestar en quien sufre.
                  </p>
               </div>

               <div>
                  <h2>Estilo de consulta: Directo</h2>
                  <p>
                     Va en línea recta hacia un fin o objetivo.
                  </p>
               </div>
            </div>

            <div>
               <div>
                  <h2>Estilo de consulta Expresivo</h2>
                  <p>
                     Muestra o manifiesta con gran viveza los pensamientos o los sentimientos.
                  </p>
               </div>

               <div>
                  <h2>Estilo de consulta: Reflexivo</h2>
                  <p>
                     Reflexiona antes de hablar o actuar.
                  </p>
               </div>

               <div>
                  <h2>Estilo de consulta: Sabio</h2>
                  <p>
                     Muestra conocimientos amplios y profundos, buen juicio, prudencia y madurez
                  </p>
               </div>

               <div>
                  <h2>Imanes</h2>
                  <p>
                     Pieza mineral o metálica que tiene la propiedad de atraer el hierro, el acero y otros cuerpos.
                  </p>
               </div>

               <div>
                  <h2>Interpretación de sueños</h2>
                  <p>
                     La interpretación de los sueños es el arte y la técnica de asignar significado a los diversos componentes, elementos e imágenes que aparecen en los sueños.
                  </p>
               </div>

               <div>
                  <h2>Interpretación de vidas pasadas</h2>
                  <p>
                     Es una técnica que se utiliza para recuperar lo que parecen ser recuerdos de vidas o encarnaciones pasadas. La regresión a vidas pasadas suele realizarse ya sea para encaminarse en la búsqueda de una experiencia espiritual personal o para un desarrollo psicoterapéutico.
                  </p>
               </div>

               <div>
                  <h2>Intuitivo</h2>
                  <p>
                     Proceso cognitivo que no está sujeto a un previo análisis o deducción lógica, sino que nace de una intuición o percepción sensorial evidente.
                  </p>
               </div>

               <div>
                  <h2>Lazos energéticos</h2>
                  <p>
                     Son una especie de hilos o pequeños tubos de energía que se crean entre las personas y por los cuales se manda información o energía. Estos lazos van de chakra a chakra, uniendo todos nuestros chakras a las personas con las que nos relacionamos, y principalmente uniendo los Chakras del Corazón.
                  </p>
               </div>

               <div>
                  <h2>Lectura de cartas</h2>
                  <p>
                     Procedimiento adivinatorio que consiste en predecir el futuro por medio de la interpretación de los naipes.
                  </p>
               </div>

               <div>
                  <h2>Lectura de tarot</h2>
                  <p>
                     La lectura del tarot se enmarca en la creencia de que las cartas pueden ser usadas para comprender situaciones actuales y futuras de la persona consultante. Algunos dicen que las cartas son guiadas por una fuerza espiritual como guía, mientras otros creen que las cartas los ayudan en introducirse a un inconsciente colectivo.
                  </p>
               </div>

               <div>
                  <h2>Ley de atracción</h2>
                  <p>
                     La ley de la atracción es la creencia pseudocientífica de que los pensamientos (conscientes e inconscientes) influyen sobre las vidas de las personas, argumentando que son unidades energéticas que devolverán a la persona una cantidad de energía similar a la emitida.
                  </p>
               </div>

               <div>
                  <h2>Manejo de la energía</h2>
                  <p>
                     Se basa en la creencia de que existe una energía vital que recorre todo el cuerpo humano. La meta del manejo de energía es equilibrar la corriente de energía en la persona. Se usa para reducir el estrés y la ansiedad, y promover el bienestar.
                     Los profesionales pueden clasificar la práctica como "con las manos", sin manos y a distancia (o ausente) cuando el paciente y el sanador se encuentran en diferentes lugares.
                  </p>
               </div>

               <div>
                  <h2>Meditación</h2>
                  <p>
                     Pensamiento o consideración de algo con atención y detenimiento para estudiarlo o comprenderlo bien.
                  </p>
               </div>

               <div>
                  <h2>Médium</h2>
                  <p>
                     Es un individuo de cualquier edad, género, educación o cultura del que se asegura le es posible establecer contacto con porciones de su inconsciente, y que le permiten desde allí acceder a realidades no ordinarias y eventualmente comunicarlas. No está muy claro que sea una actividad extrasensorial, sino probablemente mediada por el "sensorio" hacia zonas poco conocidas de la actividad psíquica.
                  </p>
               </div>

               <div>
                  <h2>Numerología</h2>
                  <p>
                     Estudio del significado oculto de los números y práctica de la adivinación asignando un número a cada letra del alfabeto y un significado especial a cada número.
                  </p>
               </div>

               <div>
                  <h2>Radiestesia</h2>
                  <p>
                     Facultad especial que se atribuye a determinadas personas de poder percibir las radiaciones electromagnéticas; se utiliza para descubrir manantiales y yacimientos subterráneos.
                  </p>
               </div>

               <div>
                  <h2>Reiki / Sanación</h2>
                  <p>
                     Es un tipo de medicina alternativa japonesa considerada como pseudoterapia englobada dentro de las "terapias de energía". Sus practicantes utilizan la imposición de manos o el toque terapéutico para desde las palmas transferir una "energía universal" (qì) hacia el paciente con el fin de promover la curación emocional o física.
                  </p>
               </div>

               <div>
                  <h2>Reprogramación transcendental</h2>
                  <p>
                     El objetivo es limpiar y desprogramar leyes personales, conflictos emocionales y/o traumas, encontrando la clave oculta que subyace en el subconsciente tras todo sufrimiento y comportamiento desequilibrado.
                  </p>
               </div>

               <div>
                  <h2>Sanación de lazos</h2>
                  <p>
                     Proceso de restauración de la salud de los lazos energéticos.
                  </p>
               </div>

               <div>
                  <h2>Señales de conexión</h2>
                  <p>
                     Identificación e interpretación de señales de conexión emocional profunda.
                  </p>
               </div>

               <div>
                  <h2>Sinastría</h2>
                  <p>
                     En Astrología la sinastría es un análisis de compatibilidad que hay entre una o más personas, basándose en sus cartas astrales. Dicho análisis se enfoca en explicar el comportamiento de la relación que hay entre esas personas, ayudando a entender (y en ocasiones predecir) los posibles comportamientos que estas seguirán; pudiéndose sacar partido de los puntos en que armonizarán y previniendo o superando los aspectos en los que desarmonizarán.
                  </p>
               </div>

               <div>
                  <h2>Superando el pasado</h2>
                  <p>
                     Recordar el pasado juega un papel crucial en nuestro funcionamiento como humanos, lo importante es como hacer para que no nos afecte negativamente y podamos vivir una vida plena.
                  </p>
               </div>

               <div>
                  <h2>Tarot</h2>
                  <p>
                     El tarot es una baraja de naipes a menudo utilizada como medio de consulta e interpretación de hechos (presentes, pasados o futuros), sueños, percepciones o estados emocionales que constituye, además, un tipo de cartomancia.
                  </p>
               </div>

               <div>
                  <h2>Terapia de Biomagnetismo</h2>
                  <p>
                     Es una práctica de medicina alternativa pseudocientífica que implica el uso de campos magnéticos estáticos.
                  </p>
               </div>
            </div>
         </div>
      </div>
   )
}

export default Glossary