const  storeActions = {
    SET_GENERAL_CONFIGURATION: 'SET_GENERAL_CONFIGURATION',
    SET_TOKEN: 'SET_TOKEN',
    SET_TOKEN_VOICE: 'SET_TOKEN_VOICE',
    SET_DEVICE: 'SET_DEVICE',
    SET_USER: 'SET_USER',
    SET_WS: 'SET_WS',
    LOGOUT: 'LOGOUT',
    LOGIN: 'LOGIN',
    LINK_HOROSCOPES: 'LINK_HOROSCOPES'
}

export default storeActions