import React, { useState, useRef, useEffect, useContext } from "react"
import Dialog from "../../../../Layouts/Dialog"
import ImageUploader from "./ImageUploader"
import { AlertIcon } from "../../../../Components/Icons"
import { SecondaryButton } from "../../../../Components/Button"
import UserAvatar from "../../../../Components/UserAvatar"
import { Context } from '../../../../store/useGlobalState'
import profile from "../../../../integrations/profile"
import { ToastError, ToastSuccess } from "../../../../Components/Toast";
import { IconLoader } from "../../../../Components/Icons"

const ImageController = ({ editable, reLoadData }) => {
    const { globalState } = useContext(Context)
    const { user } = globalState
    const [showPhotoDialog, setShowPhotoDialog] = useState(false)
    const [showPhoto, setShowPhoto] = useState(false)
    const [showUpploader, setShowUpploader] = useState((false))
    const [showRemoveImage, setShowRemoveImage] = useState(false)
    const [saving, setSaving] = useState(false)
    const thumbnailRef = useRef(null);
    const photoOptionsRef = useRef(null);

    useEffect(() => {
        /**
         * Alert if clicked on outside of element
         */
        function handleClickOutside(event) {
            const photoOptionsRefOnClick = photoOptionsRef.current && photoOptionsRef.current.contains(event.target)
            if (thumbnailRef.current && !thumbnailRef.current.contains(event.target) && !photoOptionsRefOnClick) {
                setShowPhotoDialog(false)
            }
        }

        // Bind the event listener
        document.addEventListener("mouseup", handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener("mouseup", handleClickOutside);
        };
    }, [thumbnailRef, photoOptionsRef]);

    useEffect(() => {
        if (!editable && showPhotoDialog) setShowPhotoDialog(false)
    }, [editable]);

    const photoMenuToggle = () => {
        if (editable) {
            setShowPhotoDialog(prev => !prev)
        }
    }

    const togglePhoto = () => {
        setShowPhoto(prev => !prev)
    }

    const toggleUpploader = () => {
        setShowUpploader(prev => !prev)
    }
    const handleRemovePhoto = async () => {
        setSaving(true)
        try {
            const data = {
                image: null
            }
            const res = await profile.updateInfo(data)
            reLoadData()
            ToastSuccess("Datos guardados")
        } catch (error) {
            ToastError("Se produjo un error. Por favor inténtalo de nuevo más tarde.")
        }
        setShowRemoveImage(prev => !prev)
        setSaving(false)
    }

    return <>
        <div className={`${editable && "group"} w-30 h-30 block relative`}>
            <UserAvatar />
            <div onClick={photoMenuToggle} ref={thumbnailRef}
                className="cursor-pointer w-30 h-30 rounded-full absolute hidden group-hover:block z-10 top-0 text-white text-center p-5" style={{ background: "#0000009c" }}>
                Cambiar foto de perfil
            </div>
            {
                showPhotoDialog && <div className="absolute border rounded py-3 bg-white" ref={photoOptionsRef}>
                    {user?.image && user.image !== "" && <div onClick={togglePhoto} className="cursor-pointer px-3 py-1 mb-1 hover:bg-superLight">Ver foto</div>}
                    <div onClick={toggleUpploader} className="cursor-pointer px-3 py-1 mb-1 hover:bg-superLight">Cargar foto</div>
                    {user?.image && user.image !== "" && <div onClick={() => setShowRemoveImage(prev => !prev)} className="cursor-pointer px-3 py-1 mb-1 hover:bg-superLight">Eliminar foto</div>}
                </div>
            }
        </div>
        {
            showPhoto && <Dialog
                darkOverlay
                dismisable
                showCloseButton={true}
                onClose={togglePhoto}
                render={({ closeDialog }) =>
                    <img src={globalState?.user?.image} />
                }
            />
        }
        {
            showUpploader && <ImageUploader close={toggleUpploader} />
        }
        {
            showRemoveImage && <Dialog
                darkOverlay
                dismisable
                onClose={() => setShowRemoveImage(prev => !prev)}
                render={({ closeDialog }) =>
                    <div className="border-l-8 border-alert rounded-tl-xl rounded-bl-xl p-5">
                        <div className="flex items-center mb-5">
                            <AlertIcon className="w-10 m" />
                            <h2 className="ml-3 font-bold">Eliminar foto</h2>
                        </div>
                        <p>¿Estás seguro que deseas eliminar tu foto de perfil?</p>
                        <div className="flex justify-end mt-8">
                            <SecondaryButton
                                onClick={closeDialog}
                                className="px-6 py-3 text-xs tracking-wider focus:outline-none"
                                inverted
                                label="Cancelar"
                                dismisable={saving}
                            />
                            {
                                saving ? <SecondaryButton
                                    type="button"
                                    className="px-6 py-3 text-xs tracking-wider focus:outline-none">
                                    <IconLoader
                                        className="text-white fill-current animate-spin"
                                        style={{ height: 20 }}
                                    /></SecondaryButton> : <SecondaryButton
                                    type="button"
                                    onClick={handleRemovePhoto}
                                    className="px-6 py-3 text-xs tracking-wider focus:outline-none"
                                    label="Eliminar"
                                />
                            }

                        </div>
                    </div>
                }
            />
        }
    </>
}

export default ImageController