import React from 'react'
import assets from '../../Assets/'
import palete from '../../Assets/palete'
import { LinkButton } from '../../Components/Button'


const WhyUs = () => {
    return (
        <section className="mx-auto lg:text-left md:grid md:grid-cols-5 xl:-mx-fullScreen" >
            <div className="col-span-2 pt-3">
                <img src={assets.Home_WhyOurPlatform} className="w-full ml-auto mr-0" alt="Por qué Portal Místico es la mejor plataforma" />
            </div>
            <div className="flex flex-col col-span-3">
                <div className="max-w-4xl pl-5 md:pl-20">
                    <h1 className="flex-col pt-2 text-3xl tracking-wider">¿Por qué Portal Místico es la mejor plataforma?</h1>
                    <p className="flex-col mt-5 font-semibold tracking-widest text-gray-500">Por la experiencia, la comprensión y la atención de los expertos y el equipo de servicio al cliente.</p>
                    <div className="flex flex-col w-full lg:gap-y-6 md:gap-y-4 mt-14 xl:gap-y-10" >
                        <ReasonRow
                            icon={assets.Icon_Etica}
                            title="Código de ética"
                            text="Nuestros expertos se comprometen a tratar a los clientes con respeto, compasión y profesionalismo."
                        />
                        <ReasonRow
                            icon={assets.Icon_Expertos}
                            title="Los mejores expertos"
                            text="Conocimiento, dones, habilidades y experiencia. Cada uno de nuestros expertos cuenta con capacidades y estilos diferentes. Encuentra el que más te guste."
                        />
                        <ReasonRow
                            icon={assets.Icon_Recompensas}
                            title="Flexibilidad"
                            text="Haz tu consulta desde donde quieras y cuando quieras. Fácil de usar y directo. "
                        />
                    </div>
                </div>
            </div>
            <div className="col-span-5 mt-24">
                <div className="flex flex-row w-full mx-auto bg-indigo-50 lg:w-10/12 rounded-2xl xl:max-w-7/12">
                    <div className="flex flex-col pl-20 pr-10 text-center">
                        <h2 className="flex-row mt-10 mb-4 tracking-wide lg:text-3xl" style={{fontWeight: "bold"}}>
                            Satisfacción garantizada
                        </h2>
                        <p className="flex-row leading-normal" style={{color:"#7F7F8F"}}>
                            Mantenémos tu información segura con diferentes medidas de
                            seguridad. Así mismo, garantizamos nuestro servicio contigo,
                            si no estás satisfecho con tu última consulta pagada, !Te
                            devolvemos tu dinero!.
                        </p>
                        <p className="flex-row pt-4 mt-6 text-lg">
                            <LinkButton label="Conoce más" url="/satisfaccion-garantizada" className="text-lg" style={{fontWeight:"600"}} />
                        </p>
                    </div>
                    <div className="hidden xl:flex-col xl:inline-block">
                        <div className="flex-col items-center grid-cols-1 align-middle diagonal-fractions justify-items-center md:h-72" style={{ width: '30em', overflow: 'hidden' }}>
                            <div style={{ width: '25em', position: 'absolute', height: '18em' }} className="flex flex-col items-center content-end justify-center">
                                <div className="grid flex-col col-span-2 align-middle" style={{ position: "absolute", right: 0, zIndex: 1 }}>
                                    <span className=""><img src={assets.Home_ShieldCheck} className="w-full h-full" alt="Satisfacción garantizada" /></span>
                                </div>
                            </div>
                            <div style={{ height: '100%' }}>
                                <div style={{ background: '#E4E8FF', borderRadius: '50%', transform: 'translate(5em, -3.1em)', height: '25em', width: '25em' }}>
                                    <div style={{ background: '#DEE2FF', borderRadius: '50%', transform: 'translate(8em, 1em)', height: '22em', width: '22em' }}>
                                        &amp;nbsp;
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

// #region Subcomponentes
const ReasonRow = ({ title, icon, text }) => {
    return (
        <article className="flex flex-row w-2/5 text-sm gap-x-5" style={{width: "100%"}}>
            <div className="inline-grid flex-col items-center content-center col-span-1 mr-2">
                <span className="grid items-center content-center w-10 h-10 col-span-1 text-center text-white align-middle rounded-full" style={{ background: palete.pink }}>
                    <img src={icon} alt={title} className="inline-block mx-auto rounded-full" />
                </span>
            </div>
            <aside className="flex flex-col flex-grow col-span-11 text-left gap-y-2">
                <b className="flex-row tracking-wider text-gray-700">{title}</b>
                <p className="flex-row font-semibold" style={{color:'#7F7F8F'}}>{text}</p>
            </aside>
        </article>
    )
}
// #endregion

export default WhyUs