import MyLocalStorage, {lsKeys} from "../utils/localStorage"
import {setAxiosToken} from "../utils/axiosConfig"

const InitialState = {
    ws:null,
    /*
    mainBanner: 'https://paradigma-cms-files.s3.amazonaws.com/817lWBrFWp6wUFxzw0gBVsax.jpg',
    bannerTitle: 'Banner title edt',
    bannerCaption: 'Banner caption edt',
    promotionalText: 'Promotional text edt',*/
    mainBanner: null,
    bannerTitle: null,
    bannerCaption: null,
    promotionalText: null,
    contactNumber: '',
    contactEmail: '',
    ...(
        MyLocalStorage.read(lsKeys.sesion) ||
        {token: null, user: null, ws:null, tokenVoice:null}
    ),
    /* horoscopes linked to expers, TODO: handle pagination with query params on experts */
    horoscopesLinkedToExperts: false
}

if (InitialState.token)
    setAxiosToken(InitialState.token)

export default InitialState