import { useEffect, useState } from "react"
import DataGrid from "../../Pages/Profile/partials/DataGrid"
import { IconOxxoPay, IconPaypal } from "../Icons"
import RadioButton from "../RadioButton"
import assets from "../../Assets"
 

const OtherMethods = ({ onChange, selectedid }) => {
   const [paymentId, setPaymentId] = useState(selectedid)

   useEffect(() => {
      setPaymentId(selectedid)
   }, [selectedid])


   const CustomRow = ({ data }) => (
      <tr className="cursor-pointer" onClick={() => {
         if (typeof onChange === 'function') {
            setPaymentId(data.id)
            onChange({
               id: data.id,
               name: data.name,
               number: ''
            })
         }

         // setSelectedPaymentMethodName(data.name)
         // setSelectedPaymentMethodNumber('')
      }}>
         <td className="w-10 py-3 pl-6 lg:pl-8"><RadioButton checked={paymentId === data.id} className="h-6" /> </td>
         <td className="py-3 pl-6 text-xs text-gray-600">
            <div>
               {data.name}
            </div>
            {
               (data?.catpion) && (
                  <div>
                     <small className="text-gray-400">
                        {data?.catpion}
                     </small>
                  </div>
               )
            }
         </td>
         <td className="py-3 pr-6 text-xs text-gray-600 lg:pr-8">{data.icon}</td>
      </tr>
   )

   const paymethods = [
      {
         id: "paypal",
         name: "Paypal",
         icon: <img style={{ width: 58 }}
            className="h-8 ml-auto mr-2"
            src={assets.PayPal_Logo} />
      },
      {
         id: "oxxopay",
         name: "Oxxo Pay",
         icon: <IconOxxoPay className="h-8 ml-auto mr-0" />,
         catpion: 'La transacción puede tardar hasta 48 hrs'
      }
   ]

   return (
      <>
         <section className="flex flex-row mt-8 mb-4" >
            <div className="flex-col flex-grow">
               <p className="text-xs font-bold">Otros métodos de pago</p>
            </div>
         </section>
         <section>
            {/* <OtherPaymentMethods /> */}
            <DataGrid data={paymethods} row={CustomRow} />
         </section>
      </>
   )
}

export default OtherMethods