

import React, { useState, useContext } from 'react'
import { SecondaryButton, LinkButton, PseudoLink } from "../Button"
import Video from "../../Pages/Video"
import { Context, StoreActions } from '../../store/useGlobalState'
import { useHistory } from 'react-router-dom'
import assets from '../../Assets'
import moment from 'moment'


export const ModalScheduleSuccess = ({ visible, hideSelf, expert, date, time, confirmation }) => {
    const { globalDispatch } = useContext(Context)
    const { sessionType, phone, code } = confirmation || {}
    const history = useHistory();

    return (visible &&
        <div className="fixed flex h-full  z-20 justify-center items-center cursor-auto w-full top-0 left-0 right-0 bottom-0" style={{ width: "100vw", height: "100%" }}>
            {/*transparent background that hides modal when clicked*/}
            <div
                className={"fixed flex inset-0 z-10 bg-black opacity-70 w-full"}
                style={{}}
                onClick={hideSelf}
            />
            {/*actual modal*/}
            <div
                className={"absolute z-10 pt-5 pb-2 bg-white border rounded-2xl shadow-lg w-5/6 md:w-3/6"}

                style={{ borderColor: "#F4F6FD", margin: "auto" }}>

                <div className="flex flex-col justify-center align-center max-w-md px-6 pt-3 mx-auto text-center lg:max-w-7xl sm:max-w-3xl sm:px-0">
                    <h1 className="mt-2 text-2xl font-extrabold tracking-tight text-gray-900 sm:text-2xl">
                        ¡Agendamos tu cita!
                    </h1>
                    <p className="max-w-prose mt-3.5 mx-auto text-gray-500 text-1xl tracking-normal pb-3">
                        Gracias por confiar en nosotros
                    </p>
                    <div className="grid grid-cols-1 md:grid-cols-2">
                        <div className="col-span-1 w-full">
                            <img src={assets.videoCall} className="w-100 ml-0 md:ml-auto  " />
                        </div>
                        <div className="">
                            <p className="max-w-prose mt-3.5 mx-auto text-black-500 text-sm font-semibold tracking-normal pb-1">
                                Fecha
                            </p>
                            <p className="max-w-prose mx-auto text-gray-500 text-sm tracking-normal pb-3 capitalize">
                                {moment(date).format("dddd DD, YYYY")}
                            </p>
                            <p className="max-w-prose mt-3.5 mx-auto text-black-500 text-sm font-semibold tracking-normal pb-1">
                                Hora
                            </p>
                            <p className="max-w-prose mx-auto text-gray-500 text-sm tracking-normal pb-3 capitalize">
                                {moment(time, "HH:mm").format("h:mm A")}
                            </p>
                            {
                                sessionType === 'phone_call' && <>
                                    <p className="max-w-prose mt-3.5 mx-auto text-black-500 text-sm font-semibold tracking-normal pb-1">
                                        Número telefónico
                                    </p>
                                    <p className="max-w-prose mx-auto text-gray-500 text-sm tracking-normal pb-3 capitalize">
                                        {phone}
                                    </p>
                                    <p className="max-w-prose mt-3.5 mx-auto text-black-500 text-sm font-semibold tracking-normal pb-1">
                                        Código
                                    </p>
                                    <p className="max-w-prose mx-auto text-gray-500 text-sm tracking-normal pb-3 capitalize">
                                        {code}
                                    </p>
                                </>
                            }
                        </div>
                    </div>
                    <p className="max-w-prose mx-auto text-gray-500 text-sm tracking-normal pb-3 px-7 mb-3 ">
                        Tu cita fue agendada en la hora y fecha indicada, esperámos la aproveches al máximo
                    </p>
                    <div className=" px-2 pb-4 self-center	">
                        <SecondaryButton onClick={() => {
                            hideSelf()
                            history.push("/expertos")
                        }} label="Continúa explorando" className="px-7 py-3 mx-auto text-xs bg-secondary w-full rounded font-semibold" />

                    </div>
                </div>

            </div>
        </div>
    )
}

export default ModalScheduleSuccess
