import BlankLayout from "../Layouts/BlankLayout";
import PublicLayoutBox from "../Layouts/PublicLayoutBox";
import UserProfileLayout from "../Layouts/UserProfileLayout";
import ArticlesPage from "./Articles";
import ArticlesDetail from "./Articles/Detail";
import AuthenticatePage from "./Authenticate";
import LogoutPage from "./Authenticate/Logout";
import ConsultationPage from "./Consultation";
import ExpertsPage from "./Experts";
import ExpertDetail from "./Experts/Detail/index";
import Glossary from "./Glossary";
import HomePage from "./Home";
import HoroscopesPage from "./Horoscopes";
import MaintenancePage from "./Maintenance";
import NotFound from "./NotFound";
import OffersPage from "./Offers";
import ProfilePage from "./Profile";
import RegisterPage from "./Register";
import ResetPassword from "./ResetPassword";
import VideoPage from "./Video";
import PaymentSuccessful from "./PaymentSuccessful"
import Contact from "./Contact"
import ClientService from "./ClientService";
import Request from "./Request";
import Terms from "./Terms"
import Privacy from "./Privacy";
import Manifest from './Manifest'
import EthicCode from './EthicCode'
import HowWorks from './HowWorks'
import Prices from './Prices'
import Testimonials from './Testimonials'
import SatisfactionGuaranteed from './SatisfactionGuaranteed'
import FAQ from "./FAQ"
import RoomCall from "./RoomCall";
import TechncalRecommendations from "./TechnicalRecommendations"
import Unsbuscribe from "./Unsubscribe";
import SitemapComponent from "./Sitemap";

const HomeRoutesMaintenance = [
    {
        component: MaintenancePage,
        layout: BlankLayout,
        id: 'maintenance',
    }
];

let routes = process.env.REACT_APP_PORTAL_VISIBLE === 'false' && window.location.host === "www.portalmistico.com"
    ? HomeRoutesMaintenance
    : [
        //#region Home page
        {
            path: "/",
            exact: true,
            component: HomePage,
            id: 'home',
        },
        {
            path: "index.html",
            exact: true,
            component: HomePage,
            id: 'home-index',
        },
        {
            path: "/index.html",
            exact: true,
            component: HomePage,
            id: 'home-root-index',
        },
        //#endregion
        {
            path: '/articulos/:finalArticleCategory?',
            component: ArticlesPage,
            id: 'articles',
        },
        {
            path: '/articulo/:id?',
            component: ArticlesDetail,
            id: 'articles',
        },
        {
            path: '/autenticar/:token',
            component: AuthenticatePage,
            layout: BlankLayout,
            id: 'authenticate',
        },
        {
            path: '/consultas/:consultationId?/:subConsultationId?',
            component: ConsultationPage,
            id: 'consultation',
        },
        {
            path: '/expertos/:expertise?',
            component: ExpertsPage,
            id: 'experts',
        },
        {
            path: '/experto/:id?',
            component: ExpertDetail,
            id: 'experts',
        },
        {
            path: '/glosario/:query?',
            component: Glossary,
            id: 'glossary',
        },
        {
            path: '/horoscopos/:zodiacsign?',
            component: HoroscopesPage,
            id: 'horoscopes',
        },
        {
            path: '/cerrar-sesion',
            component: LogoutPage,
            id: 'logout',
        },
        {
            path: '/ofertas',
            component: OffersPage,
            layout: PublicLayoutBox,
            id: 'offers',
        },
        {
            path: '/perfil/:step?',
            component: ProfilePage,
            layout: UserProfileLayout,
            id: 'profile',
        },
        {
            path: '/registrarse',
            component: RegisterPage,
            id: 'register',
        },
        {
            path: '/cambiar-contrasena', // last path: reset-password
            component: ResetPassword,
            id: 'resetPassword',
        },
        {
            path: '/video',
            component: VideoPage,
            id: 'video',
        },
        {
            path: '/pago-exitoso/:orderId',
            component: PaymentSuccessful,
            id: 'paymentSuccessful',
        },
        {
            path: '/contacto',
            component: Contact,
            id: 'contact',
        },
        {
            path: '/cancelar-suscripcion', //last path: unsubscribe
            component: Unsbuscribe,
            id: 'unsubscribe'
        },
        {
            path: '/servicio-al-cliente',
            component: ClientService,
            id: 'clientService',
        },
        {
            path: '/manifiesto',
            component: Manifest,
            id: 'manifest',
        },
        {
            path: '/codigo-de-etica',
            component: EthicCode,
            id: 'ethicCode',
        },
        {
            path: '/como-funciona',
            component: HowWorks,
            id: 'howWorks',
        },
        {
            path: '/precios',
            component: Prices,
            id: 'prices',
        },
        {
            path: '/testimonios',
            component: Testimonials,
            id: 'testimonials',
        },
        {
            path: '/preguntas-frecuentes',
            component: FAQ,
            id: 'faq',
        },
        {
            path: '/satisfaccion-garantizada',
            component: SatisfactionGuaranteed,
            id: 'satisfactionGuaranteed',
        },
        {
            path: '/peticion-de-expertos',
            component: Request,
            id: 'request',
        },
        {
            path: '/terminos-y-condiciones',
            component: Terms,
            id: 'terms',
        },
        {
            path: '/politicas-de-privacidad',
            component: Privacy,
            id: 'privacy',
        },
        {
            path: '/sala-de-llamada',
            component: RoomCall,
            id: 'roomCall',
        },
        {
            path: '/recomendaciones-tecnicas',
            component: TechncalRecommendations,
            id: 'thecnical-recommendations'
        },
        {
            path: '/mapa-del-sitio',
            component: SitemapComponent,
            id: 'sitemap'
        },
        // NOT FOUND ERROR PAGE
        {
            component: NotFound,
            id: 'not_found',
        },
    ];


export default routes;
