import { useContext, useEffect } from "react";
import { setAxiosToken } from "../../utils/axiosConfig";
import myLocalStorage, {} from "../../utils/localStorage";
import {Context, StoreActions} from '../../store/useGlobalState'
import { useHistory } from "react-router-dom";

const LogoutPage = () => {
   const {globalDispatch} = useContext(Context)
   const history = useHistory();

   useEffect(() => {
      myLocalStorage.clear()
      setAxiosToken(null)
      globalDispatch({type: StoreActions.LOGOUT})
      history.push('/')
   }, [globalDispatch])

   return 'Cerrando sesión'
}

export default LogoutPage