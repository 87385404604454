import momentTz from "moment-timezone"
import timezoneList from "./timezone"

const timeZoneParser = (expert, date, format, formatToShow="h:mm A") => {
    const expertTimezone = timezoneList.find(x => x.value === expert?.schedule?.timezone)
    // set expert time zone as default
    const expertMoment = momentTz.tz(date, format, expertTimezone.utc[0])
    // timezone parser
    const userTimeZone = momentTz.tz.guess(true)
    return expertMoment.tz(userTimeZone).format(formatToShow)
}

export default timeZoneParser