import React, { useEffect, useState } from "react";
import { useParams, Link } from "react-router-dom";
//Components
import articles from "../../../integrations/article"
import Recommended from "../../../Components/Recommended"
import moment from 'moment'
import assets from "../../../Assets"
import { IconFacebook, IconInstagramSVG, IconEnvelopeSVG, UserProfileIcon } from "./../../../Components/Icons"
import { FacebookShareButton, EmailShareButton } from "react-share";
import { Helmet } from "react-helmet";

const ArticlePage = () => {
    const [article, setArticle] = useState(null)
    const [stars, setStarts] = useState([])
    let { id } = useParams()

    useEffect(async () => {
        window.scrollTo(0, 0);
        const response = await articles.getById(id)
        setArticle(response)
        const starsIcon = new Array(typeof response?.ranking === 'number' ? Math.ceil(response?.ranking) : 0).fill('star')
        setStarts(starsIcon)
    }, [id])

    const renderBody = () => (<div className="mt-10">
        {article?.topics?.map((topic, i) => {
            return (<div className="grid grid-cols-1 lg:grid-cols-3 xl:grid-cols-3" key={`topic-key-${topic._id}`}>
                <div>
                    <h1 className="text-2xl" style={{ wordBreak: 'break-word', hyphens: 'auto' }}>{topic?.title}</h1>
                    <p className="text-left text-base font-normal text-base text-gray-500 my-4 formatted-text-container"
                        dangerouslySetInnerHTML={{ __html: topic.description }}>
                    </p>
                </div>
                <div className="w-full col-span-2 pl-0 lg:pl-4">
                    <img className="w-full object-cover rounded-sm bg-black h-100 mt-4 " src={topic?.image} title="" alt="" />
                    <p className="text-left text-base font-normal text-base text-gray-500 my-4 formatted-text-container"
                        dangerouslySetInnerHTML={{ __html: topic.summary }}>
                    </p>
                </div>
            </div>)
        })
        }
    </div>)

    return (
        <>
            <div className="pb-20 bg-white lg:pt-2">
                <div className="max-w-lg lg:max-w-md px-6 pt-3 mx-auto lg:max-w-7xl  sm:px-0 xs:max-w-3xl md:max-w-5x1 xs:px-0">
                    <div className="flex mb-8">
                        <Link to='/articulos' className="font-semibold" style={{ color: '#C0C9FF' }}>Artículos</Link>
                        <p className="mx-2 font-semibold" style={{ color: '#C0C9FF' }}>&gt;</p>
                        <p className="font-semibold"
                            style={{ color: '#505DD4', wordBreak: 'break-word', hyphens: 'auto' }}>{article?.name}</p>
                    </div>
                    <h1 className="text-2xl" style={{ wordBreak: 'break-word', hyphens: 'auto' }}>{article?.name}</h1>
                    <div className="flex flex-row self-start mt-4 items-center">
                        {
                            article?.postedBy?.image !== null ?
                                <img className="w-10 h-10 object-cover rounded-full" src={article?.postedBy?.image} title="" alt="" /> :
                                <UserProfileIcon className="w-10 h-10" />
                        }

                        <span className="text-left text-base text-gray-500 mx-4">Por {article?.postedBy?.name}</span>
                        <span className="text-left text-base font-bold text-gray-500 ">{"·"}</span>
                        <span className="text-left text-base text-gray-500 mx-4 capitalize">{moment(article?.updatedAt).format("MMMM DD, YYYY")}</span>
                    </div>
                    <img className="w-full object-cover rounded-sm bg-black h-100 mt-4" src={article?.photo} title="no image" alt="no image" />
                    <div className="grid grid-cols-1 lg:grid-cols-6 my-4 gap-2">
                        <div className="flex items-start">
                            <FacebookShareButton quote={article?.name} hashtag="PortalMisticoOnline"
                                url={`${process.env.REACT_APP_ARTICLES_URL_BACK}/articles/share/${article?._id}`}>
                                <IconFacebook width="22" height="22" />
                            </FacebookShareButton>
                            <EmailShareButton quote={article?.name} hashtag="PortalMisticoOnline" className="mx-3"
                                url={`${process.env.REACT_APP_ARTICLES_URL_BACK}/articles/share/${article?._id}`}>
                                <IconEnvelopeSVG width="22" height="22" />
                            </EmailShareButton>
                        </div>
                        <div className="lg:col-start-3 lg:col-span-4 formatted-text-container"
                            dangerouslySetInnerHTML={{ __html: article?.content }}>
                        </div>
                    </div>
                    {renderBody()}
                    <div className="flex flex-row-reverse mt-10">
                        <Link to='/articulos' className="font-semibold flex items-center" style={{ color: 'rgb(73,84,189)' }}>
                            <span>
                                Ver todos los artículos
                            </span>
                            <img className="ml-2 md:ml-3 w-2 h-3" src={assets.Icon_ChevronRight} alt="Right" />
                        </Link>
                    </div>
                    <Recommended />
                </div>
            </div>

        </>
    )
}

export default ArticlePage