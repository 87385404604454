import { Menu } from "@headlessui/react";

export const DropdownList = ({ children, items, onChange }) => {
   const handleClick = (selectedValue) => {
      if (typeof onChange === 'function') {
         onChange(selectedValue)
      }
   }

   return (
      <Menu>
         <Menu.Button className="outline-none focus:outline-none">{children}</Menu.Button>
         <Menu.Items className="absolute flex flex-col overflow-x-auto border rounded-md outline-none w-max bg-warmGray-100 focus:outline-none max-h-40 radius">
            {Array.isArray(items)
               ? items.map((item, i) => <DropDownItem key={i} onClick={() => handleClick(item)}>{item}</DropDownItem>)
               : null
            }
         </Menu.Items>
      </Menu>
   );
}

const DropDownItem = ({ children, onClick }) => (
   <Menu.Item>
      {({ active }) => (
         <span className={`px-3 text-xs mr-2 py-2 w-full ${active && "bg-blue-500"}`} onClick={onClick}>
            {children}
         </span>
      )}
   </Menu.Item>
)
export default DropdownList

