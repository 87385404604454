import { useEffect, useState } from "react"
import { IconChevronLeft, IconChevronRight } from "../Icons"

const Pagination = ({ handlePagination, pagination, filter = null }) => {
    const [pages, setPages] = useState(pagination?.pages ? pagination.pages : [])
    const [overflow, setOverflow] = useState(false)
    const [overflowLeft, setOverflowLeft] = useState(false)

    useEffect(() => {
        let pageStart = 1
        if (pagination?.current > 2) pageStart = pagination?.current - 2
        let pageFinish = pageStart + 6
        if (pageFinish > pagination?.pages?.length) pageFinish = pagination?.pages?.length
        setPages((new Array(pageFinish - pageStart + 1).fill(0).map((item, idx) => (idx + pageStart))))
        setOverflow(pageFinish < pagination?.pages?.length)
        setOverflowLeft(pageStart > 1)

    }, [pagination])

    return <div className="flex text-gray-700 justify-end self-end mt-10">
        {pagination?.prev > 0 && <div onClick={() => handlePagination(pagination.prev, filter)}
            className="h-12 w-12 mr-1 flex justify-center items-center cursor-pointer">
            <IconChevronLeft className="h-5 text-white ml-2 " style={{ width: 8, height: 12 }} />
        </div>}
        <div className="flex flex-row justify-center items-center ">
            {
                pages?.length > 1 && (
                    <>
                        {
                            overflowLeft && <div>...</div>
                        }
                        {
                            pages?.map(page => {
                                return <div
                                    className="m-1 text-gray-500 cursor-pointer font-semibold mx-4"
                                    style={{ color: pagination?.current == page ? "#505DD5" : "rgba(113, 113, 122, var(--tw-text-opacity))" }}
                                    onClick={() => {
                                        handlePagination(page, filter)
                                    }}>
                                    {page}
                                </div>
                            })
                        }
                        {
                            overflow && <div>...</div>
                        }
                    </>
                )
            }
        </div>
        {pagination?.next && <div onClick={() => handlePagination(pagination.next, filter)}
            className="h-12 w-12 mr-1 flex justify-center items-center cursor-pointer">
            <IconChevronRight className="h-5 text-white ml-2 " style={{ width: 8, height: 12 }} />

        </div>}
    </div>
}

export default Pagination