import { Link } from "react-router-dom"
import assets from "../../Assets"
import Icon from "../../Assets/Icon"
import palete from "../../Assets/palete"
import NavItem from "../Navbar/NavItem"
import moment from "moment"
import { Context } from '../../store/useGlobalState'
import { useContext } from "react"

const Footer = () => {
    const { globalState } = useContext(Context)

    return (
        <footer aria-labelledby="footerHeading" style={{ background: palete.lightBlue }}>
            <h2 id="footerHeading" className="sr-only">Footer</h2>
            <div className="max-w-6xl px-5 pt-8 mx-auto">
                <div className="text-gray-700 xl:gap-8 xl:grid xl:grid-cols-4">
                    <div className="grid gap-8 xl:col-span-2">
                        <div className="md:grid md:grid-cols-2 md:gap-8">
                            <div>
                                <h3 className="text-sm font-semibold">Explora Portal Místico</h3>
                                <ul className="flex flex-col mt-3 text-xs gap-y-3"
                                    style={{
                                        listStyleType:'none',
                                        marginLeft: '0px',
                                        paddingLeft: '0px'
                                    }}>
                                    <FooterLink label="Mapa del sitio" url="/mapa-del-sitio" />
                                    <FooterLink label="Expertos" url="/expertos" />
                                    <FooterLink label="Horóscopos" url="/horoscopos" />
                                    <FooterLink label="Consultas" url="/consultas" />
                                    <FooterLink label="Artículos" url="/articulos" />
                                    <FooterLink label="Comienza gratis" 
                                        url={globalState?.user?'/ofertas':'/registrarse'} />
                                </ul>
                            </div>
                            <div className="mt-12 md:mt-0">
                                <h3 className="text-sm font-semibold tracking-wider">Servicio al cliente</h3>
                                <ul className="flex flex-col mt-3 text-xs gap-y-3"
                                    style={{
                                        listStyleType:'none',
                                        marginLeft: '0px',
                                        paddingLeft: '0px'
                                    }}>
                                    <FooterLink label="Ayuda" url="/servicio-al-cliente" />
                                    <FooterLink label="Contacto" url="/contacto" />
                                    <FooterLink label="Satisfacción garantizada" url="/satisfaccion-garantizada" />
                                    <FooterLink label="Recomendaciones técnicas" url="/recomendaciones-tecnicas" />
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="flex flex-col pt-6 mt-8 xl:col-span-2 xl:mt-0">
                        <div className="flex-row text-right">
                            <img className="ml-auto mr-0 h-9" src={assets.brandLogoBlack} alt="Paradigma" />
                        </div>
                        <div className="flex-col pt-6 pb-3">
                            <p className="text-right">¿Eres psíquico? <NavItem url="/peticion-de-expertos" label="Realiza tu solicitud ahora" /> </p>
                        </div>
                        <div className="flex-col grid-cols-3 space-x-6 md:order-2">
                            <div className="flex flex-row-reverse items-center text-center">
                                <a href="https://www.facebook.com/portalmisticoonline/" className="flex-col inline-block ml-4 text-right text-gray-400 hover:text-gray-500">
                                    <span className="sr-only">Facebook</span>
                                    <Icon name="facebook" />
                                </a>
                                <a href="https://www.instagram.com/portal_mistico_online/" className="flex-col text-gray-400 hover:text-gray-500">
                                    <span className="sr-only">Instagram</span>
                                    <Icon name="instagram" className="pt-1 pr-2"/>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="pt-10 pb-6 text-xs md:flex md:items-center md:justify-between">
                    <div className="flex space-x-3 text-xs md:order-2">
                        <Link to="/terminos-y-condiciones" className="font-bold text-gray-500 hover:text-gray-900" >Términos y condiciones</Link>
                        <a href="/" className="border-l border-black">&nbsp;</a>
                        <Link to="/politicas-de-privacidad" className="font-bold text-gray-500 hover:text-gray-900" >Política de privacidad</Link>
                    </div>
                    <p className="mt-8 text-xs md:mt-0 md:order-1">
                        Copyright Portal Místico, {moment().format("YYYY")}.
                    </p>
                </div>
            </div>
        </footer>

    )
}

//#region Subcomponents
const FooterLink = ({ label, url, className }) => (
    <li className="flex-col">
        <a href={url} className={"text-xs"}>
            {label}
        </a>
    </li>
)
//#endregion

export default Footer