import { useHistory } from 'react-router-dom'
import articles from "../../integrations/article"
import Loader from "../../Components/Loader"
import { useEffect, useState } from "react"
import UserAvatar from "../../Components/UserAvatar"
import moment from "moment"
moment.locale('es');

const dateFormat = "MMMM DD, YYYY"

const Articles = () => {
    const [isLoading, setLoading] = useState(true)
    const [lastArticles, setlastArticles] = useState([])
    const history = useHistory()

    useEffect(async () => {
        try {
            const res = await articles.getArticles(4, 1)

            if (res.data) {
                res.data.forEach(element => {
                    if (element && element.name) {
                        try{
                            const str = element.name.trim();
                            element.name = str.charAt(0).toLocaleUpperCase() + str.slice(1)
                        }catch(error){}
                    }

                    if (element && element.content) {
                        if (element.content.match(/(>)([^<]+)(<\/)/)) {
                            let nContent = ''
                            element.content.replace(/(>)([^<]+)(<\/)/g, (match, p1, p2, p3) => {
                                nContent += p2+' '
                            })
                            element.content = nContent
                        }
                    }
                });
            }

            setlastArticles(res?.data)
            setLoading(false)
        } catch (error) {
            console.log(error)
            setlastArticles([])
            setLoading(false)
        }
    }, [])

    const categoryTag = (cat) => {
        switch (cat) {
            case 'psychic-topics': {
                return "Temas psíquicos"
            }
            case 'love-and-relationship': {
                return "Amor y relaciones"
            }
            case 'destiny': {
                return "Destino y significado de la vida"
            }
            case 'health': {
                return "Salud y bienestar"
            }
            case 'career-and-finance': {
                return "Carrera profesional y finanzas"
            }
            case 'relationship-and-family': {
                return "Relaciones y familia"
            }
            case 'loss-and-grief': {
                return "Perdida y duelo"
            }
            case 'astrology': {
                return "Astrología"
            }
            default: {
                return ""
            }
        }
    }
    return (
        <section className="flex flex-col w-full max-w-6xl gap-4 mx-auto mt-20 text-center lg:text-left">
            <h1 className="flex-row mt-6 ml-2 text-3xl font-bold">Artículos</h1>
            {
                isLoading ? <Loader /> : <div className="grid flex-row grid-cols-3 gap-4 xl:gap-x-6">
                    {
                        lastArticles.map((x, i) => {
                            return <ArticleItem
                                isBig={i === 3}
                                heading={x.name}
                                image={x.photo}
                                category={categoryTag(x.category)}
                                text={x.content}
                                author={x.postedBy.name}
                                date={moment(x.createdAt).format(dateFormat)}
                                profile={<UserAvatar src={x.postedBy.image} isUserClient={true} className="w-10 h-10" />}
                                onClick={() => history.push(`/articulo/${x._id}`)}
                            />
                        })
                    }
                </div>
            }
        </section>
    )
}

// #region Subcomponents
const ArticleItem = ({ heading, image, category, text, author, date, profile, isBig, onClick }) => (
    <article className={`flex flex-col gap-2 py-4 cursor-pointer transition-shadow hover:shadow-md col-span-3 md:col-span-1 ${isBig ? 'md:col-span-3' : ''}`} onClick={onClick}>
        <div className="flex-row"><img src={image} className={`w-full mx-auto h-50 object-cover rounded ${isBig?'h-80':''}`} alt={heading} /></div>
        <h1 className="flex-row px-2 text-xl font-bold text-gray-600"
            style={{wordBreak:'break-word', hyphens:'auto'}}>{heading}</h1>
        <div className="flex-row flex-shrink col-span-1 px-2 text-gray-500">{category}</div>
        <div className="flex-row px-2 mt-3 text-xs leading-normal text-gray-600 formatted-text-container line-clamp-3"
            dangerouslySetInnerHTML={{__html:text}}></div>

        <div className="flex px-2 items-center">
            <div className="inline-grid flex-col col-span-1 mr-4">
                <span className="grid items-center h-12 col-span-1 py-0 text-xs text-white align-middle rounded-full">
                    {profile}
                </span>
            </div>
            <div className="flex-col flex-grow col-span-11 text-sm text-left">
                <b className="text-xs text-gray-700">Por {author}</b>
                <p className="text-xs text-gray-500 capitalize">{date}</p>
            </div>
        </div>
    </article>
)
// #endregion

export default Articles