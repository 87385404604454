import { toast } from 'react-toastify';


export const Toast = (text) => toast(text, {
   position: "top-right",
   autoClose: 2000,
   hideProgressBar: true,
   closeOnClick: true,
   pauseOnHover: true,
   draggable: true,
});


export const ToastInfo = (text, timeout = 2000) => toast.info(text, {
   position: "top-right",
   autoClose: timeout,
   hideProgressBar: true,
   closeOnClick: true,
   pauseOnHover: true,
   draggable: true
});


export const ToastSuccess = (text) => toast.success(text, {
   position: "top-right",
   autoClose: 5000,
   hideProgressBar: true,
   closeOnClick: true,
   pauseOnHover: true,
   draggable: true,
});


export const ToastWarn = (text) => toast.warn(text, {
   position: "top-right",
   autoClose: 2500,
   hideProgressBar: true,
   closeOnClick: true,
   pauseOnHover: true,
   draggable: true
});


export const ToastError = (text) => toast.error(text, {
   position: "top-right",
   autoClose: 3000,
   hideProgressBar: true,
   closeOnClick: true,
   pauseOnHover: true,
   draggable: true,
});


export const ToastDark = (text) => toast.dark(text, {
   position: "top-right",
   autoClose: 2500,
   hideProgressBar: true,
   closeOnClick: true,
   pauseOnHover: true,
   draggable: true,
});
