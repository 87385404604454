import assets from ".."

export const Icon = ({ name, fill = "currentColor", width, height, ...props }) => {
    switch (name) {
        case "clock": return (
            <svg width="20" height="25" viewBox="0 0 17 18" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
                <path d="M8.5 15.375C12.0208 15.375 14.875 12.5208 14.875 9C14.875 5.47918 12.0208 2.625 8.5 2.625C4.97918 2.625 2.125 5.47918 2.125 9C2.125 12.5208 4.97918 15.375 8.5 15.375Z" stroke="#505DD5" strokeWidth="1.5" strokeLinecap="round" />
                <path d="M6.375 8.99967H8.14583C8.3414 8.99967 8.5 8.84108 8.5 8.64551V5.45801" stroke="#505DD5" strokeWidth="1.5" strokeLinecap="round" />
            </svg>
        )

        case "shield": return (
            <svg width="18" height="23" viewBox="0 0 14 18" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
                <path d="M7.22301 0.631394C7.04801 0.456202 6.7644 0.456202 6.5894 0.631394C6.11127 1.10997 5.46288 1.3789 4.78681 1.3789H0.448027C0.200627 1.3789 0 1.57975 0 1.82742V8.63723C0 15.744 6.90625 17.5 6.90625 17.5C6.90625 17.5 13.8125 15.744 13.8125 8.63723V1.82742C13.8125 1.57975 13.612 1.3789 13.3645 1.3789H9.02569C8.34952 1.3789 7.70114 1.11006 7.22301 0.631394Z" fill="#6773DF" />
                <path d="M6.79995 16.5C6.18016 16.2711 5.08519 15.7915 4.01826 14.9539C2.0155 13.3813 1 11.26 1 8.6492V2.5H12.6V8.6492C12.6 11.26 11.5845 13.3813 9.58174 14.9539C8.51471 15.7915 7.41964 16.2711 6.79995 16.5Z" fill="#E2E5FD" />
                <path d="M5.66832 12.5C5.64355 12.5 5.61877 12.499 5.59399 12.4967C5.27516 12.4688 4.99909 12.2638 4.88018 11.9665L3.96182 9.67045C3.94326 9.62383 3.89868 9.59374 3.84856 9.59374C3.37982 9.59374 3 9.21381 3 8.74518C3 8.27655 3.37982 7.89662 3.84856 7.89662C4.59665 7.89662 5.25977 8.34545 5.53765 9.04014L5.83249 9.77737L8.8588 4.9012C9.10601 4.50294 9.62918 4.38064 10.0272 4.62762C10.4255 4.87484 10.5479 5.39789 10.3007 5.79615L6.38903 12.0989C6.23346 12.3497 5.95989 12.5 5.66832 12.5Z" fill="#505DD5" />
            </svg>
        )

        case "chevron-left": return (
            <img src={assets.Icon_ChevronLeft} {...props} alt="Left" />
        )

        case "chevron-right": return (
            <img src={assets.Icon_ChevronRight} {...props} alt="Right" />
        )

        case "facebook": return (
            <svg width="17" height="16" viewBox="0 0 17 16" fill="black" {...props} xmlns="http://www.w3.org/2000/svg">
                <path d="M0.510742 8.04833C0.511672 11.9784 3.36949 15.3246 7.25096 15.9405V10.3571H5.22418V8.04833H7.25336V6.29078C7.16265 5.45797 7.44711 4.62796 8.02959 4.02587C8.61207 3.42378 9.43221 3.11201 10.2676 3.17511C10.8672 3.1848 11.4652 3.2382 12.0571 3.33489V5.29936H11.0473C10.6996 5.25382 10.3501 5.36864 10.0972 5.61146C9.8443 5.85427 9.71535 6.19881 9.74669 6.54802V8.04833H11.9604L11.6065 10.3579H9.74669V15.9405C13.9444 15.2771 16.888 11.4434 16.445 7.21675C16.0019 2.99007 12.3273 -0.149916 8.08326 0.0715753C3.8392 0.293067 0.511417 3.79849 0.510742 8.04833Z" />
            </svg>
        )

        case "gift": return (
            <svg xmlns="http://www.w3.org/2000/svg" {...props} fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 8v13m0-13V6a2 2 0 112 2h-2zm0 0V5.5A2.5 2.5 0 109.5 8H12zm-7 4h14M5 12a2 2 0 110-4h14a2 2 0 110 4M5 12v7a2 2 0 002 2h10a2 2 0 002-2v-7" />
            </svg>
        )

        case "instagram": return (
            <img src={assets.Icon_Instagram} {...props} alt="Paradigma en Instagram" />
        )

        case "lightning-bolt": return (
            <svg xmlns="http://www.w3.org/2000/svg" {...props} fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M13 10V3L4 14h7v7l9-11h-7z" />
            </svg>
        )

        case "lock-closed": return (
            <svg xmlns="http://www.w3.org/2000/svg" {...props} fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 15v2m-6 4h12a2 2 0 002-2v-6a2 2 0 00-2-2H6a2 2 0 00-2 2v6a2 2 0 002 2zm10-10V7a4 4 0 00-8 0v4h8z" />
            </svg>
        )

        case "phone-o": return (
            <svg viewBox="0 0 17 18" fill="none" {...props}>
                <path clipRule="evenodd" d="M13.221 10.662a7.05 7.05 0 01-1.597-.396 1.162 1.162 0 00-1.209.255c-.423.429-1.088.527-1.576.173a9.144 9.144 0 01-2.007-2.007c-.355-.487-.256-1.152.173-1.576.304-.32.403-.783.255-1.2a7.195 7.195 0 01-.444-1.633 1.171 1.171 0 00-1.152-.944H3.973c-.322 0-.63.133-.85.368a1.124 1.124 0 00-.284.869A11.133 11.133 0 004.587 9.5a11.146 11.146 0 003.42 3.419 11.425 11.425 0 004.921 1.747h.095c.287.002.564-.106.774-.302.236-.216.37-.521.369-.841v-1.69a1.143 1.143 0 00-.945-1.172z" stroke="#505DD5" strokeWidth={1.5} strokeLinecap="round" />
            </svg>
        )

        case "phone": return (
            <svg viewBox="0 0 16 16" fill="none" {...props}>
                <path d="M13.79 11.358c-.348-.354-1.85-1.546-2.222-1.624-.414-.088-.743.103-1.033.27-.298.171-.555.32-.879.202-1.66-.676-2.845-1.86-3.52-3.51-.12-.33.029-.587.2-.885.168-.289.356-.617.27-1.03-.077-.373-1.27-1.872-1.627-2.219-.235-.228-.475-.354-.72-.375-.928-.038-2.073 1.295-2.255 1.588-.455.626-.452 1.456.008 2.465C3.12 8.95 7.355 13.18 10.1 14.318c.505.235.969.352 1.384.352.408 0 .77-.113 1.081-.336.235-.134 1.633-1.318 1.6-2.261-.02-.241-.147-.481-.376-.715" fill="#C0C9FF" />
            </svg>
        )

        case "quote": return (
            <svg className="absolute top-0 left-0 w-8 h-8 text-secondary transform -translate-x-3 -translate-y-2" {...props} fill="currentColor" viewBox="0 0 32 32" aria-hidden="true">
                <path d="M9.352 4C4.456 7.456 1 13.12 1 19.36c0 5.088 3.072 8.064 6.624 8.064 3.36 0 5.856-2.688 5.856-5.856 0-3.168-2.208-5.472-5.088-5.472-.576 0-1.344.096-1.536.192.48-3.264 3.552-7.104 6.624-9.024L9.352 4zm16.512 0c-4.8 3.456-8.256 9.12-8.256 15.36 0 5.088 3.072 8.064 6.624 8.064 3.264 0 5.856-2.688 5.856-5.856 0-3.168-2.304-5.472-5.184-5.472-.576 0-1.248.096-1.44.192.48-3.264 3.456-7.104 6.528-9.024L25.864 4z" />
            </svg>
        )

        case "star": return (
            <svg xmlns="http://www.w3.org/2000/svg" {...props} viewBox="0 0 20 20" fill="currentColor">
                <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
            </svg>
        )

        case "videocall-o": return (
            <svg width={17} height={18} viewBox="0 0 17 18" fill="none" {...props}>
                <path clipRule="evenodd" d="M10.625 9c0-.434.245-.831.633-1.025l2.592-1.296a.708.708 0 011.025.634v3.374a.708.708 0 01-1.025.634l-2.591-1.296A1.146 1.146 0 0110.624 9z" stroke="#505DD5" strokeWidth={1.5} strokeLinecap="round" />
                <path d="M9.208 4.75H3.542c-.783 0-1.417.634-1.417 1.417v5.666c0 .783.634 1.417 1.417 1.417h5.666c.783 0 1.417-.634 1.417-1.417V6.167c0-.783-.634-1.417-1.417-1.417z" stroke="#505DD5" strokeWidth={1.5} strokeLinecap="round" />
            </svg>
        )

        case "eye": return (
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" {...props}>
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z" />
            </svg>
        )
        case "eye-off": return (
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" {...props}>
                <path strokeLinecap="round" stroke-linejoin="round" strokeWidth="2" d="M13.875 18.825A10.05 10.05 0 0112 19c-4.478 0-8.268-2.943-9.543-7a9.97 9.97 0 011.563-3.029m5.858.908a3 3 0 114.243 4.243M9.878 9.878l4.242 4.242M9.88 9.88l-3.29-3.29m7.532 7.532l3.29 3.29M3 3l3.59 3.59m0 0A9.953 9.953 0 0112 5c4.478 0 8.268 2.943 9.543 7a10.025 10.025 0 01-4.132 5.411m0 0L21 21" />
            </svg>
        )

        case "loader": return (
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50" fill="currentColor" stroke="currentColor" {...props}>
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M43.935,25.145c0-10.318-8.364-18.683-18.683-18.683c-10.318,0-18.683,8.365-18.683,18.683h4.068c0-8.071,6.543-14.615,14.615-14.615c8.072,0,14.615,6.543,14.615,14.615H43.935z" />
            </svg>
        )

        case "chk-box": return (
            <svg viewBox="0 0 20 20" fill="none" stroke="currentColor" xmlns="http://www.w3.org/2000/svg" {...props}>
                <path strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" d="M14.3344 0.750244H5.66537C2.64437 0.750244 0.750366 2.88924 0.750366 5.91624V14.0842C0.750366 17.1112 2.63537 19.2502 5.66537 19.2502H14.3334C17.3644 19.2502 19.2504 17.1112 19.2504 14.0842V5.91624C19.2504 2.88924 17.3644 0.750244 14.3344 0.750244Z" />
                <path strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" d="M6.43982 10.0002L8.81382 12.3732L13.5598 7.6272" />
            </svg>
        )
        case "chk-box-fill": return (
            <svg viewBox="0 0 20 20" fill="currentColor" stroke="none" xmlns="http://www.w3.org/2000/svg" {...props}>
                <path fillRule="evenodd" clipRule="evenodd" d="M5.67 0H14.34C17.73 0 20 2.38 20 5.92V14.091C20 17.62 17.73 20 14.34 20H5.67C2.28 20 0 17.62 0 14.091V5.92C0 2.38 2.28 0 5.67 0ZM9.43 12.99L14.18 8.24C14.52 7.9 14.52 7.35 14.18 7C13.84 6.66 13.28 6.66 12.94 7L8.81 11.13L7.06 9.38C6.72 9.04 6.16 9.04 5.82 9.38C5.48 9.72 5.48 10.27 5.82 10.62L8.2 12.99C8.37 13.16 8.59 13.24 8.81 13.24C9.04 13.24 9.26 13.16 9.43 12.99Z" />
            </svg>
        )

        case "arrow-left": return (
            <svg viewBox="0 0 6 10" fill="none" stroke="currentColor" xmlns="http://www.w3.org/2000/svg" {...props}>
                <path strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" d="M5.09667 9.12622L1 5.04844L5.09667 0.970665" />
            </svg>
        )

        case "filledStars": return (
            <svg width={width} height={height} viewBox="0 0 17 15" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
                <path d="M15.5404 7.23313L13.0233 9.68403L13.619 13.149C13.7215 13.7489 13.4802 14.3403 12.987 14.6947C12.4981 15.0534 11.8598 15.0982 11.3218 14.8121L8.21548 13.1789L5.10276 14.8143C4.87219 14.936 4.62026 15 4.37048 15C4.04383 15 3.72146 14.8975 3.44392 14.6968C2.95289 14.3403 2.71164 13.7489 2.81411 13.149L3.40762 9.68403L0.890544 7.23313C0.455019 6.81042 0.303439 6.18916 0.491313 5.61059C0.681321 5.03416 1.17235 4.62425 1.77227 4.53886L5.24366 4.03288L6.80003 0.87959C7.07116 0.337319 7.61343 0 8.21548 0H8.21975C8.82394 0.00213493 9.36621 0.339453 9.63308 0.881725L11.1894 4.03288L14.6672 4.54099C15.2607 4.62425 15.7518 5.03416 15.9397 5.61059C16.1297 6.18916 15.9781 6.81042 15.5404 7.23313Z" fill="#FFC700" />
            </svg>

        )
        case "unfilledStar": return (
            <svg width={width} height={height} viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
                <path d="M15.872 8.23313L13.3549 10.684L13.9505 14.149C14.053 14.7489 13.8118 15.3403 13.3186 15.6947C12.8297 16.0534 12.1913 16.0982 11.6533 15.8121L8.54703 14.1789L5.4343 15.8143C5.20373 15.936 4.95181 16 4.70202 16C4.37538 16 4.053 15.8975 3.77546 15.6968C3.28443 15.3403 3.04318 14.7489 3.14566 14.149L3.73917 10.684L1.22209 8.23313C0.786562 7.81042 0.634982 7.18915 0.822856 6.61059C1.01286 6.03416 1.5039 5.62425 2.10381 5.53886L5.57521 5.03288L7.13157 1.87959C7.4027 1.33732 7.94498 1 8.54703 1H8.5513C9.15548 1.00213 9.69775 1.33945 9.96462 1.88173L11.521 5.03288L14.9988 5.54099C15.5923 5.62425 16.0833 6.03416 16.2712 6.61059C16.4612 7.18915 16.3096 7.81042 15.872 8.23313" stroke="#FFC700" />
            </svg>
        )
        default:
            return <i {...props} >{name}</i>
    }
}

export default Icon