import axios from 'axios'

const profile = {
    getInfo: async () => {
        const { data: response } = await axios.get('/profile/info')
        return response
    },

    updateInfo: async (payload) => {
        const { data: response } = await axios.patch(
            '/profile/info',
            payload
        )
        return response
    },

    verifyRequestCode: async (phonedata) => {
        const { data: response } = await axios.post(
            '/profile/request-verify-phone',
            phonedata
        )
        return response
    },

    verifyCode: async (phone_vefification_code) => {
        const { data: response } = await axios.patch(
            '/profile/verify-phone',
            { phone_vefification_code }
        )
        return response
    },

    uploadImage: async (file, name) => {
        const formData = {
            fileData: file,
            fileName: name
        }
        const { data: response } = await axios.put(
            '/profile/image',
            formData
        )
        return response?.image
    },
}

export default profile
