import axios from 'axios'

const zodiacs = {
    getInfo: async (sign, { day, month, year }) => {
        let query = `date=`
        if (year) {
            query += `${year}`
        }
        if (month) {
            query += `-${month}`
        }
        if (day) {
            query += `-${day}`
        }
        query += `&signKey=${sign}`
        const response = await axios.get(`/horoscopes/portal_mistico/show?${query}`)
        return response?.data?.payload
    },
    getZodiagInfo: async (sign) => {
        const response = await axios.get(`/signs/portal_mistico/show?signKey=${sign}`)
        return response?.data?.payload
    }
}

export default zodiacs