import NumberList from "./NumericList"

const ExpYearPicker = (props) => {
   const currentYear = new Date().getFullYear();
   let selectableYears = []

   for (let y = currentYear; y < currentYear + 7; y++) {
      selectableYears.push(y)
   }

   return <NumberList items={selectableYears} {...props} maxLength={4}/>
}

export default ExpYearPicker