import React, { useState } from 'react'
import { Link } from "react-router-dom"


const NavItem = ({ mobile, label, url, children, open, className }) => {
    const [isOpen, setIsOpen] = useState(open)

    const toggleOpen = () => {
        setIsOpen(!isOpen)
    }

    if (children) {
        return (
            <div className="relative flex flex-col mx-3" onMouseLeave={() => setIsOpen(false)}>
                {/* Item active: "text-gray-900", Item inactive: "text-gray-500" */}
                <button onClick={toggleOpen}
                    onMouseEnter={() => setIsOpen(true)}
                    onBlur={() => setIsOpen(false)}
                    style={{color: '#0B0B28'}}
                    type="button" className="inline-flex items-center text-sm font-medium text-gray-500 bg-white rounded-md group hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500" aria-expanded="false">
                    {url
                        ? <Link to={url}>{label}</Link>
                        : <span>{label}</span>
                    }
                    {/* <svg className="w-5 h-5 ml-2 text-gray-400 group-hover:text-gray-500" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                        <path fillRule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clipRule="evenodd" />
                    </svg> */}
                </button>
                {isOpen ?
                    <div className="absolute z-10 max-w-md px-2 mt-5 -ml-4 transform w-max sm:px-0 lg:ml-0 lg:left-1/2 lg:-translate-x-1/2">
                        <div className="overflow-hidden rounded-lg shadow-lg ring-1 ring-black ring-opacity-5">
                            <div className="relative grid pt-2 pb-2 bg-white sm:gap-4 sm:px-4">
                                {children}
                            </div>
                        </div>
                    </div>
                    : null}
            </div>
        )

    }

    // Elemento de menú para móviles
    if (mobile === true) {
        return (
            <Link to={url} style={{color: '#0B0B28'}} className="flex items-center p-3 -m-3 rounded-md hover:bg-gray-50">
                <span className={"ml-3 text-sm font-medium text-gray-900 " + (className || '')}>{label}</span>
            </Link>
        )
    }

    // Tamaño normal
    return <Link to={url} style={{color: '#0B0B28'}} className={"text-sm font-medium text-gray-500 mx-3"}>{label}</Link>
}

export default NavItem