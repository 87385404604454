const palete = {
    blue: "#505DD4",
    pink: "#BB3996",
    lightBlue: "#F4F6FD",
    
    styles: {
        shadow: {
            "--tw-shadow": "0 25px 50px -12px rgb(222 226 255)",
            boxShadow: "var(--tw-ring-offset-shadow, 0 0 #DEE2FF), var(--tw-ring-shadow, 0 0 #DEE2FF), var(--tw-shadow)"
        }
    }
}

export default palete