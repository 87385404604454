import jwt_decode from "jwt-decode";
import { useContext, useEffect, useState } from "react";
import { Redirect, useParams } from "react-router";
import { Context, StoreActions } from "../../store/useGlobalState";
import myLocalStorage, { lsKeys } from "../../utils/localStorage";
import {setAxiosToken} from "../../utils/axiosConfig";

const AuthenticatePage = () => {
   let [isLoading, setIsLoading] = useState(true)

   // Parámetros para enviar por state al Home
   const [authMessage, setAuthMessage] = useState('')
   const [authError, setAuthError] = useState(false)

   let { token } = useParams();
   const { globalDispatch } = useContext(Context)

   useEffect(() => {
      try {
         const payload = {
            token,
            user: jwt_decode(token)
         }

         globalDispatch({ type: StoreActions.LOGIN, payload })
         myLocalStorage.save(lsKeys.sesion, payload)
         setAxiosToken(token)

         // Indicar que no hay error y colocar el mensaje de error en blanco
         setAuthMessage('¡Bienvenido!')
         setAuthError(false)
      } catch (err) {
         setAuthMessage('No se pudo iniciar sesión con Facebook')
         setAuthError(true)
      }
      setIsLoading(false)
   }, [globalDispatch, token])


   if (isLoading === true) {
      return 'Iniciando sesión...'
   }

   console.log('authentication result', { authError, authMessage });

   // Redirigir al home una vez finalizada la verificación de la autenticación
   return <Redirect to="/" state={{
      authError,
      authMessage
   }} />
}

export default AuthenticatePage