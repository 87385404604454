import { STATES_MAP } from "./misc"
import { MEXICO_STATES } from "./statesOfMexico"
import axios from 'axios'

export const StatesUniversalApi = {
    getToken: async () => {
        const storage = window.sessionStorage

        if (!localStorage) throw Error("This browser doesn't support local storage")

        var uni_auth_token = storage.getItem('__app_universal_auth_token')
        var uni_auth_time = storage.getItem('__app_universal_time')

        if (uni_auth_token) {
            if (uni_auth_time && ((new Date()).getTime() < (Number.parseInt(uni_auth_time) + (21600000)))) {
                return uni_auth_token
            } else {
                const res = await axios.get('https://www.universal-tutorial.com/api/getaccesstoken', {
                    headers: {
                        'Accept': 'application/json',
                        'api-token': process.env.REACT_APP_API_UNIVERSAL_TOKEN,
                        'user-email': process.env.REACT_APP_API_UNIVERSAL_EMAIL
                    }
                })

                if (res && res.data && res.data.auth_token) {
                    const time = (new Date()).getTime()
                    storage.setItem('__app_universal_auth_token', res.data.auth_token)
                    storage.setItem('__app_universal_time', time)
                    return res.data.auth_token
                } else {
                    throw Error("Error getting the api token")
                }
            }
        } else {
            const res = await axios.get('https://www.universal-tutorial.com/api/getaccesstoken', {
                headers: {
                    'Accept': 'application/json',
                    'api-token': process.env.REACT_APP_API_UNIVERSAL_TOKEN,
                    'user-email': process.env.REACT_APP_API_UNIVERSAL_EMAIL
                }
            })

            if (res && res.data && res.data.auth_token) {
                const time = (new Date()).getTime()
                storage.setItem('__app_universal_auth_token', res.data.auth_token)
                storage.setItem('__app_universal_time', time)
                return res.data.auth_token
            } else {
                throw Error("Error getting the api token")
            }
        }
    },

    getStates: async (Country) => {
        if (STATES_MAP[Country]) return STATES_MAP[Country]

        const token = await StatesUniversalApi.getToken()

        const res = await axios.get(`https://www.universal-tutorial.com/api/states/${Country}`, {
            headers: {
                'Accept': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        })

        if (res && res.data) {
            return res.data.map((item) => item.state_name)
        } else {
            throw Error("Error getting the country states")
        }
    },
    getCities: async (State) => {
        if (MEXICO_STATES[State]) return MEXICO_STATES[State]
        
        const token = await StatesUniversalApi.getToken()

        const res = await axios.get(`https://www.universal-tutorial.com/api/cities/${State}`, {
            headers: {
                'Accept': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        })

        if (res && res.data) {
            return res.data.map((item) => item.city_name)
        } else {
            throw Error("Error getting the country states")
        }
    }
}