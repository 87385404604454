import axios from 'axios'

const appointments = {
    store: async (formData) => {
        const response = await axios.post(`/appointments/store`, formData)

        if (response?.data?.payload) {
            return response?.data?.payload
        }

        response.data.error = true
        return response?.data
    },
    userSchedule: async (data) => {
        const response = await axios.get(`/appointments/user-schedule`, { params: data })
        return response?.data?.payload
    },
    userScheduleMonth: async (data) => {
        const response = await axios.get(`/appointments/user-schedule-month`, { params: data })
        return response?.data?.payload
    },
    getToken: async (data) => {
        const response = await axios.get(`/twilio/token`, { params: data })
        console.log("RESPONSE",response)
        return response?.data?.payload
    },
    getTokenCall: async (data) => {
        const response = await axios.get(`/twilio/callToken`, { params: data })
        console.log("RESPONSE",response)
        return response?.data?.payload
    },
    expertSchedule: async (id, date) => {
        const response = await axios.get(`/appointments/expert-hours/${id}`, { params: { date } })
        return response?.data?.payload
    },
    setAppointmentStatus: async (id, status) => {
        const response = await axios.put(`/appointments/status/${id}`, { status });
        console.log(response);
        return response?.data?.payload
    },
}

export default appointments
