import { default as React, useState } from 'react'
import Login from "./Login"
import { RequestEmail, SuccesEmail } from "./NewPass"
import Dialog from "../../../../Layouts/Dialog"

const LoginModal = ({ hideSelf }) => {
    let [showResetPasword, setShowResetPasword] = useState(false)
    let [emailSent, setEmailSent] = useState(false)

    if (showResetPasword)
        return (emailSent
            ? <Dialog
                darkOverlay
                dismisable
                onClose={hideSelf}
                render={({ closeDialog }) =>
                    <SuccesEmail exit={closeDialog} />
                }
            />
            : <Dialog
                onClose={hideSelf}
                dismisable
                darkOverlay
                render={({ closeDialog }) =>
                    <RequestEmail cancel={closeDialog} succes={() => setEmailSent(true)} />
                }
            />
        )

    else return <Dialog
        className='absolute z-10 inline pt-5 bg-white border rounded-md shadow-lg p-7 top-0 md:top-20 right-auto md:right-1/4 left-auto'
        style={{ width: "342px", filter: "drop-shadow(0px 2px 12px #F4F6FD)", borderColor: "F4F6FD" }}
        onClose={hideSelf}
        dismisable
        render={() => <Login forgotPass={() => { setShowResetPasword(true) }} />}
    />
}

export default LoginModal
