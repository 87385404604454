import { React, useEffect } from "react"
import Recommended from "../../Components/Recommended"
import { Link } from "react-router-dom"
import assets from "../../Assets"
import { ShieldCheck } from "../../Components/Icons"

const SatisfactionGuaranteed = () => {
  const sideMenuItems = [
    {
      title: "Manifiesto",
      url: "manifiesto"
    },
    {
      title: "Código de ética",
      url: "codigo-de-etica"
    },
    {
      title: "Satisfacción garantizada",
      url: "satisfaccion-garantizada"
    },
    {
      title: "Testimonios",
      url:"testimonios"
    },
    {
      title: "Contáctanos",
      url:"contacto"
    },
    {
      title: "Preguntas frecuentes",
      url:"preguntas-frecuentes"
    },
    {
      title: "Cómo funciona",
      url:"como-funciona"
    },
    {
      title: "Precios",
      url:"precios"
    }

  ]

  useEffect(() => {
    window.scroll(0, 0);
  }, [])

  return <>
    <div className="px-3 max-w-md px-6 pt-3 mx-auto lg:max-w-7xl sm:max-w-3xl lg:px-0">
      <section className="grid grid-cols-1 md:grid-cols-5">
        <div className="col-span-2 lg:pr-10 pb-3">
          <h1 className="text-3xl font-bold mb-3">Satisfacción garantizada</h1>
          <sub className="block text-base">Si no estás satisfecho con tu última consulta psíquica pagada, es gratis. Confiamos en nuestro servicio y lo respaldamos con esta garantía sin riesgo.</sub>
          <SideMenu src={sideMenuItems} />
        </div>
        <div className="col-span-3">
          <img className="rounded" src={assets.Satisfaction} alt="satisfaction" />
          <div className="block mt-5">
            <div className="mb-5">
              <p>
                El equipo de Portal Místico está dedicado a brindar una experiencia al cliente excelente. Si no estás satisfecho, llámanos a nuestra centro de servicio al cliente o envíanos un correo electrónico a <a href="mailto:hola@portalmistico.com">hola@portalmistico.com</a> Navegar el mundo psíquico no siempre es fácil, usar nuestros servicios si lo debe ser. Tu satisfacción es nuestra prioridad, por favor compártenos tus comentarios, preguntas o problemas.
              </p>
            </div>
            <div className="mb-5">
              <p>
                Estamos muy contentos de que estés aquí. Gracias por elegirnos.
              </p>
            </div>
            <div className="mb-5">
              <b className="block mb-5">Garantía de satisfacción</b>
              <p>
                Queremos que cada conversación en nuestra plataforma cumpla con tus expectativas. Si no es así, nos comprometemos a compensarlo.
              </p>
            </div>
            <div className="mb-5">
              <p>
                Si tuviste un problema con la plataforma, puedes ser elegible para recibir un crédito en tu cuenta, hasta un límite de $500 pesos de Portal Místico, que puede usarse en futuras conversaciones.
              </p>
            </div>
            <div className="mb-5">
              <p>
                Ten en cuenta que se te permite recibir un reembolso por una experiencia insatisfactoria cada 30 días y solo las conversaciones que ocurran dentro de las últimas 72 horas son elegibles para el reembolso.
              </p>
            </div>
            <div className="mb-5">
              <p>
                Los créditos de la Garantía de satisfacción se emiten en créditos de Portal Místico y es posible que los fondos promocionales no sean elegibles para reembolso.
              </p>
            </div>
            <div className="mb-5">
              <p>
                Portal Místico se reserva el derecho, a su entera discreción, de aprobar, denegar o emitir un crédito parcial por cualquier solicitud.
              </p>
            </div>
          </div>          
        </div>
      </section>
      <section className="grid grid-cols-1 md:grid-cols-5 mt-20">
        <div class="col-span-5 text-center">
        <h1 className="text-base text-3xl sm:text-2xl">Satisfacción garantizada</h1>
        </div>
      </section>
      <section className="grid grid-cols-1 md:grid-cols-5">
        <div class="md:col-start-3 col-span-3">
        <div className="overflow-hidden relative mt-10 w-full md:w-96 xl:w-full rounded-lg h-32" style={{ backgroundColor: "#EBEFFF" }}>
            <div className="absolute w-64 h-64 rounded-full -right-1 -top-16" style={{ backgroundColor: "#E4E8FF"}} >
            <div className="absolute w-48 h-48 rounded-full top-8 -right-4" style={{ backgroundColor: "#DEE2FF" }} />
            </div>
            <div className="flex flex-row justify-between items-center absolute inset-y-0 inset-x-4 xl:px-3">
              <div>
                <h1 className="text-base xl:text-xl">Satisfacción garantizada</h1>
                <p className="text-base xl:text-xl text-secondary font-semibold mt-2">
                 <small>
                    Te ofrecemos el mejor servicio
                  </small> 
                </p>
              </div>
              <ShieldCheck className="w-10 xl:w-16" />
            </div>
          </div>
        </div>
      </section>
    </div>
    <Recommended />
  </>
}

const SideMenu = ({ src, ...props }) => {
  return <div className="mt-30 pr-10 lg:pr-0">
    {
      src.map((x, i) => {
        return <div className="mb-6" key={`sub-consultation-${i}`}>
          <Link to={`/${x.url}`} className="pb-3 border-b block max-w-xs">{x.title}</Link>
        </div>
      })
    }
  </div>
}


export default SatisfactionGuaranteed