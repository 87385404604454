import React, { useState, useRef, useEffect } from 'react'
import { mergeClassName } from '../../../utils/misc'
import { IconClose } from '../../../Components/Icons'

const Dialog = ({ onClose, darkOverlay, render, dismisable, showCloseButton = false, ...props }) => {
   const [visible, setVisible] = useState(true)
   const [loading, setLoading] = useState(false)
   const dialogRef = useRef(null)

   const closeDialog = e => {
      e?.stopPropagation()
      onClose?.()
      setVisible(false)
   }

   useEffect(() => {
      /**
       * Alert if clicked on outside of element
       */
      function handleClickOutside(event) {
         if (dialogRef.current && !dialogRef.current.contains(event.target)) {
            closeDialog()
         }
      }

      // Bind the event listener
      document.addEventListener("mouseup", handleClickOutside);
      return () => {
         // Unbind the event listener on clean up
         document.removeEventListener("mouseup", handleClickOutside);
      };
   }, [dialogRef]);

   props = mergeClassName('bg-white rounded-xl z-10', props)
   return (visible &&
      <div className={`fixed inset-0 z-10 md:flex justify-center items-center inset-0 z-10 ${darkOverlay && 'bg-modalScreen bg-opacity-modalScreen'} overflow-y-auto`}>
         <div className="relative w-full max-w-3xl">
            {/* content */}
            <div  {...props} ref={dialogRef}>
               {
                  render({
                     closeDialog,
                     loading,
                     setLoading
                  })
               }
            </div>
            {
               showCloseButton && <IconClose stroke="#2d2d2dc7"
                  onClick={closeDialog}
                  className="w-5 absolute top-2 right-2 z-10 cursor-pointer" />
            }
         </div>
      </div>
   )
}
export default Dialog
