import "./technicalRecommendationsStyle.css"

const TechnicalRecommendations = () => {
    return (
        <div className="p-3">
            <div className="grid grid-cols-1 md:grid-cols-5">
                <div className="col-start-1 md:col-start-2 md:col-span-3 style-container">
                    <h1 className="text-2xl">
                        Recomendaciones técnicas para una mejor
                        experiencia con Portal Místico
                    </h1>
                    <div className="mt-10">
                        <h2>
                            ¿Qué navegador utilizar?
                        </h2>
                        <ul>
                            <li>
                                Se recomienda utilizar un dispositivo que
                                soporte el navegador Google Chrome.
                            </li>
                            <li>
                                Dada sus características técnicas, Portal Místico
                                funciona mejor con el navegador Google Chrome, sin embargo, soportamos otros navegadores también.
                            </li>
                            <li>
                                Se recomienda utilizar las versiones más recientes
                                siempre que sea posible.
                            </li>
                        </ul>
                    </div>
                    <div className="mt-10">
                        <h2>
                            Conexión a Internet
                        </h2>
                        <ul>
                            <li>
                                La característica más importante de este servicio es el ancho de banda, que determina la velocidad de comunicación. Para utilizar las herramientas del sistema en forma adecuada, se recomienda una conexión de 512 kbps o superior. Un servicio con un ancho de banda reducido o entrecortado puede llegar a dificultar el uso del sistema, hacerlo más lento e incluso inoperable.
                            </li>
                            <li>
                                Accede a sitios como <a target="_blank">https://www.speedtest.net/es</a> comprueba cuál es tu capacidad real de conexión, tanto para descargar como para cargar información.
                            </li>
                            <li>
                                Si vas a compartir contenidos, requieres de una buena capacidad de carga, no solo de descarga.
                            </li>
                            <li>
                                Muévete al sitio de tu casa u oficina en el que los resultados sean más favorables, o conéctate vía alámbrica a la red (módem) si esto es posible.
                            </li>
                        </ul>
                    </div>
                    <div className="mt-10">
                        <h2>
                            Zona horaria
                        </h2>
                        <ul>
                            <li>
                            La zona horaria se establece en base a la zona horaria que tenga el navegador que estén usando.
                            </li>
                            <li>
                            Si por alguna razón es necesario actualizar la zona horaria de tu navegador, recomendamos que vuelva a cargar el sitio web para que se tome la nueva zona horaria.
                            </li>
                        </ul>
                    </div>
                    <div className="mt-10">
                        <h2>
                        Configuraciones recomendadas para el navegador
                        </h2>
                        <ul>
                            <li>
                            Aceptar el uso de cookies
                            </li>
                            <li>
                            Habilitar el uso de micrófono y cámara por parte del navegador.
                            </li>
                            <li>
                            Borrar las cookies(archivos temporales) y el cache del navegador si se tiene problemas.
                            </li>
                            <li>
                            Es recomendable no tener desactivado el uso de ventanas emergente (Popups) en tu navegador.
                            </li>
                        </ul>
                    </div>
                    <div className="mt-10">
                        <h2>
                        Configuración de la computadora
                        </h2>
                        <ul>
                            <li>
                            Seleccionar la entrada/salida del audio/video adecuada (¿audífonos?, ¿bocinas?, ¿micrófono auxiliar?)
                            </li>
                            <li>
                            Para una visualización óptima se aconseja una resolución gráfica de 800 x 600 pixeles.
                            </li>
                        </ul>
                    </div>
                    <div className="mt-10">
                        <h2>
                        ¿Problemas con el audio/video?
                        </h2>
                        <ul>
                            <li>
                            Comprobar que otros sonidos si se escuchan en la computadora
                            </li>
                            <li>
                            Cerrar otras aplicaciones o páginas que no sean necesarias para la sesión y pudieran estar usando el micrófono, los audífonos, el video o demandando recursos de procesamiento en la computadora.
                            </li>
                            <li>
                            Usar Google Chrome en su versión más reciente o la versión más reciente de otros navegadores soportados.
                            </li>
                            <li>
                            Probar conectarse con una nueva ventana privada o incógnito para identificar si hay algún bloqueo establecido.
                            </li>
                            <li>
                            Revisar que el navegador no haya bloqueado el uso del micrófono y la cámara para Portal Místico.
                            </li>
                            <li>
                            Revisar que en la computadora está seleccionada la entrada/salida correcta para audio/video.
                            </li>
                            <li>
                            Utilizar alguna diadema o audífonos con micrófono para mejorar la calidad del audio.
                            </li>
                            <li>
                            Reiniciar el navegador después de revisar y corregir configuraciones.
                            </li>
                        </ul>
                    </div>
                    <div className="mt-10">
                        <h2>
                        Algunas opciones adicionales para la solución de problemas:
                        </h2>
                        <ul>
                            <li>
                            Reiniciar la computadora después de revisar la entrada/salida de audio/video.
                            </li>
                            <li>
                            Cerrar las aplicaciones que pueden estar consumiendo recursos de memoria de la computadora.
                            </li>
                            <li>
                            Cambiar de red (Wifi, Alámbrica, Celular).
                            </li>
                            <li>
                            Cambiar de computadora o dispositivo móvil.
                            </li>
                        </ul>
                    </div>
                    <div className="mt-10">
                        <h2>
                        La página no carga
                        </h2>
                        <ul>
                            <li>
                            Revisar la configuración de cookies en tu navegador. Deben estar habilitadas.
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default TechnicalRecommendations