import React from "react"
import Recommended from "../../Components/Recommended"
import { Link } from "react-router-dom"
import assets from "../../Assets"
import { ShieldCheck } from "../../Components/Icons"

const FAQ = () => {
  const sideMenuItems = [
    {
      title: "Manifiesto",
      url: "manifiesto"
    },
    {
      title: "Código de ética",
      url: "codigo-de-etica"
    },
    {
      title: "Satisfacción garantizada",
      url: "satisfaccion-garantizada"
    },
    {
      title: "Testimonios",
      url:"testimonios"
    },
    {
      title: "Contáctanos",
      url:"contacto"
    },
    {
      title: "Preguntas frecuentes",
      url:"preguntas-frecuentes"
    },
    {
      title: "Cómo funciona",
      url:"como-funciona"
    },
    {
      title: "Precios",
      url:"precios"
    }

  ]
  return <>
    <div className="px-3 max-w-md px-6 pt-3 mx-auto lg:max-w-7xl sm:max-w-3xl lg:px-0">
      <section className="grid grid-cols-1 md:grid-cols-5">
        <div className="col-span-2 lg:pr-10 pb-3">
          <h1 className="text-3xl font-bold mb-3">Preguntas frecuentes</h1>
          <SideMenu src={sideMenuItems} />
        </div>
        <div className="col-span-3">
          <img className="rounded" src={assets.ClientServiceHeader} />
          <div className="block mt-5">
            <div className="mb-5">
              <b className="block mb-3">¿Quienes somos?</b>
              <p>
                Portal Místico es una plataforma confiable de asesores espirituales profesionales que se dedican a empoderar vidas ayudando a las personas a descubrir respuestas a sus preguntas más urgentes, lograr un cierre, encontrar su propósito en la vida y más.
                Los expertos en Portal Místico tienen diversas especialidades y pueden ofrecer consultas psíquicas, lectura de cartas, dar consejos sobre el amor y las relaciones, leer el Tarot, responder a cuestiones de la vida, compatibilidad astrológica y muchos temas más. No hay dos expertos en nuestra comunidad iguales, ya que cada uno tiene su propio toque personal cuando se trata de brindar servicios intuitivos y espirituales.
              </p>
            </div>
            <div className="mb-5">
              <b className="block mb-3">¿Qué hacemos en Portal Místico?</b>
              <p>
                En Portal Místico te conectamos con psíquicos expertos en servicios intuitivos y espirituales de una forma segura, desde donde quieras y cuando quieras.
              </p>
            </div>
            <div className="mb-5">
              <b className="block mb-3">¿Por qué Portal Místico?</b>
              <p>
                Por la experiencia, la comprensión y la atención de los expertos y el equipo de servicio al cliente.
              </p>
              <p>
                Los mejores expertos: Conocimiento, dones, habilidades y experiencia. Cada uno de nuestros expertos cuenta con capacidades y estilos diferentes. Encuentra el que más te guste.
              </p>
              <p>
                Flexibilidad: Haz tu consulta desde donde quieras y cuando quieras. Fácil de usar y directo.
              </p>
              <p>
                Código de ética: Nuestros expertos se comprometen a tratar a los clientes con respeto, compasión y profesionalismo.
              </p>
            </div>
            <div className="mb-5">
              <b className="block mb-3">¿Qué es y cómo funciona Portal Místico?</b>
              <p className="mb-3">
                Los expertos de Portal Místico ayudan a los clientes con consejos sobre el amor y las relaciones, lecturas de tarot, preguntas de la vida, compatibilidad astrológica, y consultas psíquicas.
                Sin importar si ya eres cliente regular o nuevo, tenemos diversas formas de contactar a un experto de Portal Místico.
                Conéctate fácilmente con tus psíquicos favoritos:
              </p>
              <ol className="mb-3 pl-4">
                <li className="list-decimal">Crea una cuenta</li>
                <li className="list-decimal">Agrega saldo</li>
                <li className="list-decimal">Elige al psíquico de tu preferencia</li>
                <li className="list-decimal">Haz tu consulta</li>
                <li className="list-decimal">Termina la consulta cuando tú quieras.</li>
              </ol>
              <p className="mb-3">
                Portal Místico está abierto 24/7 y nuestros expertos hacen lo posible por estar disponible en diversas zonas horarias, pero como son humanos y no computadoras, algunas veces, no están disponibles. Así que, si has elegido a un experto y no esta disponible, puedes programar una cita. No hay necesidad de que pierdas tu tiempo esperando si algún experto en específico se pone disponible. Simplemente programa una cita a la hora que te sea más conveniente.
              </p>
            </div>
            <div className="mb-5">
              <b className="block mb-3">¿Qué es Portal Místico?</b>
              <p>
                Portal Místico es una plataforma confiable de asesores espirituales profesionales que se dedican a empoderar vidas ayudando a las personas a descubrir respuestas a sus preguntas más urgentes, lograr un cierre, encontrar su propósito en la vida y más.
                Los expertos en Portal Místico tienen diversas especialidades y pueden ofrecer consultas psíquicas, lectura de cartas, dar consejos sobre el amor y las relaciones, leer el Tarot, responder a cuestiones de la vida, compatibilidad astrológica y muchos temas más. No hay dos expertos en nuestra comunidad iguales, ya que cada uno tiene su propio toque personal cuando se trata de brindar servicios intuitivos y espirituales.
              </p>
            </div>
            <div className="mb-5">
              <b className="block mb-3">¿Cómo funcionan las consultas en línea en Portal Místico?</b>
              <p className="mb-3">
                En Portal Místico puedes hablar de forma segura y privada con un psíquico de confianza por teléfono o videollamada.
                Para tener una consulta en línea con alguno de nuestros expertos solo tienes que darle clic al botón de “llamada” o “videollamada” o puedes agendar previamente una cita. La disponibilidad de horario varía de acuerdo con la agenda del experto que selecciones.
                Hacer una consulta en Portal Místico es muy fácil:
              </p>
              <ul className="mb-5">
                <li>Paso 1: Crea una cuenta</li>
                <li>Paso 2: Agrega saldo</li>
                <li>Paso 3: Elige al psíquico de tu preferencia</li>
                <li>Paso 4: Haz tu consulta</li>
                <li>Paso 5: Termina la consulta cuando tú quieras.</li>
              </ul>
            </div>
            <div className="mb-5">
              <b className="block mb-3">¿Cómo agendar mi primera cita en Portal Místico?</b>
              <p>
                Haz clic en el botón “Agendar cita” del experto que selecciones. La disponibilidad de horario varía de acuerdo con la agenda del experto. Elige el tipo de sesión, la zona horaria, la duración y el horario.
              </p>
            </div>
            <div className="mb-5">
              <b className="block mb-3">¿Cómo tomar mi primera cita en Portal Místico?</b>
              <p>
                Una vez agendada la cita, cuando sea hora solo tienes que iniciar sesión e ingresar a tu cuenta y en la sección “Agenda” darle clic a la cita agendada en la fecha y hora programada.
              </p>
            </div>
            <div className="mb-5">
              <b className="block mb-3">¿Las consultas en línea son efectivas?</b>
              <p>
                Las consultas en línea te permiten recibir la misma atención que recibirías en una sesión presencial, pero de forma remota. Podrás platicar con tu psíquico por videollamada o teléfono sin salir de casa.
              </p>
            </div>
            <div className="mb-5">
              <b className="block mb-3">¿Necesito descargar alguna App de videollamada?</b>
              <p>
                No necesitas descargar ninguna App. dentro de Portal Místico se abrirá la sesión.
              </p>
            </div>
            <div className="mb-5">
              <b className="block mb-3">¿Los pagos son seguros?</b>
              <p>
                ¡Si! Nuestro sistema de pagos está actualizado con los estándares más altos de la industria. Toda la información personal es mantenida y protegida por un procesador de pagos externo. Nosotros nunca almacenamos ninguna información financiera personal.
              </p>
            </div>
            <div className="mb-5">
              <b className="block mb-3">¿Los pagos son seguros?</b>
              <p>
                ¡Si! Nuestro sistema de pagos está actualizado con los estándares más altos de la industria. Toda la información personal es mantenida y protegida por un procesador de pagos externo. Nosotros nunca almacenamos ninguna información financiera personal.
              </p>
            </div>


          </div>
          <div className="relative mt-20 w-full md:w-96 xl:w-full max-w-xs xl:max-w-lg rounded-lg h-24 md:h-28 xl:h-32" style={{ backgroundColor: "#EBEFFF" }}>
            <div className="absolute right-0 inset-y-0 w-xs h-24 md:h-28 xl:h-32 w-40 xl:w-64" style={{ backgroundColor: "#E4E8FF", borderRadius: "36px 0.5rem 0.5rem 36px" }} />
            <div className="absolute right-0 inset-y-0 w-xs h-24 md:h-28 xl:h-32 w-24 xl:w-40" style={{ backgroundColor: "#DEE2FF", borderRadius: "36px 0.5rem 0.5rem 36px" }} />
            <div className="flex flex-row justify-between items-center absolute inset-y-0 inset-x-4 xl:px-3">
              <div>
                <h1 className="text-base xl:text-2xl">Satisfacción garantizada</h1>
                <p className="text-base xl:text-xl text-secondary font-semibold">Te ofrecemos el mejor servicio</p>
              </div>
              <ShieldCheck className="w-10 xl:w-16" />
            </div>
          </div>
        </div>
      </section>
    </div>
    <Recommended />
  </>
}

const SideMenu = ({ src, ...props }) => {
  return <ul className="mt-30 pr-10 lg:pr-0">
    {
      src.map((x, i) => {
        return <li className="mb-6" key={`sub-consultation-${i}`}>
          <Link to={`/${x.url}`} className="pb-3 border-b block max-w-xs">{x.title}</Link>
        </li>
      })
    }
  </ul>
}


export default FAQ