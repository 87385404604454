import React, { useState } from 'react'
import { SecondaryButton } from "../../../../Components/Button"
import authIntegration from "../../../../integrations/auth"
import { parseErrorMsg } from "../../../../utils/misc"
import { IconLoader, IconCross, IconRoundCheck} from "../../../Icons"

export const SuccesEmail = ({exit}) => {
    return <div className="flex flex-col z-10 max-w-2xl bg-white py-9 px-20 rounded-2xl">
        {/* title */}
        <div className="flex flex-col w-full text-lg text-center text-black">
            <h1 className="flex-col text-2xl font-bold">Correo enviado</h1>
            <p className="flex-col pt-2 text-base whitespace-normal">Te hemos enviado un correo electrónico para que puedas recuperar tu contraseña.</p>
        </div>
        
        {/* content */}
        <div className="flex flex-row justify-center mt-6 mb-12">
            <IconRoundCheck className="text-greenSuccess"/>
        </div>
        {/* actions */}
        <div className="flex flex-row justify-center">
            <SecondaryButton
                onClick={exit}
                label="Cerrar"
                className="w-48 py-3 text-sm tracking-wider focus:outline-none"
            />
        </div>
    </div>
}

export const RequestEmail = ({cancel, succes}) => {
    let [loadingReq, setLoadingReq] = useState(false)    
    let [requestError, setRequestError] = useState(null)
    let [email, setEmail] = useState("")

    const requestEmail = async () => {
        setLoadingReq(true)
        setRequestError(null)
        try {
            await authIntegration.requestNewPassword(email)
            succes()
        } catch (err) {
            let errorMsg = parseErrorMsg(err)
            setRequestError(errorMsg)
        }
        setLoadingReq(false)
    }
    
    return <div className="relative z-10 px-24 py-10 bg-white rounded-lg">
        {/* cross */}
        <IconCross
            onClick={cancel}
            className="absolute top-5 right-5 cursor-pointer text-secondaryText"
            style={{ height: 10, width: 10 }}
        />

        {/* title */}
        <div className="justify-center text-center flex-column">
            <h1 className="text-2xl font-bold text-black">Recupera tu contraseña</h1>
            <h3 className="pt-2 text-lg text-secondaryText">Ingresa tu correo electrónico para recuperar tu contraseña</h3>
            {requestError &&
                <h3
                    className="mx-auto font-bold text-red-500">
                    {requestError}
                </h3>
            }
        </div>
        
        {/* content */}
        <div className="my-10 text-black">
            <div className="flex justify-start">
                <label
                    className="block mb-1 text-xs font-semibold"
                    htmlFor="userName">
                    Correo electrónico
                </label>
            </div>
            <input
                className={`text-sm rounded-md w-200 px-3 py-2 h-10 ${requestError ? 'border-red-500 border-2' : 'border-secondary border'} focus:outline-none`}
                id="userName"
                type="text"
                value={email}
                onChange={e => { setEmail(e.target.value) }}
            />
        </div>

        {/* actions */}
        <div className="flex flex-row justify-end">
            <SecondaryButton
                onClick={cancel}
                className="px-6 py-3 mr-5 text-xs tracking-wider focus:outline-none"
                inverted
                label="Cancelar"
            />

            {loadingReq
                ? <SecondaryButton className="px-6 py-3 text-xs tracking-wider focus:outline-none">
                    <IconLoader
                        className="text-white fill-current animate-spin"
                        style={{ height: 20 }}
                        />
                </SecondaryButton>
                : <SecondaryButton
                    label="Recuperar contraseña"
                    className="px-6 py-3 text-xs tracking-wider focus:outline-none"
                    onClick={requestEmail}
                />
            }
        </div>
    </div>
}

export const RequestEmailPanel = ({cancel, succes}) => {
    let [loadingReq, setLoadingReq] = useState(false)    
    let [requestError, setRequestError] = useState(null)
    let [email, setEmail] = useState("")

    const requestEmail = async () => {
        setLoadingReq(true)
        setRequestError(null)
        try {
            await authIntegration.requestNewPassword(email)
            succes()
        } catch (err) {
            let errorMsg = parseErrorMsg(err)
            setRequestError(errorMsg)
        }
        setLoadingReq(false)
    }
    
    return <div>

        {/* title */}
        <div className="justify-center text-center flex-column">
            <h1 className="text-2xl font-bold text-black">Recupera tu contraseña</h1>
            <h3 className="pt-2 text-lg text-secondaryText">Ingresa tu correo electrónico para recuperar tu contraseña</h3>
            {requestError &&
                <h3
                    className="mx-auto font-bold text-red-500">
                    {requestError}
                </h3>
            }
        </div>
        
        {/* content */}
        <div className="my-10 text-black">
            <div className="flex justify-start">
                <label
                    className="block mb-1 text-xs font-semibold"
                    htmlFor="userName">
                    Correo electrónico
                </label>
            </div>
            <input
                className={`text-sm rounded-md w-full px-3 py-2 h-10 ${requestError ? 'border-red-500 border-2' : 'border-secondary border'} focus:outline-none`}
                id="userName"
                type="text"
                value={email}
                onChange={e => { setEmail(e.target.value) }}
            />
        </div>

        {/* actions */}
        <div className="flex flex-row justify-end">
            <SecondaryButton
                onClick={cancel}
                className="px-6 py-3 mr-5 text-xs tracking-wider focus:outline-none"
                inverted
                label="Cancelar"
            />

            {loadingReq
                ? <SecondaryButton className="px-6 py-3 text-xs tracking-wider focus:outline-none">
                    <IconLoader
                        className="text-white fill-current animate-spin"
                        style={{ height: 20 }}
                        />
                </SecondaryButton>
                : <SecondaryButton
                    label="Recuperar contraseña"
                    className="px-6 py-3 text-xs tracking-wider focus:outline-none"
                    onClick={requestEmail}
                />
            }
        </div>
    </div>
}