import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { IconLockClosedWithKey, IconMessage, IconMoney, IconParty, IconUser } from "../../Components/Icons";
import { Heading, Sub } from "../../Components/Typography";
import SlipitCard from "../../Layouts/SlipitCard";
import offers from "../../integrations/offers";
import Loader from "../../Components/Loader"

const OffersPage = () => {
    const [offersList, setOffersList] = useState([])
    const [isLoading, setIsLoading] = useState(true)

    useEffect(async () => {
        const res = await offers.getOffers()
        setOffersList(res?.data)
        setIsLoading(false)
    }, [])

    return (
        <section>
            <Heading>Nuestras ofertas exclusivas</Heading>
            <Sub className="mb-8">Conoce a todos nuestros especialistas que pueden ayudarte</Sub>
            <SlipitCard>
                <div className="flex flex-col">
                    <h1 className="mb-2 text-xl">Oferta de lanzamiento desde $5.00 MXN el minuto.</h1>
                    <span className="font-semibold text-gray-500">Disfruta de nuestra oferta de lanzamiento, única para clientes nuevos.</span>
                    <span className="my-6 text-sm text-gray-500">Selecciona una de nuestras ofertas</span>
                    {
                        isLoading ? <Loader /> : <section className="grid grid-cols-2 sm:grid-cols-3 gap-x-4 md:gap-x-6">
                            {
                                offersList && offersList.map((x, i) => (
                                    <OfferCard selected={!x.extraInfo}
                                        offer={x}
                                        tooltip={x.extraInfo}
                                        icon={x.extraInfo ? <IconParty className="mx-auto w-7" /> : <IconMoney className="mx-auto w-7" />}
                                        title={`$${x.price.toFixed(2)} MXN`}
                                        caption={x.info}
                                        key={`offers-key-${i}`}
                                    />
                                ))
                            }
                        </section>
                    }
                    <section className="mt-12">
                        <h1 className="mb-4 font-semibold tracking-wider text-center">Tenemos para ti el mejor servicio</h1>
                        <div className="flex flex-col px-8 py-4 border rounded border-secondary gap-y-4">
                            <ServiceFeature icon={<IconUser className="h-6 mx-auto" />} text="Los mejores expertos" />
                            <ServiceFeature icon={<IconLockClosedWithKey className="h-6 mx-auto" />} text="Protegemos tu privacidad" />
                            <ServiceFeature icon={<IconMessage className="h-6 mx-auto" />} text="Horarios accesibles" />
                        </div>
                    </section>
                </div>
            </SlipitCard>
        </section>
    )
}

//#region Subcomponents
const OfferCard = ({ title, caption, icon, tooltip, selected, offer }) => {
    let cardSelected = "bg-secondary border-2 text-white"
    let cardUnselected = "border-secondary-light border-2"
    let cardStyle = !selected ? ({ marginTop: "-1em" }) : ({ marginTop: "-1em" })

    return (
        <Link className="col-span-1 relative mt-5" to={{
            pathname: "/registrarse",
            state: {
                offer: offer
            }
        }} >
            <div className="flex flex-col items-end absolute right-0 -top-6">
                {!tooltip ? null :
                    <span className={`px-2 py-1 text-xs ${!selected ? "text-white bg-secondary" : "text-secondary bg-white border border-secondary"} rounded`}>{tooltip}</span>
                }
            </div>
            <div style={cardStyle} className={"flex h-full flex-col px-4 py-8 rounded-lg text-center " + (selected === true ? cardSelected : cardUnselected)}>
                <span className="my-5 mx-auto">
                    <img src={offer.icon} className="rounded-full w-20 h-20 object-cover" />
                </span>
                <b className="flex-row mx-auto">{title}</b>
                <span className={"flex-row text-sm mx-auto " + (selected === true ? 'text-white' : 'text-gray-500')}
                     style={{wordBreak:'break-word', hyphens:'auto'}}>{caption}</span>
            </div>
        </Link>
    )
}

const ServiceFeature = ({ icon, text }) => (
    <article className="flex flex-row">
        <span className="flex flex-row items-center content-center flex-shrink w-8 h-8 rounded bg-secondary-lightest">{icon}</span>
        <span className="flex flex-row items-center content-center flex-grow pl-4 text-xs"> {text}</span>
    </article>
)
//#endregion

export default OffersPage