import React, { useContext } from "react"
import { Heading } from "../../Components/Typography";
import { LinkButton } from "../../Components/Button";
import { Context } from "../../store/useGlobalState"

const Terms = () => {
    const { globalState } = useContext(Context)

    return <div className="px-3 max-w-md px-6 pt-3 mx-auto lg:max-w-7xl sm:max-w-3xl lg:px-0">
        <Heading className="mb-10">Limitación de responsabilidad y términos</Heading>
        <h4 className="font-bold">Limitación de responsabilidad</h4>
        <p className="my-5">
            Nuestro servicio y sitio web son solo para fines de entretenimiento. Este servicio es para adultos mayores de 18 años. Nuestros Consejeros psíquicos hacen su mejor esfuerzo para proporcionarte información de utilidad y de perspectiva. Sin embargo, la información que te ofrecen nuestros Consejeros no se debe utilizar en reemplazo de indicaciones de profesionales de la medicina u otros consejeros profesionales. Queremos que disfrutes tus experiencias con Portal Místico y que te beneficies de ellas.
        </p>
        <p className="my-5">
            Portal Místico no es responsable del contenido o las prácticas privadas de sitios que no pertenecen a Portal Místico de los que Portal Místico provee el enlace.
        </p>
        <h4 className="font-bold">Uso de nuestros servicios</h4>
        <p className="my-5">
            Portal Místico nunca divulgará intencionadamente los contenidos de las interacciones entre nuestros miembros y psíquicos. Al usar nuestros servicios de teléfono, video, chat y mensajes, brindas tu consentimiento para que el contenido de estas comunicaciones sea grabado o almacenado de forma privada para tu protección y garantizar la calidad.
        </p>
        <h4 className="font-bold">Términos y condiciones</h4>
        <p className="my-5">
            Al usar el sitio web de Portal Místico® aceptas estos Términos y condiciones. A menos que se enmienden o modifiquen, estos Términos y condiciones permanecerán en efecto mientras seas Usuario, Suscriptor o Miembro. Podemos revisar estos Términos y condiciones en cualquier momento mediante la actualización de esta página. Si no estás de acuerdo con estos Términos y condiciones, no uses nuestro sitio.
        </p>
        <h4 className="font-bold">Definiciones</h4>
        <ul className="my-5 list-disc list-inside">
            <li><b>Contenido:</b> cualquier información publicada en Portal Místico por la compañía o algún usuario, incluidos los enlaces a otros sitios.</li>
            <li><b>Miembros:</b> aquellos que eligen usar el servicio por teléfono o en línea de Portal Místico a través de este sitio web.</li>
            <li><b>Miembros nuevos:</b> aquellos que nunca han comprado una consulta.</li>
            <li><b>Portal Místico:</b> la presencia en línea de Portal Místico®.</li>
            <li><b>Servicio:</b> cualquier provisión del servicio y esta página web.</li>
            <li><b>Suscriptores:</b> cualquier persona que se anote para cualquiera de nuestras características en línea o utilice nuestros procedimientos definidos.</li>
            <li><b>Usuario:</b> cualquiera, suscriptor o no, que visite Portal Místico o un servicio afiliado.</li>
            <li><b>Tú: </b> cualquier Usuario de este sitio web y servicio, incluidos los Suscriptores o Miembros.</li>

        </ul>
        <h4 className="font-bold">Compra en línea de tiempo para el servicio por chat, video y telefóno de Portal Místico ("comercio electrónico")</h4>
        <p className="my-5">
            Nuestro sitio web, Portal Místico, te ofrece la posibilidad de agregar dinero a tu cuenta para nuestros servicios por telefóno, video y chat psíquico en línea. Al crear una cuenta de Portal Místico estás de acuerdo con que el comercio electrónico se provea como una conveniencia y que Portal Místico o Portal Místico se reserva el derecho de restringir el uso de tal servicio a cualquier miembro. Todas y cada una de las ofertas para los miembros nuevos se aplican a los clientes por primera vez que nunca han hecho una compra a Portal Místico. Las cuentas creadas falsamente serán sujetas a revisión y suspensión. Todas las preguntas relacionadas con nuestras membresías de servicio telefónico deben ser dirigidas a la línea del Centro de Atención al Cliente

            al <a className="text-secondary border-b border-secondary" href={`tel:${globalState?.contactNumber}`}>
                    {globalState?.contactNumber}
                </a>. Debido al carácter sensible de tu información personal, no podemos responder correos electrónicos sobre la información de la cuenta de membresía. Estás de acuerdo con que la información que nos provees es verdadera, acertada y completa.
        </p>
        <h4 className="font-bold">Registro, cuentas de cliente, uso del sitio</h4>
        <p className="my-5">
            Antes de realizar una compra, debes establecer una cuenta de cliente. Eres responsable de mantener la confidencialidad de la información sobre tu cuenta de cliente, incluidos tu número de identificación personal (PIN) y toda la actividad que ocurre en tu cuenta. Estás de acuerdo en notificar inmediatamente a

            Portal Místico sobre cualquier uso no autorizado de tu cuenta o contraseña, o sobre cualquier otra violación de seguridad. Puedes ser considerado responsable de los gastos incurridos por Portal Místico o cualquier otro usuario del sitio si alguien más utiliza tu PIN o cuenta de cliente. No debes usar el PIN o cuenta de cliente de otra persona en ningún momento. No debes intentar obtener acceso no

            autorizado al sitio, ayudar a otros a hacer tales intentos ni distribuir instrucciones, software o instrumentos con ese propósito. Si llegaras a intentarlo, se cancelará tu cuenta de cliente. Estás de acuerdo con proveernos información acertada, actual y completa sobre ti y tu información de facturación como se pide en el proceso de registro. Puedes actualizar la información de tu cuenta de cliente, designar una tarjeta de crédito para facturar diferente o cambiar la fecha de vencimiento aplicable en tu tarjeta de crédito actual al hacer clic en el botón de Iniciar sesión, iniciar sesión en tu cuenta y seleccionar el enlace apropiado. No puedes usar ningún dispositivo, programa, algoritmo o metodología automática o cualquier proceso manual similar o equivalente para acceder, adquirir, copiar, investigar, probar o controlar cualquier porción del sitio web o Contenido. De ninguna manera puedes reproducir o evadir la estructura de navegación o presentación del sitio web o de cualquier Contenido, para obtener o intentar obtener cualquier material, documento o información mediante cualquier medio no disponible intencionalmente a través del sitio. Estás de acuerdo en que no realizarás ninguna acción que imponga una carga irrazonable o desproporcionada sobre la infraestructura del sitio o de cualquier sistema o red que comprenda el sistema o esté conectada a este. Ten en cuenta que Portal Místico puede, a su libre y exclusiva discreción y sin previo aviso, cancelar tus capacidades para realizar compras por cualquier o ninguna razón. ESTÁS DE ACUERDO EN QUE
            Portal Místico NO SERÁ RESPONSABLE ANTE TI O UN TERCERO POR LA
            CANCELACIÓN DE TUS CAPACIDADES PARA REALIZAR COMPRAS EN EL SITIO WEB O
            DE CUALQUIER OTRA MANERA.
        </p>
        <h4 className="font-bold">Condiciones de venta y términos de pago</h4>
        <p className="mt-5">
            Para comprar cualquier producto o servicio en nuestro sitio web, debes (a) tener al
            menos 18 años de edad o la mayoría de edad aplicable en el estado, la que sea
            mayor y (b) ser una persona física (no una corporación, sociedad u otra entidad
            legal). Antes de la compra de cualquier servicio en nuestro sitio web, debes
            brindarnos un número de tarjeta de crédito e información de pago asociada válida
            que incluya todo lo siguiente: (i) tu nombre como aparece en la tarjeta, (ii) tu
            número de tarjeta de crédito, (iii) el tipo de tarjeta de crédito, (iv) la fecha de
            vencimiento y (v) cualquier número o código de activación necesarios para realizar
            cargos en tu tarjeta. Al proporcionar esa información, estás de acuerdo por la
            presente en que nos autorizas a realizar cargos en tu tarjeta de crédito a
            conveniencia pero dentro de los treinta (30) días de la autorización de la tarjeta de
            crédito.
        </p>
        <p className="mb-5">
            Si desea cerrar o suspender su cuenta, favor de comunícarse con servicio al cliente
            al <a href={`tel:${globalState?.contactNumber}`} className="text-secondary border-b border-secondary">{globalState?.contactNumber}</a>. No ofrecemos cancelaciones de servicio. Portal Místico es un
            servicio prepagado y solo reembolsaremos los balances no utilizados en tu cuenta.
        </p>
        <h4 className="font-bold">Impuesto estatal y local </h4>
        <p className="my-5">La tasa de impuestos aplicada a su compra será el impuesto estatal y local combinado basado en su dirección de facturación. Actualmente, cobramos todos los impuestos aplicables para el estado de Jalisco, México. </p>

        <h4 className="font-bold">Garantía de Satisfacción</h4>
        <p className="my-5">Aunque hacemos todo el esfuerzo posible para proporcionarte una comunidad de Consejeros Psíquicos auténticos, seleccionados, entendemos que haya momentos en los que no obtengas una consulta psíquica que satisfaga completamente tus expectativas. Nuestra Garantía de Satisfacción fue creada con la intención de proporcionarte una oportunidad de encontrar a un Psíquico que te ofrezca una experiencia excepcional en la consulta, sin riesgo para ti. Para solicitar una devolución, llama al Centro de Atención al Cliente o envía un correo electrónico a través de nuestra página de Contacto.</p>
        <p className="my-5">Si eres un cliente actual de Portal Místico y no estás contento con tu última consulta pagada, con gusto te regresaremos el tiempo para que hables con otro Psíquico de acuerdo a las siguientes directrices:</p>
        <ul className="my-5 list-disc list-inside">
                <li>Debes solicitar tu devolución de tiempo dentro de un (1) día,</li>
                <li>Solo será devuelto el tiempo de la última consulta pagada, hasta por 20 minutos.</li>
                <li>El tiempo devuelto será aplicado a tu cuenta en pesos de Bonificación de Portal Místico equivalente a la cantidad de tiempo hasta por 20 minutos a la tarifa por minuto del Consejero Psíquico con quien hayas tenido la última consulta pagada. No se otorgarán devoluciones de dinero en efectivo.</li>
                <li>Una vez que hayas solicitado y recibido hasta 20 minutos de timpo de devolución, no podrás solicitar otra devolución de tiempo con el mismo Consejero Psíquico. La Garantía de Satisfacción puede ser usada solo una vez por Consejero Psíquico.</li>
                <li>Tu cuenta de Portal Místico deberá estar en buenos términos para poder solicitar y recibir tu tiempo de devolución.</li>
        </ul>
        <p className="my-5">Si eres cliente por primera vez de Portal Místico y solicitas un reembolso en efectivo dentro de un (1) día de tu consulta psíquica, con gusto te otorgaremos el reembolso. Por favor toma en cuenta que Portal Místico se reserva el derecho de prevenir que uses el servicio en el futuro.</p>
        <p className="my-5">Portal Místico se reserva el derecho de rechazar cualquier solicitud de devolución de tiempo.</p>

        <h4 className="font-bold">Métodos de pago, términos de la tarjeta de crédito</h4>
        <p className="my-5">Todos los pagos deben hacerse con VISA, MasterCard, American Express, Discover o Diners club (cada una de las cuales puede ser eliminada por Portal Místico a su única discreción). A menos que se establezca expresamente en el sitio de Portal Místico, no aceptamos otra forma de pago. Si ofrecemos o aceptamos cualquier otra forma de pago, por la presente Tú estás de acuerdo a todas las restricciones, términos y condiciones asociadas con dichas formas de pago adicional. El acuerdo con el emisor de la tarjeta gobierna tu uso de la tarjeta designada y debes referirte a ese acuerdo y no a estos Términos de uso para determinar tus derechos y responsabilidades como titular de una tarjeta. TÚ, Y NO Portal Místico, ERES RESPONSABLE DE PAGAR CUALQUIER MONTO NO AUTORIZADO FACTURADO EN TU TARJETA DE CRÉDITO POR UN TERCERO. Estás de acuerdo en pagar todos los cobros y cargos incurridos en relación con tus compras en las tarifas efectivas cuando se incurren los cargos. A menos que notifiques a Portal Místico de cualquier discrepancia dentro de los treinta (30) días después de que aparezca por primera vez en tu resumen de tarjeta de crédito, se considerarán aceptadas para todos los propósitos. Si Portalmistico.com recibe el pago de tu emisor de tarjeta de crédito o su agente, Tú estás de acuerdo en pagar todos los cargos bajo demanda de Portalmistico.com sus agentes.</p>
        
        <h4 className="font-bold">Política de aceptación de órdenes</h4>
        <p className="my-5">La recepción de una confirmación de orden electrónica o de otro medio no significa que aceptes la orden ni constituye la confirmación de nuestra oferta de brindar un servicio. Portal Místico se reserva el derecho de aceptar o rechazar tu orden por cualquier razón en cualquier momento posterior a recibir tu orden. Toda la información de pago debe obtener aprobación previa con un método de pago aceptable, tal como establece nuestro departamento de prevención de crédito y fraude. Podemos requerir verificaciones o información adicional antes de aceptar una compra.</p>

        <h4 className="font-bold">Modificaciones de los precios o términos de facturación</h4>
        <p className="my-5">Portalmistico.com SE RESERVA EL DERECHO DE CAMBIAR EN CUALQUIER MOMENTO SUS PRECIOS Y MÉTODOS DE FACTURACIÓN POR LOS PRODUCTOS Y SERVICIOS VENDIDOS, EFECTIVO INMEDIATAMENTE DESDE LA PUBLICACIÓN EN EL SITIO WEB O ENVÍO POR CORREO ELECTRÓNICO.</p>

        <h4 className="font-bold">Limitación de responsabilidad y Garantía</h4>
        <p className="my-5">Bajo ninguna circunstancia seremos responsables ante ningún Suscriptor, no Suscriptor, Miembro o no Miembro en relación con el uso de este Servicio. Nuestra responsabilidad máxima no será mayor que la cuota que Tú has pagado a Portal Místico, si la hubiere.</p>
        <p className="my-5">El Usuario de este sitio web lo utiliza bajo el riesgo del Usuario. Ni Portal Místico ni ninguno de sus funcionarios, directores, empleados, agentes, terceros proveedores de contenido, comerciantes, patrocinadores licenciantes o similares, garantizan que cualquiera de los sitios web funcionarán sin interrupción o errores, ni realizan ninguna garantía sobre los resultados que se obtendrán del uso del sitio web o de sitios web desarrollados por Portal Místico en el futuro, ni sobre la precisión, confiabilidad o intemporalidad de cualquier contenido de información, servicio o mercancía provista por el sitio web. No garantizamos que este sitio ni los correos electrónicos que enviamos estén libres de virus o componentes dañinos. EXPRESAMENTE RECHAZAMOS TODA GARANTÍA DE CUALQUIER TIPO, YA SEA EXPRESA O IMPLÍCITA, INCLUIDAS PERO NO LIMITADAS A, LAS GARANTÍAS IMPLÍCITAS DE COMERCIABILIDAD, LA ADECUACIÓN PARA UN PROPÓSITO PARTICULAR Y LA NO VIOLACIÓN DE DERECHOS DE TERCEROS.</p>
        
        <h4 className="font-bold">Uso inapropiado</h4>
        <p className="my-5">No debes usar el sitio web o los servicios de chat o video o servicio por teléfono para cualquier actividad fraudulenta, acosadora, ofensiva o ilegal. El comportamiento lascivo u obsceno que sea indecente no será tolerado y resultará en que seas suspendido de usar el servicio. Estás de acuerdo en no involucrarte en ninguna actividad que podría perjudicar el funcionamiento apropiado del sitio web. No debes, y al usar el sitio estás de acuerdo, en no hacerte pasar por otra persona o enviar material que contenga virus u otra rutina o motor de programación destinado a dañar, interferir o interceptar algún sistema, datos o información. Podemos investigar las ocurrencias que creemos pueden involucrar cualquiera de estas actividades y cooperar con las autoridades de cumplimiento de la ley en la interposición de una acción judicial ante cualquier violación de la ley. Si exhibes cualquiera de estas actividades, Portal Místico tiene el derecho de suspender tu cuenta.</p>
        <p className="my-5">Estás de acuerdo en que las leyes de Jalisco, México, sin consideración de conflictos de provisiones legales, gobernarán cualquier disputa sobre estos Términos y condiciones.</p>
        <p className="my-5">NUESTRO SITIO WEB PODRÍA CONTENER INEXACTITUDES TÉCNICAS O DE OTRO TIPO O ERRORES TIPOGRÁFICOS. SE AGREGAN CAMBIOS PERIÓDICAMENTE A LA INFORMACIÓN AQUÍ PRESENTE; ESTOS CAMBIOS SE INCORPORARÁN A LAS NUEVAS EDICIONES DEL SITIO WEB.</p>
        
        <h4 className="font-bold">Todos los derechos reservados</h4>
        <p className="my-5">El sitio web y todos sus contenidos están protegidos por leyes de propiedad intelectual, de marcas comerciales u otras leyes. Ninguna parte puede ser reproducida, almacenada en sistemas de recuperación o transmitida en ninguna forma o mediante cualquier medio, electrónico, mecánico, de fotocopia, grabación u otros, sin el permiso escrito de Portal Místico.</p>
        <p className="my-5">Este Acuerdo de Términos y condiciones constituye un acuerdo completo entre Tú y nosotros y gobierna tu uso del Servicio, teniendo validez sobre cualquier otro acuerdo previo entre Tú y nosotros con respecto al Servicio.</p>
        
        <LinkButton to="/" label="Volver a inicio"/>
    </div>
}

export default Terms