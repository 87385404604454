import React, { useContext } from 'react'
import { IconLoader } from '../Icons'
import { Calendar, Summary } from "../../Components/Schedule"
import { Context } from '../../store/useGlobalState'
import { ToastError } from '../Toast'

export const ModalSchedule = ({ visible, hideSelf, id, handleConfirm, expert, 
            time, setTime, timezone, setTimezone, date, 
            setSelectedDate, duration, setDuration, sessionType, 
            setSessionType, saving, calculatingInfo, setCalculatingInfo }) => {
    const { globalState } = useContext(Context)
    const { user } = globalState

    return (visible &&
        <div className="fixed z-20 flex overflow-y-auto justify-center items-center cursor-auto w-full top-0 left-0" 
            style={{ width: "100%", height: "100%", backgroundColor:'rgba(10,11,12,0.8)' }}>
            {/*transparent background that hides modal when clicked*/}
            <div className={"w-full h-full"} onClick={hideSelf} >
                {/*actual modal*/}
                <div onClick={(e)=>{e.stopPropagation()}}
                    className={"p-5 bg-white border rounded-2xl shadow-lg w-full md:w-5/6  sm:w-4/6 px-10 max-w-4xl"}
                    style={{ borderColor: "#F4F6FD", margin: '10px auto' }}>
                    <div className="flex flex-col justify-start items-center max-w-md sm:px-6 pt-3 mx-auto text-center lg:max-w-7xl sm:max-w-3xl sm:px-0">
                        <div className="self-start">
                            <h1 className="mt-1 md:mt-2 text-2xl text-left font-extrabold self-start tracking-tight text-gray-900 sm:text-2xl">
                                Agendar tu cita
                            </h1>
                            <p className="mt-1 md:mt-2 text-left mx-auto text-gray-500 text-1xl tracking-normal pb-3 md:pb-10">
                                Selecciona una fecha para tu cita
                            </p>
                        </div>

                        <div className="w-full grid grid-cols-1 gap-y-2 sm:gap-y-5 gap-x-5 md:grid-cols-5 lg:grid-cols-2 ">
                            <div className="col-span-1 md:col-span-3 lg:col-span-1">
                                <Calendar
                                    selectDate={date}
                                    setSelectedDate={setSelectedDate}
                                    idExpert={id}
                                    expert={expert}
                                /></div>
                            <div className="col-span-1 md:col-span-2 lg:col-span-1">
                                <Summary
                                    expert={expert}
                                    selectDate={date}
                                    setTime={setTime}
                                    hide={true}
                                    setTimezone={setTimezone}
                                    timezone={timezone}
                                    time={time}
                                    duration={duration}
                                    setDuration={setDuration}
                                    onConfirm={handleConfirm}
                                    sessionType={sessionType}
                                    setSessionType={setSessionType}
                                    calculatingInfo={calculatingInfo}
                                    setCalculatingInfo={setCalculatingInfo}
                                /></div>
                        </div>

                    </div>
                    <div className="w-full flex justify-center md:justify-end">
                        <div className="w-full md:w-80 mt-3 grid grid-cols-2  sm:grid-cols-2 gap-1 px-2 pb-4 mr-0 lg:mr-8 gap-x-8 gap-y-2 ">
                            <button onClick={() => {
                                hideSelf(true)
                            }} className="bg-superLight h-10 px-2 py-3 mx-auto text-xs w-full rounded text-secondary font-bold flex items-center justify-center">
                                <span className='fill-current' style={{ height: 20 }}>Cancelar</span>
                            </button>
                            <button onClick={() => {
                                if (!user) {
                                    ToastError("Debe de iniciar sesión para poder agendar")
                                }
                                else {
                                    handleConfirm()
                                }
                            }} disabled={saving || calculatingInfo}
                            className={`${(saving || calculatingInfo) ? 'bg-secondary-light':'bg-secondary'}
                                    h-10 px-2 py-3 mx-auto text-xs w-full rounded text-white font-bold flex items-center justify-center`} >
                                {
                                    saving? (<IconLoader
                                        className="text-white fill-current animate-spin"
                                        style={{ height: 20 }}
                                    />): 
                                        ('Continuar')
                                }
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ModalSchedule
