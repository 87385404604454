// Formatea la cantidad como moneda
export const formatCurrency = (amount, currency) => {
   let formatter = null;
   switch (currency) {
      // Dólares
      case 'USD':
         formatter = new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD',
         });
         return formatter.format(amount);

      case 'number': formatter = new Intl.NumberFormat('en-MX', {
            currency: 'MXN',
         });
         return formatter.format(amount);

      // Pesos mexicanos
      default:
         formatter = new Intl.NumberFormat('en-MX', {
            style: 'currency',
            currency: 'MXN',
         });
         return formatter.format(amount);
   }
}


/**
 * Devuelve un número a partir del valor recibido
 * @param {string|number} value El valor a ser analizado
 * @returns Un número (flotante o entero)
 */
export const toNumber = (value) => {
   let thevalue = `${value}`.trim()
   return isNaN(thevalue) ? 0 : parseFloat(thevalue)
}


/**
 * Suma dos números
 * @param {string|number} addendA El primer valor a sumar (si no es válido, valdrá 0)
 * @param {string|number} addendB El segundo valor a sumar (si no es válido, valdrá 0)
 * @returns La suma de los dos valores. Devuelve 0 si algún valor no es numérico
 */
export const sum = (addendA, addendB) => toNumber(toNumber(addendA) + toNumber(addendB))


/**
 * Resta dos valores y devuelve un valor numérico
 * @param {string|number} minuend Minuendo
 * @param {string|number} subtracting Sustraendo
 * @returns Resultado de la resta. Devuelve 0 si algún valor no es correcto
 */
export const subtract = (minuend, subtracting) => toNumber(toNumber(minuend) - toNumber(subtracting))


/**
 * Multiplica dos valores
 * @param {string|number} factorA Factor a
 * @param {string|number} factorB Factor b
 * @returns Producto de la multiplicación. Devuelve 0 si algún valor no es correcto
 */
export const multiply = (factorA, factorB) => toNumber(toNumber(factorA) * toNumber(factorB))


/**
 * Divide dos valores
 * @param {string|number} dividend
 * @param {string|number} divider
 * @returns El resultado de la división. Si no se pudo realizar devolverá 0
 */
export const divide = (dividend, divider) => toNumber(toNumber(dividend) / toNumber(divider))