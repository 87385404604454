import React, { useContext } from 'react'
import { Redirect } from 'react-router-dom'
import Footer from '../Components/Footer'
import Navbar from '../Components/Navbar'
import { Context } from '../store/useGlobalState'

export const UserProfileLayout = ({ children }) => {
    const { globalState } = useContext(Context)

    if (globalState.user) {
        return (<>
            <Navbar />
            <div className="max-w-5xl mx-auto pb-9">
                {children}
            </div>
            <Footer />
        </>)
    }

    return <Redirect to="/" />
}

export default UserProfileLayout