import { useState, useEffect } from 'react'
import assets from "../../Assets"
import palete from "../../Assets/palete"
import reviews from '../../integrations/reviews'
import Loader from '../../Components/Loader'
import {UserProfileIcon} from '../../Components/Icons'
import './home.css'

const Testimonials = () => {
    const [lastReviews, setLastReviews] = useState([])
    const [loading, setLoading] = useState(false)
    const [currentIndex, setCurrentIndex] = useState(0)
    
    useEffect(() => {
        const fetchData = async () => {
            setLoading(true)
            try {
                const data = await reviews.getLastReviews()

                if (data.length>1) {
                    const item0 = data[0];
                    const item1 = data[data.length - 1];
                    data.push(item0)
                    data.splice(0, 0, item1)
                }

                setLoading(false)
                setLastReviews(data)
                setCurrentIndex(data.length>1?1:0)
            } catch (error) {
                setLastReviews([])
                setLoading(false)
            }
        }

        fetchData()

    }, [lastReviews.length])

    const onNext = () => {
        const nextIndex = currentIndex + 1
        if (lastReviews.length > 2) {
            setCurrentIndex(nextIndex<(lastReviews.length-1)?nextIndex:(1))
        }
    }

    const onPrev = () => {
        const prevIndex = currentIndex - 1
        if (lastReviews.length > 2) {
            setCurrentIndex(prevIndex<1?(lastReviews.length-2):prevIndex);
        }
    }

    return (
        <section className="max-w-5xl py-10 mx-auto">
            <div className="flex flex-row pt-9">
                <h1 className="w-full mb-6 text-3xl tracking-wider text-center">
                    Lo que nuestros clientes opinan
                </h1>
            </div>

            <div className="flex flex-row">
                {loading
                    ? <div style={{ margin: 'auto' }}>
                        <Loader />
                    </div>
                    : (
                        <>
                            <aside className="flex flex-row items-center content-center flex-shrink px-1 sm:px-4 xl:px-12" onClick={onPrev}> 
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="rgb(103,115,223)" class="bi bi-chevron-left" viewBox="0 0 16 16">
                                <path stroke={'rgb(103,115,223)'} strokeWidth={'1.5'} fill-rule="evenodd" d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z"/>
                                </svg>
                            </aside>
                            <div className="flex-col flex-grow overflow-x-hidden">
                                {lastReviews.length > 0 ? 
                                (<div className="flex flex-row overflow-hidden relative" 
                                    style={{width: "100%", justifyContent: "center", height:'310px'}}>
                                    {
                                        lastReviews.map((opinion, idx) => 
                                            <Opinion 
                                                currentIndex={currentIndex}
                                                index={idx}
                                                key={opinion.id} 
                                                text={opinion.commentary}
                                                user={opinion.user}
                                                rate={opinion.rating}
                                            />
                                        )
                                    }
                                </div>)
                                : null                          
                                }
                            </div>
                            <aside className="flex flex-row items-center content-center flex-shrink px-1 sm:px-4 xl:px-12" onClick={onNext}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="rgb(103,115,223)" class="bi bi-chevron-right" viewBox="0 0 16 16">
                                <path stroke={'rgb(103,115,223)'} strokeWidth={'1.5'} fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"/>
                                </svg> 
                            </aside>
                        </>
                    )
                }
            </div>
        </section>
    )
}

// #region Subcomponents
const Opinion = ({ text, user, rate, index, currentIndex }) => {
    const styles = {
        ...palete.styles.shadow,
    }

    const furtherStyle = {
        opacity: 0.5
    }

    const opinionStyles = {
        margin: "3em 1em",
        transition: 'min-width 0.3s'
    }

    const imageIconStyles = {
        display: 'inline-block',
        width: '54px',
        height: '54px',
        borderRadius: '54px',
        border: '2px solid lightgray'
    }

    const indexFlag = index>(currentIndex+1) || index<(currentIndex-1);

    return (
        <article className="opition-carousel-item" style={{...opinionStyles, ...indexFlag?{
            minWidth: '0px',
            width: '0px',
            margin: '0px',
            overflow: 'hidden'
        }:null}}>
            <div className="pb-6" style={currentIndex==index?styles:undefined}>
                <div>
                    <img src={index==currentIndex? assets.Icon_Quote: assets.Icon_Quote_Blue} 
                    style={{ marginTop: "-1.5em", marginLeft: "-1em" }} alt="Opinion" />
                </div>
                <blockquote className="px-1" 
                    style={index!=currentIndex?furtherStyle:undefined}>
                    <div style={{maxWidth:'100%', 
                            maxHeight:'60px', 
                            whiteSpace: 'normal',
                            wordWrap:'break-word', 
                            lineHeight:'18px',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis'}}>
                        <p className="px-6 md:px-12 pr-2 mb-6 ">
                            <small style={{fontWeight:600, color:'#0B0B28', fontSize:'0.8rem'}} >
                                {text}
                            </small>
                        </p>
                    </div>
                    <footer className="p-6">
                        <div className="flex flex-col md:flex-row items-center content-center">
                            <div>
                                {user.image
                                    ? <img style={{...imageIconStyles}}
                                    src={user.image} alt="Micaela Quintero - Lectura de tarot" />
                                    : <div className="w-10" style={{margin: 'auto'}}><UserProfileIcon/></div>
                                }
                            </div>
                            <div className="ml-4 pl-0.5">
                                <div className="text-xs font-bold tracking-widest text-indigo-500">
                                    {user.username}
                                </div>
                                <div className="pt-2 text-xs tracking-tight text-gray-400">Calificación: {rate}/5</div>
                            </div>
                        </div>
                    </footer>
                </blockquote>
            </div>
        </article>
    )
}
// #endregion

export default Testimonials