import { mergeClassName } from "../../utils/misc"

const CTAPrimaryButton = (props) => {
    props = mergeClassName('focus:outline-none flex items-center justify-center text-white bg-primary rounded-md cursor-pointer', props)
    
    if(props.children){
        return (
            <div {...props}>
                {props.label ? props.label : props.children}
            </div>
        )
    }

    return (
        <button type="submit" {...props}>
            {props.label ? props.label : props.children}
        </button>
    )
}

export default CTAPrimaryButton