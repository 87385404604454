import React from "react";

// TODO: remove
import assets from "../Assets"

// Avatar
export const Avatar = (props) => (
    <div {...props}>
        <div className="table w-full h-full overflow-hidden text-center rounded-full shadow-inner cursor-pointer group bg-purple">
            <span className="hidden font-bold text-white align-middle group-hover:table-cell">KR</span>
            <img src={assets.Home_Physic4} alt="userAvatar" className="object-cover object-center w-full h-full visible group-hover:hidden" />
        </div>
    </div>
)

export default Avatar