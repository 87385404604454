import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";
import RecomendedLectures from "../../../Components/RecomendedLectures"
import PseudoLinkButton from "../../../Components/Button/PseudoLinkButton"
import { IconArrowLeft } from "../../../Components/Icons"
import RecentVideos from "./RecentVideos"
import consultationMethods from "../../../integrations/consultation"
import Loader from "../../../Components/Loader"

const ConsultationIndex = () => {
    const { consultationId } = useParams()
    const [consultations, setConsultations] = useState([])
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        if (consultationId) {
            window.scrollTo(0, 0);
        }
    }, [consultationId])

    useEffect(async () => {
        try {
            const res = await consultationMethods.getConsultations(4, 1)
            setLoading(false)
            setConsultations(res?.data)
        } catch (error) {
            setLoading(false)
        }
    }, [])

    const subTitle = "Descubre cómo son las consultas psíquicas, cómo trabajan los expertos, que puedes esperar de la consulta y muchas cosas más."
    return <div className="w-full pt-20 max-w-md px-6 pt-3 mx-auto lg:max-w-7xl sm:max-w-3xl lg:px-0">
        <section className="w-full bg-consultation bg-cover bg-center text-white rounded px-10 md:px-20 py-20 lg:py-30">
            <h1 className="text-3xl font-bold mb-3">Consultas psíquicas</h1>
            <sub className="block text-base text-white md:w-1/2 lg:text-xl">{subTitle}</sub>
        </section>
        {
            loading ? <Loader /> : <Consultations source={consultations} />
        }
        <section className="grid grid-cols-1 md:grid-cols-5 xl:grid-cols-12 mt-10">
            <div className="col-span-3 xl:col-span-8">
                <RecentVideos />
            </div>
            <div className="col-span-2 xl:col-span-4 md:mt-20">
                <RecomendedLectures />
            </div>
        </section>
    </div>
}

const Consultations = ({ source }) => {
    return <section className="grid grid-cols-1 md:grid-cols-2 gap-2 mt-10">
        {
            source.map((x, i) => {
                return <ConsultationItem data={x} goToTerms={i===3} key={`Consultations-${i}`} />
            })
        }
    </section>
}

const ConsultationItem = ({ data, goToTerms=false }) => {
    const link = goToTerms ? '/glosario': (`/consultas/${data._id}`)
    return <Link to={link} className="mb-5 ml-0 md:ml-3 border-b border-primary-lightest ">
        <div className="flex flex-col xl:flex-row items-start">
            <img src={data.image} className="rounded-md w-full h-72 md:w-40 md:h-30" />
            <div className="block w-full md:w-72 xl:ml-3 mt-2 lg:mt-0">
                <span className="text-black-400 text-base font-semibold" 
                    style={{wordBreak:'break-word', hyphens:'auto'}}>{data.title}</span>
                <p className="text-black-400 text-base mt-1 formatted-text-container line-clamp-3"
                    dangerouslySetInnerHTML={{__html: data.description}}></p>
            </div>
        </div>
        <PseudoLinkButton justify="justify-end" color="text-black-400" className="font-semibold ml-auto mb-3">Leer más<IconArrowLeft
            color="stroke-black" className="ml-3 cursor-pointer transform -rotate-90 w-2" /></PseudoLinkButton>
    </Link>
}


export default ConsultationIndex