import moment from "moment"
const timeFormat = "HH:mm"

const hoursList = (formatType = "string") => {
    const items = [];
    new Array(24).fill().forEach((acc, index) => {
        new Array(12).fill().forEach((acc, minutesindex) => {
            const time = moment({ hour: index, minute: minutesindex * 5 })
            items.push(formatType === "moment" ? time : time.format(timeFormat))
        })
    })
    return items;
}

export default hoursList