import { misticoConfigOptions } from "../../../../utils/expertConfigOptions"

const ExpertConfigOptions = ({ config, type, hideTitle = false, textColor = "text-gray-500", textSize="text-lg" }) => {
    const expertConfig = misticoConfigOptions[`${type}Options`]
    let title = ""
    let configContent = <div />
    if (!config || config.length === 0) {
        title = expertConfig.label
        configContent = <div className="text-left text-base font-normal text-base text-gray-500 md:pl-4">N/A</div>
    }
    else if (type !== "themes") {
        title = expertConfig.label
        configContent = <ConfigItem items={config} type={expertConfig} textColor={textColor} textSize={textSize} />
    }
    else {

    }
    return <div className="flex flex-wrap my-2">
        {!hideTitle && <div className="text-left font-semibold text-xs text-black-400 pr-4">{title}</div>}
        {configContent}
    </div>
}

const ConfigItem = ({ items, type, textColor, textSize }) => {
    if (!items) {
        return <div className={`text-left text-base font-normal text-base ${textColor} md:pr-4`}>N/A</div>
    }
    return items.map((item, i) => {
        if (item in type.options)
            return <div key={`config-item-${item}-key-${i}`}
                className={`text-left ${textSize} font-semibold ${textColor} pr-4`}>{type.options[item]}</div>
    })
}



export default ExpertConfigOptions