import { useState } from "react"
import { useLocation } from "react-router"
import Login from "../../Components/Navbar/UserAccountSection/LoginModal/Login"
import { RequestEmailPanel, SuccesEmail } from "../../Components/Navbar/UserAccountSection/LoginModal/NewPass"
import RegisterPanel from "../../Components/Navbar/UserAccountSection/RegisterPanel"

const ModalPanel = {
    'login': 1,
    'newPass': 2,
    'register': 3
}

const ModalLoginForm = ({close, handleLoggedIn}) => {
    const [panel, setPanel] = useState(ModalPanel.register)
    const location = useLocation()

    return (
        <div>
            <h1 className="text-center">
                Debes iniciar sesión o registrarte para continuar
            </h1>
            {
                panel === ModalPanel.login ?
                    <div className="flex items-center mt-10">
                        <span>
                            ¿ No estás registrado ?,
                        </span>
                        <button className="btn mx-2 text-blue-500" onClick={() => {setPanel(ModalPanel.register)}}>
                            Regístrate
                        </button>
                    </div> : null
            }
            <div>
                {
                    panel === ModalPanel.login ? <Login forgotPass={() => { setPanel(ModalPanel.newPass) }} redirectUrl={location.pathname} onLoggedIn={handleLoggedIn} /> :
                        (
                            <>
                                {
                                    panel === ModalPanel.newPass ? <RequestEmailPanel
                                        cancel={() => { setPanel(ModalPanel.login) }} succes={() => { }} /> :
                                        <RegisterPanel hideSelf={() => { setPanel(ModalPanel.login) }} />
                                }
                            </>
                        )
                }
            </div>
        </div>

    )
}

export default ModalLoginForm