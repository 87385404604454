import axios from 'axios';
import React, { useContext } from 'react'
import { Router } from "react-router-dom";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import history from "./Pages/History";
import RouterConfig from "./Pages/RouterConfig";
import useGlobalState, { Context,StoreActions } from "./store/useGlobalState";
import { w3cwebsocket as W3CWebSocket } from "websocket";

axios.defaults.baseURL = process.env.REACT_APP_URL_BACK



function App() {

  const store = useGlobalState()


  return (
    <Context.Provider value={store}>
      <Router history={history}>
        <RouterConfig />
      </Router>
      <ToastContainer />
    </Context.Provider>
  )
}

export default App;
