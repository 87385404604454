import NavItem from "../NavItem"

const categories = {
    'psychic-topics': "Temas psíquicos",
    'love-and-relationship': "Amor y relaciones",
    'destiny': "Destino y significado de la vida",
    'health': "Salud y bienestar",
    'career-and-finance': "Carrera profesional y finanzas",
    'relationship-and-family': "Relaciones y familia",
    'loss-and-grief': "Perdida y duelo",
    'astrology': "Astrología",
}

const categoriesTexts = {
    'psychic-topics': "temas-psiquicos",
    'love-and-relationship': "amor-y-relaciones",
    'destiny': "destino-y-significado-de-vida",
    'health': "salud-y-bienestar",
    'career-and-finance': "carrera-profesional-y-finanzas",
    'relationship-and-family': "relaciones-y-familia",
    'loss-and-grief': "perdida-y-duelo",
    'astrology': "astrologia",
}

const MenuArticles = ({ mobile }) => {
    if (mobile === true) {
        return <NavItem mobile={true} url="/articulos" label="Artículos" />
    }

    return (
        <NavItem className="flex-grow" url="/articulos" label="Artículos">
            {
                Object.keys(categories).map((x) => {
                    return <NavItem url={`/articulos/${categoriesTexts[x]}`} label={categories[x]} key={`nav-article-${x}`} />
                })
            }
        </NavItem>
    )
    //#endregion
}

export default MenuArticles