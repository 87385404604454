import React, { useEffect, useState, useContext } from "react"
import { useParams, Link } from "react-router-dom"
//Components
import { IconThophy, IconReading, IconReview } from "../../../Components/Icons"
import { Calendar, Summary } from "../../../Components/Schedule"
import experts from "../../../integrations/expert"
import Icon from "../../../Assets/Icon"
import Recommended from "../../../Components/Recommended"
import { ToastWarn } from "../../../Components/Toast"
import moment from 'moment-timezone'
import ModalScheduleSuccess from "../../../Components/ExpertModal/ModalScheduleSuccess"
import ModalCall from "../../../Components/ExpertModal/ModalCall"
import ExpertConfigOptions from "./Components/ExpertConfiguration"
import Loader from "../../../Components/Loader"
import { Context } from '../../../store/useGlobalState'
import { ToastError } from "../../../Components/Toast"
import appointments from "../../../integrations/appointments"
import reviewsApi from "../../../integrations/reviews"
import { calculateExpertDisplayName } from "../../../utils/misc"
import Pagination from "../../../Components/Pagination";
import timezoneList from "../../../utils/timezone"
import ModalDialog from "../../../Components/ModalDialog"
import ModalLoginForm from "../ModalLoginForm"
import { UserProfileIcon } from '../../../Components/Icons'

const colors = {
    yellow: "#FFC700",
}

const perPage = 5

const timeFormat = "HH:mm"

const ExpertsPage = () => {
    const { globalState } = useContext(Context)
    const [isLoading, setIsLoading] = useState(true)
    const [openCall, setOpenCall] = useState(false)
    const [openConfirm, setOpenConfirm] = useState(null)
    const [expert, setExpert] = useState(null)
    const [stars, setStarts] = useState([])
    const [selectDate, setSelectedDate] = useState(new Date())
    const [time, setTime] = useState("")
    const [timezone, setTimezone] = useState("")
    const [sessionType, setSessionType] = useState("")
    const [duration, setDuration] = useState(-1)
    const [reviews, setReviews] = useState([])
    const [pagination, setPagination] = useState({})
    const [reviewsIsLoading, setReviewsIsLoading] = useState(false)
    const [calculatingInfo, setCalculatingInfo] = useState(false)
    const [confirmData, setConfirmData] = useState({})
    const [timeToShow, setTimeToShow] = useState(null)
    const [saving, setSaving] = useState(false)

    let { id } = useParams();
    var EXPERT = null;

    useEffect(()=>{
        setDuration(-1);
        setSessionType('');
        setTime('');
    }, [selectDate])

    useEffect(() => {
        loadData()
    }, [id])

    const loadData = async () => {
        setIsLoading(true)
        window.scrollTo(0, 0);
        try {
            const response = await experts.getById(id)

            setExpert(response)
            EXPERT = response
            await handlePagination()
            const starsIcon = new Array(typeof response?.rating === 'number' ? Math.ceil(response?.rating) : 0).fill('star')
            setStarts(starsIcon)
        } catch (error) {
            console.error("error: ", error)
            ToastError("Se produjo un error. Por favor inténtalo de nuevo más tarde")
        }
        setIsLoading(false)
    }

    const handlePagination = async (page = 1) => {
        if (!isLoading) {
            setReviewsIsLoading(true)
        }

        const resReviews = await reviewsApi.getByExpertId(EXPERT?._id || expert?._id, page, perPage)
        setReviews(resReviews)
        const pagesCount = resReviews.totalPages
        let pages = []
        for (let i = 0; i < pagesCount; i++) {
            pages.push(i + 1)
        }
        setPagination({
            prev: page - 1,
            next: (page + 1) > pagesCount ? null : (page + 1),
            pages: pages,
            current: page
        })
        setReviewsIsLoading(false)
    }

    const handleConfirm = async () => {
        setSaving(true)

        const { user } = globalState
        if (time && timezone && sessionType && duration>0) {
            try {
                const isAvailable = await experts?.checkAvailable2(expert?._id ? expert._id: EXPERT?._id)

                if (!isAvailable?.available) {
                    ToastError("El experto no está disponible en este momento")
                    setSaving(false)
                    return;
                }

                if (!isAvailable?.methods[sessionType]) {
                    ToastError("El experto desactivó el tipo de sesión seleccionado")
                    setSaving(false)
                    return;
                }
            } catch (error) {
                ToastError("El experto no está disponible en este momento")
                setSaving(false)
                return;
            }

            // get the time in local timezone format
            let expertTimeZone = timezoneList.find(x => x.value === expert?.schedule?.timezone)

            if (!(expertTimeZone && expertTimeZone.utc && expertTimeZone.utc[0])) {
                ToastError("Ocurrió un error obteniendo la zona horario del experto")
                return
            }

            expertTimeZone = expertTimeZone.utc[0]

            const localTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone

            if (!localTimezone) {
                ToastError("Ocurrió un error obteniendo la zona horaria local")
                return
            }

            const localMoment = moment.tz(selectDate, localTimezone)
            const localHourMoment = moment.tz(time, timeFormat, localTimezone)
            localMoment.set("hours", localHourMoment.hour())
                .set("minutes", localHourMoment.minute())
                .set("seconds", 0)
            const expertMoment = localMoment.tz(expertTimeZone)

            setTimeToShow(time)

            const formData = {
                "time": expertMoment.format(timeFormat),
                "timeZone": timezone,
                "date": expertMoment.format("YYYY/MM/DD"),
                "expert": expert._id,
                duration,
                sessionType
            }

            //console.log("expert time: ", time)
            //console.log("local time: ", expertMoment.tz(localTimezone).format(timeFormat))
            formData.timeZone = expertTimeZone
            //console.log("form data: ", formData)
            //return

            try {
                const response = await appointments.store(formData)
                if (!response?.error) {
                    setOpenConfirm(true)
                    // Se vuelve a setear el dia para que vuelva a renderizar los horarios disponibles
                    setSelectedDate(selectDate)
                    setConfirmData(response)
                } else {
                    //console.log("response: ", response)
                    if (response?.message && response.message==='Schedule blocked') {
                        ToastError('El experto se encuentra en sesión. Por el momento '+
                            'su agenda no está disponible, por favor inténtalo más tarde.')
                    } else {
                        ToastError("Por favor completa la información")
                    }
                }
            } catch (error) {
                ToastError("Por favor completa la información")
            }

            
        }
        else if (!user) {
            ToastError("Debe iniciar sesión para agendar")
        }
        else {
            ToastError("Por favor completa la información")
        }

        setSaving(false)
    }

    const row = (title, element) => (
        <div className="md:flex mt-4 m-left">
            <div className="text-left text-base font-semibold text-base text-black-400 ">{title}</div>
            <div className="text-left text-base font-normal text-base text-gray-500 md:pl-4 ">{element || "N/A"}</div>
        </div>
    )

    const comments = (user, image, type, rating, date, title, comment) => {
        const filledStars = new Array(typeof rating === 'number' ? Math.ceil(rating) : 0).fill('star')
        const unfilledStars = new Array(typeof (5 - rating) === 'number' ? Math.ceil(5 - rating) : 0).fill('star')
        return <div className="flex flex-col w-full border-b-2 border-gray-200 pb-4">
            <div className="flex flex-row self-start mt-4 w-full items-center">

                {
                    image ? <img className="w-10 h-10 object-cover rounded-full mr-4" src={image} title={image} alt={image} /> :
                        <div className="w-10 h-10 object-cover rounded-full mr-4"><UserProfileIcon /></div>
                }

                <div className="flex flex-col self-start mt-4 w-full">

                    <div className="flex flex-row self-start mt-4 w-full justify-between">
                        <span className="text-left text-base font-semibold text-base text-black-400 ">{user}</span>
                        <span className="flex flex-row gap-x-0.5 mx-auto">
                            {filledStars.map((star, key) => <Icon className="flex-col inline-block w-4"
                                width={20} height={20}
                                fill={colors.yellow} name="filledStars" key={`g-rating-filledStars-${key}`} />)}
                            {unfilledStars.map((star, key) => <Icon className="flex-col inline-block w-4"
                                width={20} height={20}
                                stroke={colors.yellow} name="unfilledStar" key={`g-rating-unfilledStars-${key}`} />)}
                        </span>
                    </div>
                    <div className="flex flex-row self-start mt-2 mb-4 w-full justify-between">
                        <span className="text-left  font-normal text-xs text-gray-500 ">{type}</span>
                        <span className="text-left font-normal text-xs text-gray-500 capitalize">{moment(date).format("MMMM DD, YYYY")}</span>

                    </div>
                </div>
            </div>
            {/*<p className="text-left text-base font-normal font-semibold">{title}</p>*/}
            <p className="text-left text-xs font-normal text-gray-400"
                style={{ wordWrap: 'break-word' }}>
                {comment}
            </p>

        </div>
    }

    const renderBody = () => {
        let yearsOfExperience = moment().diff(moment(expert?.start_date), 'year')
        let yearsOfExperienceLabel = "Años"
        if (yearsOfExperience === 0) {
            yearsOfExperience = moment().diff(moment(expert?.start_date), 'months')
            yearsOfExperienceLabel = "Meses"
        }
        return <div className="grid grid-cols-1 lg:grid-cols-4 xl:grid-cols-6 ">
            <div className={`w-full pt-10 ${(globalState?.user && expert?.available) ? 'col-span-4' : 'col-span-6'}`}>
                <div className="rounded-md border-gray-100 border-2 w-full px-5 py-10 md:px-10 md:py-10">
                    <h3 className="text-left text-base font-semibold text-lg text-black-400 mb-4">Acerca de mí</h3>
                    <p className="text-left text-xs font-semibold text-gray-400 mb-4">
                        {expert?.about_me}
                    </p>

                    <ExpertConfigOptions config={expert?.specialities} type="specialities" textColor='text-gray-400' textSize="text-xs" />
                    <ExpertConfigOptions config={expert?.instruments} type="instruments" textColor='text-gray-400' textSize="text-xs" />
                    <ExpertConfigOptions config={expert?.styles} type="lectureStyle" textColor='text-gray-400' textSize="text-xs" />

                    <div className="md:flex md:flex-row justify-between md:px-10 mt-10">
                        <div className="flex flex-col items-center">
                            <IconThophy className="h-10 w-10 cursor-pointer" />
                            <div className="text-left text-base font-semibold text-xl text-black-400">{yearsOfExperience}</div>
                            <h3 className="text-left text-xs font-normal text-normal text-gray-500 mb-4">{yearsOfExperienceLabel} de experiencia</h3>
                        </div>
                        <div className="flex flex-col items-center">
                            <IconReading className="h-10 w-10 cursor-pointer" />
                            <div className="text-left text-base font-semibold text-xl text-black-400">{expert?.totalSessions}</div>
                            <h3 className="text-left text-xs font-normal text-normal text-gray-500 mb-4">Lecturas realizadas</h3>
                        </div>
                        <div className="flex flex-col items-center">
                            <IconReview className="h-10 w-10 cursor-pointer" />
                            <div className="text-left text-base font-semibold text-xl text-black-400">{reviews?.totalCount}</div>
                            <h3 className="text-left text-xs font-normal text-normal text-gray-500 mb-4">Reviews de clientes</h3>
                        </div>

                    </div>
                    <h3 className="text-left text-base font-semibold text-md text-black-400 mb-4 mt-4">Recomendaciones</h3>
                    <p className="text-left text-xs font-normal text-gray-400">
                        {expert?.recommendations}
                    </p>
                    <h3 className="text-left text-base font-semibold text-md text-black-400  mt-4">Reseñas y ratings ({reviews?.totalCount} reseñas)</h3>
                    {reviewsIsLoading ? <Loader /> : <>
                        {reviews?.data.map(x => {
                            return comments(x.user.username, x.user.image, "Videollamada", x.rating, moment(x.createdAt).toDate(), x.title, x.commentary)
                        })}
                        <Pagination handlePagination={handlePagination} pagination={pagination} />
                    </>}
                </div>
            </div>
            <div className=" col-span-2 w-full pt-10 md:px-10">
                {
                    (globalState?.user && expert?.available) &&
                    <>
                        <div className="text-left text-lg font-semibold pb-6">Disponibilidad de horario</div>
                        <Calendar
                            expert={expert}
                            selectDate={selectDate}
                            setSelectedDate={setSelectedDate}
                            idExpert={expert._id}
                        />
                        <div className="mt-10" />
                        <Summary
                            expert={expert}
                            selectDate={selectDate}
                            setTime={setTime}
                            setTimezone={setTimezone}
                            timezone={timezone}
                            time={time}
                            saving={saving}
                            duration={duration}
                            sessionType={sessionType}
                            setSessionType={setSessionType}
                            setDuration={setDuration}
                            onConfirm={handleConfirm}
                            calculatingInfo={calculatingInfo}
                            setCalculatingInfo={setCalculatingInfo}
                        />
                    </>
                }
            </div>
        </div>
    }

    const calculateRating = (rating) => {
        const ratingString = rating?.toString()

        if (!ratingString) return rating
        if (!(ratingString.match && ratingString.match(/^([0-9]+)(\.|,)([0-9]{2,2})([0-9]*)$/))) return rating
        return ratingString.replace(/^([0-9]+)(\.|,)([0-9]{1,1})([0-9]*)$/, '$1$2$3')
    }

    const filledStars = stars
    const unfilledStars = new Array(typeof (5 - stars.length) === 'number' ? Math.ceil(5 - stars.length) : 0).fill('star')

    if (isLoading) {
        return <Loader />
    }

    return (
        <>
            <div className="pb-20 bg-white lg:pt-20">
                <div className="max-w-lg lg:max-w-md px-6 pt-3 mx-auto lg:max-w-7xl  sm:px-0 xs:max-w-3xl md:max-w-5x1 xs:px-0 flex">
                    <Link to='/expertos' className="font-semibold" style={{ color: '#C0C9FF' }}>Expertos</Link>
                    <p className="mx-2 font-semibold" style={{ color: '#C0C9FF' }}>&gt;</p>
                    <div className="font-semibold" style={{ color: '#505DD4' }}>{calculateExpertDisplayName(expert)}</div>
                </div>
                <div className="max-w-lg lg:max-w-md px-6 pt-3 mx-auto text-center lg:max-w-7xl  sm:px-0 xs:max-w-3xl md:max-w-5x1 xs:px-0">
                    <div className="w-full min-h-80 rounded-md grid grid-cols-1 md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-4 md:p-12 py-5" style={{ backgroundColor: "#F4F6FD" }}>
                        <div className="lg:flex items-start md:flex-nowrap col-span-1 md:col-span-9 lg:col-span-3 n pb-2 md:pb-0">
                            <div className="flex place-content-center lg:block">
                                {
                                    expert?.image ? <img className="w-40 h-40 object-cover rounded-2xl" src={expert?.image} title={expert?.name} alt={expert?.name} /> :
                                        <div className="w-40 h-40 object-cover rounded-2xl"><UserProfileIcon /></div>
                                }
                            </div>
                            <div className="justify-between flex-grow flex flex-col  sm:w-auto px-10 self-start ">
                                <div className="md:flex flex-row justify-between h-full mb-2 ">
                                    <div className="text-2xl text-black-400" style={{ fontWeight: '700' }}>
                                        {calculateExpertDisplayName(expert)}
                                    </div>
                                    <div className="flex flex-row gap-x-0.5 justify-center items-center">
                                        <p className="text-md font-semibold mr-3">{`${calculateRating(expert?.rating)} de 5`}</p>
                                        {filledStars.map((star, key) => <Icon className="flex-col inline-block w-4 ml-1"
                                            width={20} height={20}
                                            fill={colors.yellow} name="filledStars" key={`g-rating-filledStars-${key}`} />)}
                                        {unfilledStars.map((star, key) => <Icon className="flex-col inline-block w-4 ml-1"
                                            width={20} height={20}
                                            stroke={colors.yellow} name="unfilledStar" key={`g-rating-unfilledStars-${key}`} />)}
                                    </div>
                                </div>
                                <p className="text-left text-xs font-semibold text-black-400 my-5 lg:my-0 lg:mt-10">
                                    {expert?.slogan}
                                </p>
                            </div>
                        </div>
                        <div className=" rounded-md flex flex-col p-8 gap-y-3  col-span-1 sm:col-span-3 xl:col-span-1 md:w-80 xl:w-50 m-auto lg:mt-10
                        xl:mt-0" style={{ backgroundColor: "rgba(11, 11, 40, 0.6)" }}>
                            <h3 className="text-center text-base font-semibold text-xl text-white">${Number(expert?.price_videocall).toFixed(2)} MXN/min</h3>
                            <button
                                onClick={async () => {
                                    const available = await experts.checkAvailable(expert?._id)
                                    if (available) {
                                        if (!globalState?.user) {
                                            setSessionType('video_call')
                                            setOpenCall(true)
                                            return
                                        }

                                        setSessionType('video_call');
                                        setOpenCall(true)
                                    }
                                    else {
                                        ToastWarn("El experto no está disponible por el momento")
                                    }
                                }}
                                className=" text-white bg-primary-light
                                focus:outline-none flex items-center justify-center font-bold  rounded-md 
                                font-semibold mr-3 mx-2 px-7 py-3.5 text-xs tracking-wider whitespace-nowrap "
                            >
                                Videollamada
                            </button>
                            <h3 className="text-center text-base font-semibold text-xl text-white">${Number(expert?.price_phonecall).toFixed(2)} MXN/min</h3>
                            <button
                                onClick={async () => {
                                    const available = await experts.checkAvailable(expert?._id)
                                    if (available) {
                                        if (!globalState?.user) {
                                            setSessionType('internet_call')
                                            setOpenCall(true)
                                            return
                                        }

                                        setSessionType('internet_call');
                                        setOpenCall(true)
                                    }
                                    else {
                                        ToastWarn("El experto no está disponible por el momento")
                                    }
                                }}
                                className=" text-white bg-primary-light
                                focus:outline-none flex items-center justify-center font-bold  rounded-md 
                                font-semibold mr-3 mx-2 px-7 py-3.5 text-xs tracking-wider whitespace-nowrap "
                            >
                                Llamada
                            </button>
                        </div>
                    </div>
                    {renderBody()}
                </div>
                <Recommended />
                <ModalScheduleSuccess
                    id={id}
                    expert={expert}
                    date={selectDate}
                    time={timeToShow}
                    visible={openConfirm}
                    confirmation={confirmData}
                    hideSelf={() => { setOpenConfirm(false); loadData(); }}
                />
                <>
                    {
                        globalState.user ? <ModalCall
                            id={id}
                            expert={expert}
                            visible={openCall}
                            serviceType={sessionType}
                            hideSelf={() => { setOpenCall(false) }}
                        /> :
                            <ModalDialog visible={openCall} close={() => { setOpenCall(false) }}>
                                <ModalLoginForm handleLoggedIn={() => { }} />
                            </ModalDialog>
                    }
                </>

            </div>

        </>
    )
}

export default ExpertsPage