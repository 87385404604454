export const DataGrid = ({ headings = [], data, row }) => {
   let CustomRow = row

   if (!Array.isArray(data)) {
      console.error(':DataGrid: Debes enviar data como un array de objetos')
      return <div>Sin información</div>
   }

   return (
      <div className="flex flex-col">
         <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8 w-full">
            <div className="inline-block min-w-full align-middle sm:px-6 lg:px-8">
               <div className="overflow-hidden border-b border-gray-200 sm:rounded-lg">
                  <table className="min-w-full divide-y divide-gray-200">
                     <thead>
                        <tr>
                           {headings.map((heading, i) => <ColHead key={i}>{heading}</ColHead>)}
                        </tr>
                     </thead>
                     <tbody className="bg-white divide-y divide-gray-200">
                        {data.map((payment, i) => row
                           ? <CustomRow key={i} data={payment} />
                           : <DataRow key={i} cols={payment} />
                        )}
                     </tbody>
                  </table>
               </div>
            </div>
         </div>
      </div>
   )
}

const ColHead = ({ children }) => (<th scope="col" className="px-6 py-3 text-xs font-semibold tracking-wider text-center text-gray-500 uppercase">{children}</th>)

const DataRow = ({ cols, children }) => {
   let columns = Object.keys(cols)
   return <tr>
      {columns.map((colname, index) => <DataCell key={index}>{cols[colname]}</DataCell>)}
   </tr>
}

const DataCell = ({ children }) => (<td className="px-6 py-4 text-xs whitespace-nowrap">{children}</td>)

export default DataGrid