import React, { useEffect, useState } from "react"
import { Link } from "react-router-dom";
import Loader from "../Loader";
import articles from "../../integrations/article"

const RecomendedLectures = ({ template = "vertical", ...props }) => {
    const [lastArticles, setlastArticles] = useState([])
    const [loading, setLoading] = useState(true)

    useEffect(async () => {
        try {
            const res = await articles.getArticles(4, 1)

            if (res.data) {
                res.data.forEach(element => {
                    if (element && element.name) {
                        try {
                            const str = element.name.trim();
                            element.name = str.charAt(0).toLocaleUpperCase() + str.slice(1)
                        } catch (error) { }
                    }
                });
            }

            setlastArticles(res?.data)
            setLoading(false)
        } catch (error) {
            console.log(error)
            setlastArticles([])
            setLoading(false)
        }
    }, [])

    if (loading) {
        return <Loader />
    }
    return <>
        {
            template === "vertical" ? <Vertical src={lastArticles} /> : <Horizontal src={lastArticles} />
        }
    </>
}

const Vertical = ({ src }) => {
    return <>
        <h1 className=" font-bold text-xl text-black-400 mb-3 text-center mt-6 md:mt-0 mb-10">Lecturas recomendadas</h1>
        {
            src.map((x, i) => {
                return <Link to={`/articulo/${x._id}`} key={`recomended-lectures-vertical-${i}`} className="grid grid-cols-1 md:grid-cols-3 mb-5 items-center ml-0 md:ml-3">
                    <div className="col-span-1">
                        <img src={x.photo} className="rounded-md w-full md:w-30 md:h-20 mx-auto mb-2 md:mb-0" />
                    </div>
                    <div className="col-span-1 md:col-span-2 ml-3">
                        <span className="text-gray-500 flex flex-wrap items-center">
                            <CategoryTag cat={x.category} /><small className="mx-2"> &#183;   Por {x.postedBy.name}</small>
                        </span>
                        <p className="text-black-400 text-sm font-semibold mt-1"
                            style={{ wordBreak: 'break-word', hyphens: 'auto' }}
                            dangerouslySetInnerHTML={{ __html: x.name }}></p>
                    </div>
                </Link>
            })
        }
    </>
}

const Horizontal = ({ src }) => {
    return <div className="lg:overflow-hidden lg:-mx-fullScreen py-10 px-6" style={{ backgroundColor: "rgb(244, 246, 253)" }}>
        <div className="lg:px-fullScreen mb-9">
            <div className="w-full py-5 max-w-md px-6 mx-auto sm:max-w-3xl lg:max-w-7xl sm:px-6 md:px-0">
                <div className="ml-auto max-w-5xl">
                    <h3 className="text-base font-bold text-2xl text-black-400 mb-3 mt-6 md:mt-0 pb-6">Nuestras lecturas recomendadas</h3>
                    <div className="grid grid-cols-2 md:grid-cols-4 gap-4">
                        {
                            src.map((x, i) => {
                                return <Link to={`/articulo/${x._id}`} key={`recomended-lectures-vertical-${i}`} className="ml-0 md:ml-3">
                                    <div className="col-span-1 md:col-span-2">
                                        <div className="flex flex-col justify-between" style={{ height: '240px' }}>
                                            <div>
                                                <p className="text-black-400 text-sm font-semibold mt-1"
                                                    style={{ wordBreak: 'break-word', hyphens: 'auto' }}
                                                    dangerouslySetInnerHTML={{ __html: x.name }}>
                                                </p>

                                            </div>
                                            <div>
                                                <span className="text-gray-500 flex flex-wrap items-center my-2">
                                                    <small className="truncate">Por {x.postedBy.name}</small>
                                                </span>
                                                <img src={x.photo} className="rounded w-full h-40 mb-2 md:mb-0" />
                                            </div>

                                        </div>
                                    </div>
                                </Link>
                            })
                        }
                    </div>
                </div>
            </div>
        </div>

    </div>
}

// #region Subcomponents
const CategoryTag = ({ cat }) => {
    let className = "py-1 px-2 rounded font-semibold"
    let label = ""
    switch (cat) {
        case 'psychic-topics': {
            className += " bg-orange-light text-orange"
            label = "Temas psíquicos"
            break;
        }
        case 'love-and-relationship': {
            className += " bg-catRed-light text-catRed"
            label = "Amor y relaciones"
            break;
        }
        case 'destiny': {
            className += " bg-green-light text-green"
            label = "Destino y significado de la vida"
            break;
        }
        case 'health': {
            className += " bg-catBlue-light text-catBlue"
            label = "Salud y bienestar"
            break;
        }
        case 'career-and-finance': {
            className += " bg-catYellow-light text-catYellow"
            label = "Carrera profesional y finanzas"
            break;
        }
        case 'relationship-and-family': {
            className += " bg-pink-light text-pink"
            label = "Relaciones y familia"
            break;
        }
        case 'loss-and-grief': {
            className += " bg-catGrey-light text-catGrey"
            label = "Perdida y duelo"
            break;
        }
        case 'astrology': {
            className += " bg-purple-light text-purple"
            label = "Astrología"
            break;
        }
        default: {
            break;
        }
    }

    return <label className={className}><small>{label}</small></label>
}

// #endregion

export default RecomendedLectures