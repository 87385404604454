import React from "react";
import { SecondaryButton } from "../../Components/Button"
import { useHistory } from 'react-router-dom'

const SuccesImg = (props) => (
    <svg width="342" height="284" viewBox="0 0 342 284" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <g clipPath="url(#clip0)">
            <path d="M244.194 64.2955H241.765C241.286 64.2955 240.898 64.6843 240.898 65.1639V97.7838C240.898 98.2633 241.286 98.6521 241.765 98.6521H244.194C244.673 98.6521 245.061 98.2633 245.061 97.7838V65.1639C245.061 64.6843 244.673 64.2955 244.194 64.2955Z" fill="#3F3D56"/>
            <path d="M228.378 0H118.627C110.472 0 103.861 6.61939 103.861 14.7848V268.646C103.861 276.812 110.472 283.431 118.627 283.431H228.378C236.533 283.431 243.144 276.812 243.144 268.646V14.7848C243.144 6.61939 236.533 0 228.378 0Z" fill="#3F3D56"/>
            <path d="M223.758 7.38257H207.781V9.30698C207.781 10.5049 207.545 11.691 207.087 12.7976C206.629 13.9043 205.958 14.9097 205.112 15.7565C204.265 16.6033 203.261 17.2748 202.155 17.7328C201.05 18.1908 199.865 18.4261 198.669 18.4255H147.857C146.661 18.4261 145.476 18.1908 144.371 17.7328C143.265 17.2748 142.261 16.6033 141.415 15.7565C140.568 14.9097 139.897 13.9043 139.439 12.7976C138.981 11.691 138.745 10.5049 138.745 9.30698V7.38257H123.786C120.852 7.38257 118.039 8.54949 115.964 10.6266C113.89 12.7038 112.724 15.521 112.724 18.4585V264.992C112.724 267.93 113.89 270.747 115.964 272.824C118.039 274.901 120.852 276.068 123.786 276.068H223.758C226.692 276.068 229.505 274.901 231.58 272.824C233.654 270.747 234.82 267.93 234.82 264.992V18.4774C234.822 17.0213 234.538 15.579 233.983 14.233C233.428 12.8871 232.614 11.6638 231.586 10.6333C230.559 9.60282 229.339 8.78528 227.995 8.22748C226.652 7.66968 225.212 7.38257 223.758 7.38257Z" fill="white"/>
            <path d="M178.052 8.46442H161.533C160.724 8.46442 160.068 9.12143 160.068 9.9319V10.9493C160.068 11.7598 160.724 12.4168 161.533 12.4168H178.052C178.861 12.4168 179.517 11.7598 179.517 10.9493V9.9319C179.517 9.12143 178.861 8.46442 178.052 8.46442Z" fill="#E6E8EC"/>
            <path d="M184.701 12.6852C185.939 12.6852 186.942 11.6803 186.942 10.4407C186.942 9.20117 185.939 8.19629 184.701 8.19629C183.463 8.19629 182.459 9.20117 182.459 10.4407C182.459 11.6803 183.463 12.6852 184.701 12.6852Z" fill="#E6E8EC"/>
            <path d="M174.353 171.577C188.905 171.577 200.701 159.766 200.701 145.195C200.701 130.625 188.905 118.814 174.353 118.814C159.801 118.814 148.005 130.625 148.005 145.195C148.005 159.766 159.801 171.577 174.353 171.577Z" fill="white"/>
            <path d="M209.008 141.511C209.008 148.485 206.943 155.302 203.073 161.101C199.203 166.899 193.703 171.418 187.268 174.087C180.834 176.755 173.753 177.453 166.922 176.093C160.091 174.732 153.816 171.374 148.891 166.442C143.966 161.511 140.613 155.228 139.254 148.388C137.896 141.548 138.593 134.459 141.259 128.016C143.924 121.573 148.438 116.066 154.229 112.192C160.021 108.318 166.829 106.25 173.794 106.25C178.419 106.25 182.998 107.162 187.27 108.935C191.543 110.707 195.425 113.304 198.695 116.578C201.965 119.853 204.559 123.74 206.328 128.018C208.098 132.296 209.009 136.881 209.008 141.511ZM169.723 160.175L195.846 134.02C196.057 133.809 196.225 133.558 196.34 133.282C196.454 133.006 196.513 132.709 196.513 132.411C196.513 132.112 196.454 131.816 196.34 131.539C196.225 131.263 196.057 131.013 195.846 130.801L192.632 127.607C192.421 127.395 192.171 127.227 191.896 127.113C191.62 126.998 191.325 126.939 191.027 126.939C190.729 126.939 190.434 126.998 190.158 127.113C189.883 127.227 189.633 127.395 189.422 127.607L168.117 148.94L158.168 138.956C157.741 138.53 157.164 138.292 156.561 138.292C155.959 138.292 155.381 138.53 154.954 138.956L151.74 142.193C151.316 142.62 151.077 143.199 151.077 143.802C151.077 144.405 151.316 144.983 151.74 145.411L166.51 160.194C166.721 160.406 166.971 160.574 167.247 160.689C167.523 160.803 167.819 160.862 168.117 160.862C168.416 160.862 168.711 160.803 168.987 160.689C169.263 160.574 169.513 160.406 169.724 160.194L169.723 160.175Z" fill="#6C63FF"/>
            <path d="M108.222 55.877H107.857C107.379 55.877 106.99 56.2658 106.99 56.7454V65.1761C106.99 65.6556 107.379 66.0444 107.857 66.0444H108.222C108.701 66.0444 109.089 65.6556 109.089 65.1761V56.7454C109.089 56.2658 108.701 55.877 108.222 55.877Z" fill="#3F3D56"/>
            <path d="M108.412 74.4944H107.781C107.302 74.4944 106.914 74.8832 106.914 75.3627V91.3215C106.914 91.8011 107.302 92.1899 107.781 92.1899H108.412C108.891 92.1899 109.28 91.8011 109.28 91.3215V75.3627C109.28 74.8832 108.891 74.4944 108.412 74.4944Z" fill="#3F3D56"/>
            <path d="M108.336 98.3818H107.819C107.34 98.3818 106.952 98.7706 106.952 99.2501V115.361C106.952 115.841 107.34 116.229 107.819 116.229H108.336C108.815 116.229 109.203 115.841 109.203 115.361V99.2501C109.203 98.7706 108.815 98.3818 108.336 98.3818Z" fill="#3F3D56"/>
            <path d="M79.053 145.577C79.1132 145.635 79.1905 145.672 79.2734 145.683C79.3564 145.693 79.4406 145.677 79.5135 145.636C79.358 145.638 79.2029 145.618 79.053 145.577Z" fill="url(#paint0_linear)"/>
            <path d="M96.4598 152.394L96.0054 154.873L95.6547 156.769L95.0169 160.237L88.8329 164.572C88.8329 164.572 88.2755 164.094 87.3559 163.341C84.8216 161.252 79.495 156.997 75.5836 154.713C74.5775 154.071 73.489 153.569 72.3479 153.22L96.4598 152.394Z" fill="#FEB9B9"/>
            <path d="M91.9231 154.238C91.9231 154.238 99.1334 155.478 99.5557 152.394C99.9779 149.311 95.8443 147.645 92.3412 147.437C88.8381 147.23 67.8127 148.677 67.8127 148.677C67.8127 148.677 64.1039 153.629 77.4983 154.458L91.9231 154.238Z" fill="#3F3D56"/>
            <path d="M107.691 52.4977H69.9714V97.2821H107.691V52.4977Z" fill="#2F2E41"/>
            <path opacity="0.1" d="M107.691 52.7009H69.9714V97.4854H107.691V52.7009Z" fill="black"/>
            <path d="M25.7638 129.163C25.1427 129.319 24.3489 129.921 23.4662 130.791C19.6646 134.559 14.2199 143.403 14.2199 143.403C7.25873 135.1 14.9618 128.541 18.2452 126.286C19.0435 125.741 19.5798 125.44 19.5798 125.44L25.7638 129.163Z" fill="#FEB9B9"/>
            <path d="M96.0458 66.6321C96.0458 66.6321 97.9016 79.6332 104.291 82.729C110.68 85.8247 96.0458 91.6105 96.0458 91.6105L76.054 84.1752C76.054 84.1752 87.3911 80.4591 85.1226 69.5205L96.0458 66.6321Z" fill="#FEB9B9"/>
            <path d="M74.6095 144.849L74.1574 147.319L73.79 149.222L73.1523 152.69L66.9682 157.021C66.9682 157.021 66.4108 156.548 65.4913 155.795C62.9569 153.706 57.6307 149.451 53.7133 147.167C52.7077 146.524 51.619 146.022 50.4775 145.674L74.6095 144.849Z" fill="#FEB9B9"/>
            <path d="M69.2602 147.895C69.2602 147.895 75.7313 147.941 76.1206 144.849C76.5099 141.757 72.594 140.1 69.2602 139.892C65.9264 139.685 45.6705 141.228 45.6705 141.228C45.6705 141.228 42.7968 147.067 55.5379 147.895L69.2602 147.895Z" fill="#2F2E41"/>
            <path d="M114.631 196.468C114.348 196.329 114.065 196.185 113.786 196.045L113.026 195.085L103.053 182.423V182.643C102.986 184.757 102.268 201.514 96.6665 203.062C90.6897 204.711 83.4794 205.126 80.1925 193.982C76.9056 182.838 70.9 158.485 64.7327 157.039L73.907 149.506C73.907 149.506 79.9812 156.62 82.4561 163.429C82.3633 163.006 78.1687 143.382 81.6112 139.91H111.709C111.709 139.91 116.356 153.161 119.54 159.725C122.02 164.821 125.741 176.87 128.137 185.024C130.509 193.076 122.175 200.186 114.631 196.468Z" fill="#3F3D56"/>
            <path opacity="0.1" d="M113.774 143.61C109.127 146.363 88.7299 145.242 82.0941 144.794L80.2861 144.663C80.0918 144.663 80.3156 144.553 80.3156 144.553L80.5016 142.679L85.448 130.093L111.932 114.715C111.396 116.335 111.218 118.813 111.261 121.651C111.383 130.77 113.774 143.61 113.774 143.61Z" fill="black"/>
            <path d="M113.774 142.988C109.127 145.742 88.7298 144.621 82.094 144.173L80.286 144.046L79.9861 144.02L80.3156 143.936L81.9459 143.513L85.4475 129.48L111.932 114.106C111.395 115.726 111.218 118.205 111.26 121.042C111.383 130.152 113.774 142.988 113.774 142.988Z" fill="#EFEFEF"/>
            <path opacity="0.1" d="M111.244 121.034C105.858 122.277 94.0058 125.925 89.4521 134.737C86.4152 140.603 83.8596 143.119 82.077 144.172L80.269 144.045C80.2809 144.009 80.2907 143.972 80.2986 143.935L81.9289 143.512L85.4305 129.48L111.915 114.106C111.385 115.717 111.201 118.196 111.244 121.034Z" fill="black"/>
            <path opacity="0.1" d="M19.5797 125.449L25.7637 129.163C25.1426 129.32 24.3488 129.921 23.4661 130.791C22.9506 129.285 19.7168 127.184 18.2451 126.287C19.0431 125.75 19.5797 125.449 19.5797 125.449Z" fill="black"/>
            <path opacity="0.1" d="M79.1669 144.287C79.0991 144.322 79.022 144.335 78.9463 144.325C78.8706 144.314 78.8001 144.28 78.7446 144.228C78.8817 144.268 79.024 144.288 79.1669 144.287Z" fill="black"/>
            <path d="M93.1612 81.9042C93.1612 81.9042 87.5942 82.5215 83.6786 78.394C83.6786 78.394 67.2046 83.1434 61.6254 93.8731C56.0462 104.603 18.1184 125.656 18.1184 125.656C18.1184 125.656 24.7164 129.37 24.0953 131.226L49.0377 116.779C49.0377 116.779 57.9079 110.384 60.3707 108.938C62.8335 107.492 76.4513 98.6187 79.3363 122.764C82.2213 146.909 79.3363 144.021 79.3363 144.021C79.3363 144.021 83.459 145.467 89.4359 133.908C95.4127 122.349 113.96 119.668 113.96 119.668L114.991 107.496C114.991 107.496 118.67 99.4261 115.836 96.0766C114.977 95.0849 114.406 93.8767 114.184 92.5831C113.715 89.4915 114.184 84.3402 120.748 80.0602C130.847 73.4539 134.763 68.0913 134.763 68.0913C134.763 68.0913 134.97 64.1666 128.786 62.3097C122.602 60.4528 97.457 50.3408 97.457 50.3408L96.6121 53.851L116.452 67.8714C116.452 67.8714 106.56 75.9069 98.7117 75.7123C98.7113 75.7123 100.169 81.6968 93.1612 81.9042Z" fill="#6C63FF"/>
            <path opacity="0.1" d="M96.9795 72.2909C96.492 70.6366 96.1112 68.9526 95.8392 67.2495L84.9159 70.1389C85.3975 72.2268 85.3115 74.4058 84.6667 76.4491C86.9 77.0458 89.2595 76.9695 91.4496 76.2299C93.6398 75.4902 95.5634 74.12 96.9795 72.2909Z" fill="black"/>
            <path d="M87.9066 76.2288C94.3942 76.2288 99.6535 70.9629 99.6535 64.467C99.6535 57.971 94.3942 52.7051 87.9066 52.7051C81.4189 52.7051 76.1597 57.971 76.1597 64.467C76.1597 70.9629 81.4189 76.2288 87.9066 76.2288Z" fill="#FEB9B9"/>
            <path opacity="0.1" d="M100.684 159.191C100.684 159.191 107.484 172.606 105.423 176.73L100.684 159.191Z" fill="black"/>
            <path d="M94.1535 37.3018C97.4142 37.6742 100.511 39.0738 103.721 39.8394C104.523 40.0891 105.377 40.1212 106.196 39.9323C107.442 39.5474 108.456 38.2407 109.748 38.2786C110.544 38.304 111.248 38.8455 111.903 39.3654C112.953 40.17 113.941 41.0522 114.859 42.0046C117.072 44.411 118.445 47.6508 120.464 50.2688C122.483 52.8867 125.362 55.9567 128.23 54.7562C126.279 62.3689 120.194 67.1312 113.28 67.6052C110.521 67.7948 107.695 67.2203 105.026 68.028C104.361 68.3009 103.659 68.472 102.943 68.5353C101.405 68.5353 100.168 67.1228 99.2089 65.7483C96.9616 62.5171 95.1836 58.8503 92.6534 55.9025C91.8085 54.9256 90.8329 53.9993 89.6502 53.8218C88.4674 53.6444 87.382 54.2109 86.3304 54.7565C84.5943 55.6575 82.8204 56.5877 81.473 58.1401C80.1255 59.6925 79.2764 61.9844 79.8339 64.0612C80.1339 65.1863 80.8186 66.2014 80.8307 67.3769C80.8561 69.2547 79.2003 70.6251 78.875 72.452C78.8015 73.2195 78.7747 73.9907 78.7947 74.7613C78.6512 77.264 77.0148 79.3117 75.3106 80.8895C74.6433 81.4985 73.9093 82.1583 73.7139 83.1014C73.5829 83.7483 73.7139 84.4285 73.6294 85.0849C73.3803 86.709 71.7624 87.5338 70.3305 87.7454C68.6575 87.9934 66.9486 87.7905 65.3799 87.1576C67.3822 86.2096 68.6157 83.512 68.151 81.0969C67.5132 77.7471 64.151 75.3408 64.4396 71.9322C64.5976 70.8863 64.9109 69.87 65.3689 68.9168C66.1016 67.0748 66.6341 65.1592 66.9573 63.2031C67.2106 61.6931 67.3333 60.0903 66.8599 58.6482C66.4377 57.337 65.5549 56.2927 64.8069 55.1843C63.7934 53.6764 62.9931 52.0354 62.429 50.3078C62.2246 49.7954 62.1466 49.2411 62.2018 48.6921C62.3712 48.0436 62.7319 47.4614 63.2368 47.0213C65.0743 45.0874 67.0383 43.0796 69.5011 42.5256C70.6761 42.3302 71.8711 42.2848 73.0576 42.3902C75.1211 42.455 77.1865 42.3673 79.2371 42.1278C80.9265 41.9291 83.7816 41.9545 85.2139 40.859C86.5485 39.8352 87.3006 38.5497 88.9435 37.8987C90.6073 37.2812 92.3957 37.0765 94.1557 37.3022L94.1535 37.3018Z" fill="#2F2E41"/>
            <path d="M312.424 282.727C321.835 282.727 329.464 281.435 329.464 279.842C329.464 278.248 321.835 276.956 312.424 276.956C303.013 276.956 295.384 278.248 295.384 279.842C295.384 281.435 303.013 282.727 312.424 282.727Z" fill="#6C63FF"/>
            <path d="M319.472 275.123C320.248 274.488 320.814 273.631 321.095 272.667C321.307 271.695 320.892 270.543 319.959 270.167C318.916 269.768 317.8 270.494 316.958 271.224C316.116 271.953 315.145 272.789 314.038 272.633C314.608 272.117 315.034 271.463 315.276 270.733C315.518 270.004 315.566 269.224 315.417 268.47C315.368 268.158 315.234 267.864 315.032 267.621C314.455 267.002 313.403 267.265 312.714 267.757C310.51 269.306 309.896 272.308 309.883 275C309.658 274.028 309.849 273.014 309.84 272.03C309.832 271.045 309.565 269.906 308.722 269.386C308.201 269.107 307.617 268.969 307.026 268.987C306.035 268.949 304.933 269.051 304.255 269.776C303.407 270.676 303.636 272.187 304.365 273.171C305.094 274.155 306.209 274.787 307.234 275.471C308.054 275.961 308.753 276.629 309.279 277.427C309.343 277.539 309.393 277.657 309.431 277.779H315.623C317.019 277.072 318.313 276.179 319.471 275.125L319.472 275.123Z" fill="#BB3996"/>
            <path d="M80.7463 284C86.957 284 91.9918 283.148 91.9918 282.096C91.9918 281.044 86.957 280.192 80.7463 280.192C74.5355 280.192 69.5007 281.044 69.5007 282.096C69.5007 283.148 74.5355 284 80.7463 284Z" fill="#EFEFEF"/>
            <path d="M85.3972 278.982C85.9096 278.562 86.2831 277.997 86.4682 277.361C86.5497 277.041 86.5199 276.704 86.3836 276.404C86.2472 276.104 86.0127 275.859 85.7187 275.711C85.0306 275.448 84.2951 275.927 83.7388 276.409C83.1824 276.89 82.5417 277.442 81.8119 277.338C82.1875 276.998 82.4687 276.566 82.628 276.085C82.7873 275.603 82.8193 275.089 82.7208 274.591C82.688 274.385 82.6001 274.192 82.4663 274.031C82.0876 273.623 81.3923 273.796 80.9367 274.121C79.4824 275.145 79.0768 277.123 79.0685 278.901C78.9204 278.26 79.0461 277.59 79.0405 276.941C79.0348 276.291 78.8587 275.541 78.302 275.197C77.9586 275.012 77.5728 274.921 77.1832 274.933C76.5288 274.908 75.8017 274.976 75.3541 275.454C74.7947 276.048 74.9458 277.045 75.4268 277.695C75.9077 278.344 76.6432 278.762 77.3203 279.212C77.8616 279.534 78.3233 279.975 78.6712 280.501C78.7131 280.574 78.7469 280.652 78.772 280.733H82.8579C83.7792 280.266 84.6333 279.676 85.3972 278.98V278.982Z" fill="#EFEFEF"/>
            <path d="M17.1481 284C23.3588 284 28.3936 283.148 28.3936 282.096C28.3936 281.044 23.3588 280.192 17.1481 280.192C10.9374 280.192 5.90259 281.044 5.90259 282.096C5.90259 283.148 10.9374 284 17.1481 284Z" fill="#EFEFEF"/>
            <path d="M21.799 278.982C22.3115 278.562 22.6849 277.997 22.87 277.361C22.9516 277.041 22.9217 276.704 22.7854 276.404C22.6491 276.104 22.4145 275.859 22.1206 275.711C21.4324 275.448 20.697 275.927 20.1406 276.409C19.5843 276.89 18.9435 277.442 18.2138 277.338C18.5894 276.998 18.8706 276.566 19.0299 276.085C19.1891 275.603 19.2211 275.089 19.1227 274.591C19.0899 274.385 19.0019 274.192 18.8682 274.031C18.4894 273.623 17.7941 273.796 17.3385 274.121C15.8843 275.145 15.4787 277.123 15.4704 278.901C15.3223 278.26 15.448 277.59 15.4423 276.941C15.4366 276.291 15.2605 275.541 14.7038 275.197C14.3605 275.012 13.9746 274.921 13.5851 274.933C12.9307 274.908 12.2036 274.976 11.7559 275.454C11.1966 276.048 11.3477 277.045 11.8286 277.695C12.3096 278.344 13.0451 278.762 13.7222 279.212C14.2635 279.534 14.7252 279.975 15.0731 280.501C15.115 280.574 15.1488 280.652 15.1738 280.733H19.2598C20.1811 280.266 21.0352 279.676 21.799 278.98V278.982Z" fill="#EFEFEF"/>
            <path d="M341.814 282.913H0V283.603H341.814V282.913Z" fill="#2F2E41"/>
            <path d="M325.129 19.1585C325.129 19.1585 289.447 17.0027 293.651 42.0379C293.651 42.0379 292.803 46.4635 296.821 48.4706C296.821 48.4706 296.884 46.6163 300.482 47.2485C301.763 47.461 303.064 47.5236 304.36 47.435C306.107 47.3199 307.77 46.6394 309.097 45.4958C309.097 45.4958 319.128 41.3459 323.027 24.9332C323.027 24.9332 325.913 21.3521 325.799 20.431L319.777 23.0065C319.777 23.0065 321.837 27.3559 320.201 30.9696C320.201 30.9696 320.006 23.162 318.849 23.3319C318.616 23.366 315.721 24.8381 315.721 24.8381C315.721 24.8381 319.26 32.4121 316.59 37.9203C316.59 37.9203 317.603 28.5853 314.615 25.3856L310.377 27.8636C310.377 27.8636 314.514 35.6883 311.708 42.0744C311.708 42.0744 312.428 32.2809 309.483 28.4662L305.644 31.466C305.644 31.466 309.53 39.1758 307.161 44.4724C307.161 44.4724 306.852 33.0749 304.813 32.2138C304.813 32.2138 301.452 35.184 300.939 36.3974C300.939 36.3974 303.601 41.9985 301.948 44.9517C301.948 44.9517 300.935 37.3564 300.104 37.3139C300.104 37.3139 296.757 42.3462 296.409 45.8003C296.409 45.8003 296.553 40.6702 299.295 36.8388C299.295 36.8388 296.057 37.3947 294.167 39.4932C294.167 39.4932 294.688 35.9375 300.121 35.6235C300.121 35.6235 302.888 31.8046 303.626 31.5756C303.626 31.5756 298.227 31.1206 294.951 32.577C294.951 32.577 297.832 29.2208 304.618 30.7482L308.41 27.6464C308.41 27.6464 301.299 26.6745 298.282 27.7484C298.282 27.7484 301.753 24.7781 309.432 26.9422L313.56 24.4725C313.56 24.4725 307.495 23.1654 303.885 23.6239C303.885 23.6239 307.699 21.566 314.772 23.7979L317.738 22.4707C317.738 22.4707 313.297 21.5967 311.996 21.4568C310.694 21.3168 310.627 20.9604 310.627 20.9604C313.441 20.4907 316.329 20.8124 318.971 21.8898C318.971 21.8898 325.235 19.5532 325.129 19.1585Z" fill="#6C63FF"/>
            <path d="M276.238 5.90444C276.238 5.90444 260.135 4.93257 262.016 16.2451C262.016 16.2451 261.637 18.2438 263.448 19.1516C263.448 19.1516 263.478 18.303 265.105 18.5957C265.683 18.6931 266.271 18.7216 266.855 18.6807C267.646 18.6283 268.398 18.3182 268.995 17.7979C268.995 17.7979 273.53 15.9224 275.293 8.50534C275.293 8.50534 276.594 6.88883 276.543 6.47286L273.822 7.63547C273.822 7.63547 274.75 9.60008 274.021 11.2336C274.021 11.2336 273.932 7.70713 273.411 7.78828C273.305 7.78828 271.995 8.47083 271.995 8.47083C271.995 8.47083 273.597 11.895 272.39 14.3817C272.39 14.3817 272.847 10.1639 271.495 8.71693L269.58 9.85452C269.58 9.85452 271.449 13.389 270.182 16.2747C270.182 16.2747 270.508 11.8491 269.177 10.1264L267.444 11.4843C267.444 11.4843 269.198 14.968 268.13 17.3618C268.13 17.3618 267.987 12.2105 267.067 11.8203C267.067 11.8203 265.55 13.1611 265.316 13.7128C265.316 13.7128 266.52 16.2587 265.774 17.5783C265.774 17.5783 265.316 14.1455 264.927 14.1277C264.927 14.1277 263.414 16.4028 263.257 17.9465C263.333 16.5065 263.783 15.1111 264.562 13.8986C263.689 14.0642 262.883 14.4816 262.244 15.0995C262.244 15.0995 262.482 13.4914 264.935 13.3515C264.935 13.3515 266.207 11.6246 266.52 11.5184C266.52 11.5184 264.079 11.3148 262.6 11.9735C262.6 11.9735 263.901 10.4586 266.965 11.1461L268.66 9.74569C268.66 9.74569 265.445 9.3172 264.082 9.78967C264.082 9.78967 265.65 8.44884 269.12 9.42489L270.985 8.30892C270.985 8.30892 268.243 7.71927 266.612 7.92972C266.612 7.92972 268.337 7.00031 271.532 8.00556L272.867 7.40719C272.867 7.40719 270.86 7.01245 270.269 6.94874C269.678 6.88504 269.65 6.72388 269.65 6.72388C270.922 6.51198 272.228 6.6589 273.422 7.1482C273.422 7.1482 276.288 6.08115 276.238 5.90444Z" fill="#3F3D56"/>
            <path d="M27.9005 178.604C27.9005 178.604 11.7968 177.632 13.6783 188.945C13.6783 188.945 13.2996 190.943 15.1106 191.851C15.1106 191.851 15.1401 191.002 16.7674 191.295C17.3454 191.393 17.9328 191.421 18.5175 191.38C19.308 191.328 20.0596 191.018 20.6576 190.497C20.6576 190.497 25.1919 188.622 26.9549 181.205C26.9549 181.205 28.2557 179.588 28.2046 179.172L25.4839 180.335C25.4839 180.335 26.4121 182.3 25.6831 183.933C25.6831 183.933 25.5941 180.407 25.073 180.488C24.967 180.488 23.6574 181.17 23.6574 181.17C23.6574 181.17 25.2593 184.594 24.0516 187.081C24.0516 187.081 24.5091 182.863 23.1575 181.416L21.242 182.554C21.242 182.554 23.1109 186.088 21.8437 188.974C21.8437 188.974 22.1702 184.549 20.8394 182.826L19.106 184.184C19.106 184.184 20.8606 187.667 19.7926 190.061C19.7926 190.061 19.6487 184.91 18.7288 184.52C18.7288 184.52 17.2117 185.861 16.9784 186.412C16.9784 186.412 18.1819 188.958 17.4363 190.278C17.4363 190.278 16.9784 186.845 16.5887 186.827C16.5887 186.827 15.0757 189.102 14.919 190.646C14.9947 189.206 15.4447 187.811 16.2244 186.598C15.3513 186.764 14.5456 187.181 13.9063 187.799C13.9063 187.799 14.1437 186.191 16.5974 186.051C16.5974 186.051 17.8688 184.324 18.1823 184.218C18.1823 184.218 15.7415 184.014 14.2623 184.673C14.2623 184.673 15.5631 183.158 18.6273 183.845L20.3224 182.445C20.3224 182.445 17.1072 182.017 15.7438 182.489C15.7438 182.489 17.3117 181.148 20.7826 182.124L22.6474 181.008C22.6474 181.008 19.9055 180.419 18.274 180.629C18.274 180.629 19.9986 179.7 23.1942 180.705L24.5292 180.107C24.5292 180.107 22.522 179.712 21.9312 179.648C21.3404 179.584 21.3124 179.423 21.3124 179.423C22.5845 179.211 23.8905 179.358 25.084 179.848C25.084 179.848 27.9501 178.781 27.9005 178.604Z" fill="#3F3D56"/>
        </g>
        <defs>
            <linearGradient id="paint0_linear" x1="79.2855" y1="145.687" x2="79.2855" y2="145.577" gradientUnits="userSpaceOnUse">
                <stop stop-color="#808080" stop-opacity="0.251"/>
                <stop offset="0.54" stop-color="#808080" stop-opacity="0.122"/>
                <stop offset="1" stop-color="#808080" stop-opacity="0.102"/>
            </linearGradient>
            <clipPath id="clip0">
                <rect width="341.814" height="284" fill="white"/>
            </clipPath>
        </defs>
    </svg>
)

const Success = () => {
    const history = useHistory();

    return <div className="pt-4 flex flex-col items-center">
        <h1 className="text-2xl mb-2">Registro exitoso!</h1>
        <h3>Gracias por unirte a la mejor comunidad de psíquicos</h3>

        <SuccesImg className="mt-9 mb-10"/>

        <h2 className="font-semibold text-xl">
            Tu orden ha sido confirmada existosamente
        </h2>
        <h3 className="text-secondaryText font-semibold">Ya puedes platicar y consultar a los mejores expertos psíquicos del país.</h3>
        <SecondaryButton
            onClick={() => {history.push('/')}}
            className="mt-10 py-3 px-9"
            label="Comenzar a navegar"
        />
    </div>
}
export default Success
