import axios from 'axios'

const suscription = {
    suscribre: async (phonedata) => {
        const { data: response } = await axios.post(
            '/subscribers/store',
            phonedata
        )
        return response
    },
}

export default suscription
