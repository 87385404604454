import axios from 'axios'

const RecentVideo = {
    getVideos: async (limit, page, search, filter, order) => {
        const response = await axios.get(`/videos/cms/list?perPage=${limit}&page=${page}`)
        return response?.data?.payload
    },
    getById: async (requestId) => {
        const response = await axios.get(`/videos/cms/show/${requestId}`)
        return response?.data?.payload
    },
}

export default RecentVideo