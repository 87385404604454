import NavItem from "../NavItem"

const MenuHoroscope = ({ mobile }) => {
    // #region Mobile item menu
    if (mobile === true) {
        return (
            <NavItem mobile={true} url="/horoscopos" label="Horóscopos" />
        )
    }
    //#endregion

    //#region Desktop submenu
    return (
        <NavItem className="flex-grow" url="/horoscopos" label="Horóscopos">
            <NavItem url="/horoscopos/acuario" label="Acuario" />
            <NavItem url="/horoscopos/piscis" label="Piscis" />
            <NavItem url="/horoscopos/aries" label="Aries" />
            <NavItem url="/horoscopos/tauro" label="Tauro" />
            <NavItem url="/horoscopos/geminis" label="Géminis" />
            <NavItem url="/horoscopos/cancer" label="Cáncer" />
            <NavItem url="/horoscopos/leo" label="Leo" />
            <NavItem url="/horoscopos/virgo" label="Virgo" />
            <NavItem url="/horoscopos/libra" label="Libra" />
            <NavItem url="/horoscopos/escorpion" label="Escorpión" />
            <NavItem url="/horoscopos/sagitario" label="Sagitario" />
            <NavItem url="/horoscopos/capricornio" label="Capricornio" />
        </NavItem>
    )
    //#endregion
}

export default MenuHoroscope