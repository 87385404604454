import React, { useState, useEffect, useRef } from "react";
import { IconMicrophoneWhite, UserProfileIcon } from "../Icons"

const Participant = ({ participant, focusMe, key, reverseFocus ,name,image}) => {

  const [videoTracks, setVideoTracks] = useState([]);
  const [audioTracks, setAudioTracks] = useState([]);
  const [camera, setCamera] = useState(true)
  const [audio, setAudio] = useState(true)
  const [participantName, setParticipantName] = useState('')
  const [participantPhoto, setParticipantPhoto] = useState(null)
  const videoRef = useRef();
  const audioRef = useRef();

  const trackpubsToTracks = (trackMap) =>
    Array.from(trackMap.values())
      .map((publication) => publication.track)
      .filter((track) => track !== null);

  useEffect(() => {

    setVideoTracks(trackpubsToTracks(participant.videoTracks));
    setAudioTracks(trackpubsToTracks(participant.audioTracks));

    const trackSubscribed = (track) => {

      if (track.kind === "video") {
        setVideoTracks((videoTracks) => [...videoTracks, track]);
      } else if (track.kind === "audio") {
        setAudioTracks((audioTracks) => [...audioTracks, track]);
      }
    };

    const trackUnsubscribed = (track) => {
      if (track.kind === "video") {
        setVideoTracks((videoTracks) => videoTracks.filter((v) => v !== track));
      } else if (track.kind === "audio") {
        setAudioTracks((audioTracks) => audioTracks.filter((a) => a !== track));
      }
    };

    const trackEnable = (track) => {

      if (track.kind === 'video') {
        setCamera(true)
        setVideoTracks((videoTracks) => [...videoTracks, track]);
      }
      else if (track.kind === 'audio') {
        setAudio(true)
      }
    }
    const trackDisabled = (track, participant) => {
      if (track.kind === 'video') {
        setCamera(false)
      }
      else if (track.kind === 'audio') {
        setAudio(false)
      }
    }

    participant.on("trackDisabled", trackDisabled);
    participant.on("trackEnabled", trackEnable);
    participant.on("trackSubscribed", trackSubscribed);
    participant.on("trackUnsubscribed", trackUnsubscribed);

    return () => {
      setVideoTracks([]);
      setAudioTracks([]);
      participant.removeAllListeners();
    };
  }, [participant]);

  useEffect(() => {
    try {
      const videoTrack = videoTracks[0];
      if (videoTrack) {
        videoTrack.attach(videoRef.current);
        return () => {
          videoTrack.detach();
        };
      }
    } catch (ex) {

    }
  }, [videoTracks]);

  useEffect(() => {
    const audioTrack = audioTracks[0];
    if (audioTrack) {
      audioTrack.attach(audioRef.current);
      return () => {
        audioTrack.detach();
      };
    }
  }, [audioTracks]);

  return (
    <div style={{
      width: "100%",
      height: "100%",
      objectFit: "cover",
      borderRadius: 10,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      position: "relative"
    }} onClick={reverseFocus}>
      {
        camera ?
          <video style={{
            width: "100%",
            height: "100%",
            objectFit: "cover",
            borderRadius: 10,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            position: "relative"
          }} ref={videoRef} autoPlay={true} onClick={reverseFocus} /> :
          (image != null && image != 'null' ?
            <img alt={participant.identity} src={image} style={{ width: 80, height: 80,objectFit:"cover",borderRadius:"50%" }} /> :
            <div style={{ width: 80, height: 80,objectFit:"cover",borderRadius:"50%" }}><UserProfileIcon/></div>)
      }
      {!audio && 
      <div
        style={{
          position: "absolute",
          right: 10,
          top: 10,
          backgroundColor: "#676767",
          borderRadius: "50%",
          padding: 10,
          fontSize: 20
        }} >
        <IconMicrophoneWhite width="20" height="20" fill="white" />
        <div className="absolute w-6 h-1 bg-white transform rotate-135 top-5 right-2"></div>
      </div>
      }
      <audio ref={audioRef} autoPlay={true} muted={false} />
      <h3 style={{
        position: "absolute",
        top: 10,
        textAlign: "center",
        width: "90%",
        color: "white",
        fontFamily: "Montserrat",
        fontSize: 16,
        fontWeight: "600",
        textShadow: "1px 1px 2px black"
      }} >{name?name:participantName ? participantName : participant.identity}</h3>
    </div>
  );
};

export default Participant;
