import assets from "../../Assets"
import MenuArticles from "./Menus/MenuArticles"
import MenuConsultations from "./Menus/MenuConsultations"
import MenuExperts from "./Menus/MenuExperts"
import MenuHoroscope from "./Menus/MenuHoroscope"
import MenuOffers from "./Menus/MenuOffers"
import UserAccountSection from "./UserAccountSection"

export const MobileMenu = ({ onOpen, onClose, open }) => {
    // #region Botón para abrir el menú
    if (typeof onOpen === "function") {
        // renderizar el botón con su evento de clic para abrir
        return (
            <div className="lg:hidden">
                <button type="button" onClick={onOpen} className="inline-flex items-center justify-center p-2 text-gray-400 bg-white rounded-md hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500" aria-expanded="false">
                    <span className="sr-only">Open menu</span>
                    {/* <!-- Heroicon name: outline/menu --> */}
                    <svg className="w-6 h-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16M4 18h16" />
                    </svg>
                </button>
            </div>
        )
    }
    //#endregion

    // #region Contenido del menú
    if (typeof onClose === "function") {

        return (
            <div className={"absolute z-10 inset-x-0 top-0 p-2 transition origin-top-right transform lg:hidden " + (open === true ? '' : 'hidden')} >
                <div className="bg-white divide-y-2 rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 divide-gray-50">
                    <div className="px-5 pt-5 pb-6">
                        <div className="flex items-center justify-between">
                            <div>
                                <img className="w-auto h-8 object-contain" src={assets.brandLogo} alt="Paradigma" />
                            </div>
                            <div className="-mr-2">
                                <button type="button" onClick={onClose} className="inline-flex items-center justify-center p-2 text-gray-400 bg-white rounded-md hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
                                    <span className="sr-only">Close menu</span>
                                    {/* <!-- Heroicon name: outline/x --> */}
                                    <svg className="w-6 h-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                                    </svg>
                                </button>
                            </div>
                        </div>
                        <div className="mt-6">
                            <nav className="grid gap-y-8 gap-x-4">
                                <MenuOffers mobile={true} />
                                <MenuExperts mobile={true} />
                                <MenuHoroscope mobile={true} />
                                <MenuConsultations mobile={true} />
                                <MenuArticles mobile={true} />
                            </nav>
                            <UserAccountSection mobile={true} />
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    return null
}

export default MobileMenu