import axios from 'axios'

const sessionIntegration = {
   getHistory: async (page = 1) => {
      const { data } = await axios.get(`/sessions/list?perPage=10&page=${page}`)
      return data
   },

   storeSession: async body => {
      const { data } = await axios.post('/sessions/store', body)
      return data
   },
}
export default sessionIntegration
