import React, { useState, useEffect, useRef } from "react";
import Webcam from "react-webcam";
import { SecondaryButton, LinkButton, PseudoLink } from "../../Components/Button"
import { IconPhone, IconVideocallO, IconMicrophone } from "../../Components/Icons"
import Avatar from "../../Components/Avatar"

const VideoPage = ({ participant, expert, closeModal }) => {
   const [videoTracks, setVideoTracks] = useState([]);
   const [audioTracks, setAudioTracks] = useState([]);
   const [audioEnabled, setAudioEnabled] = useState(true);
   const [videoEnabled, setVideoEnabled] = useState(true);
   const [connected, setConnected] = useState(false);

   const videoRef = useRef();
   const audioRef = useRef();

   /*const trackpubsToTracks = (trackMap) =>
     Array.from(trackMap.values())
       .map((publication) => publication.track)
       .filter((track) => track !== null);
 
   useEffect(() => {
     setVideoTracks(trackpubsToTracks(participant?.videoTracks));
     setAudioTracks(trackpubsToTracks(participant?.audioTracks));
 
     const trackSubscribed = (track) => {
       if (track.kind === "video") {
         setVideoTracks((videoTracks) => [...videoTracks, track]);
       } else if (track.kind === "audio") {
         setAudioTracks((audioTracks) => [...audioTracks, track]);
       }
     };
 
     const trackUnsubscribed = (track) => {
       if (track.kind === "video") {
         setVideoTracks((videoTracks) => videoTracks.filter((v) => v !== track));
       } else if (track.kind === "audio") {
         setAudioTracks((audioTracks) => audioTracks.filter((a) => a !== track));
       }
     };
 
     participant.on("trackSubscribed", trackSubscribed);
     participant.on("trackUnsubscribed", trackUnsubscribed);
 
     return () => {
       setVideoTracks([]);
       setAudioTracks([]);
       participant.removeAllListeners();
     };
   }, [participant]);
 
   useEffect(() => {
     const videoTrack = videoTracks[0];
     if (videoTrack) {
       videoTrack.attach(videoRef.current);
       return () => {
         videoTrack.detach();
       };
     }
   }, [videoTracks]);
 
   useEffect(() => {
     const audioTrack = audioTracks[0];
     if (audioTrack) {
       audioTrack.attach(audioRef.current);
       return () => {
         audioTrack.detach();
       };
     }
   }, [audioTracks]);*/
   const onClose = () => {
      //FINISH CALL
      closeModal()
   }

   const switchVideo = () => {
      console.log("asdasdsadas")
      setVideoEnabled(prev => !prev)
   }

   const switchAudio = () => {
      setAudioEnabled(prev => !prev)
   }
   const Controls = () => (
      <div className="absolute flex flex-row bottom-5 self-center mt-3 ">
         <div
            onClick={switchAudio}
            className="px-2 py-2 mx-auto text-xs bg-superLight rounded-full cursor-pointer relative" >
            <IconMicrophone width="40" height="40" />
            {!audioEnabled && <div className="absolute w-12 h-1 bg-secondary transform rotate-135 top-7 left-1"></div>}

         </div>
         <div className="px-2 py-2 text-xs bg-red-500 rounded-full mx-4 cursor-pointer transform rotate-135"
            onClick={onClose}
         >
            <IconPhone width="40" height="40" />
         </div>
         <div
            onClick={switchVideo}
            className="px-2 py-2 mx-auto text-xs bg-superLight  cursor-pointer rounded-full relative" >
            <IconVideocallO width="40" height="40" />
            {!videoEnabled && <div className="absolute w-12 h-1 bg-secondary transform rotate-135 top-7 left-1"></div>}
         </div>

      </div>
   )
   console.log(expert)
   return (
      <div className="  h-full">
         <div className="text-center h-full relative">
           <div className="absolute z-50 self-center w-full pt-4">
           <h1 className="mt-2 text-3xl font-extrabold tracking-tight text-white sm:text-2xl filter drop-shadow	">
               Videollamada con {expert?.username}
            </h1>
            <p className="max-w-prose mt-3.5 mx-auto text-white text-xl tracking-normal pb-3  filter drop-shadow">
               {expert?.specialities?.toString()}
            </p>
           </div>
            <div className="relative flex justify-center h-full">
               {
                  <div className="w-full h-full bg-black flex justify-center relative rounded-2xl" >
                     {videoEnabled ?
                        <Webcam className={connected ? "absolute h-30 top-0 left-0" : "h-full "} /> : 
                        <Avatar className="m-auto w-40 h-40" />}
                     {/*connected? <Webcam className="h-full" /> : <Avatar className="relative mt-30 w-30 h-30" />*/}
                  </div>
               }
               {Controls()}
            </div>
            {/* <video ref={videoRef} autoPlay={true} />
               <audio ref={audioRef} autoPlay={true} muted={true} /> */}
         </div>
      </div>
   )
}

export default VideoPage