import assets from "../../Assets"
import { Link } from "react-router-dom";

const zodiacList = {
    "aquarius": "Acuario",
    "pisces": "Piscis",
    "aries": "Aries",
    "taurus": "Tauro",
    "gemini": "Geminis",
    "cancer": "Cancer",
    "leo": "Leo",
    "virgo": "Virgo",
    "libra": "Libra",
    "scorpio": "Escorpion",
    "sagittarius": "Sagitario",
    "capricorn": "Capricornio"
}

const zodiacText = {
    "aquarius": "acuario",
    "pisces": "piscis",
    "aries": "aries",
    "taurus": "tauro",
    "gemini": "geminis",
    "cancer": "cancer",
    "leo": "leo",
    "virgo": "virgo",
    "libra": "libra",
    "scorpio": "escorpion",
    "sagittarius": "sagitario",
    "capricorn": "capricornio"
}

const HoroscopeOfDay = () => {
    const styles = {
        backgroundImage: "linear-gradient(45deg, rgba(217,125,169,1) 0%, rgba(119,117,235,1) 100%)",
        filter:
            'progid:DXImageTransform.Microsoft.gradient(startColorstr="#d97da9",endColorstr="#7775eb",GradientType=1)'
    }

    return (
        <div style={styles} className="mt-24 xl:-mx-fullScreen">
            <section className="flex flex-col w-full max-w-6xl gap-4 px-10 pb-4 mx-auto text-center text-white lg:text-left pt-28">
                <h1 className="flex-row ml-10 mt-1.5 text-3xl tracking-wider">Tu horóscopo del día</h1>
                <div className="flex-row text-xl ml-9">Conoce qué es lo que las estrellas tienen para ti hoy</div>
                <div className="grid flex-row grid-cols-2 gap-y-14 mb-28 mt-11 xl:grid-cols-6">
                    {
                        Object.keys(zodiacList).map(x => {
                            return <Zodiac sign={x} label={zodiacList[x]} link={zodiacText[x]} />
                        })
                    }
                </div>
            </section>
        </div>
    )
}

// #region Subcomponents
const Zodiac = ({ sign, label, link }) => {
    switch (sign) {
        case "aquarius": return <ZodiacItem sign={sign} label={label} link={link} dates="Ene 21 - Feb 18" icon={assets.Zodiac_Acuario} />

        case "pisces": return <ZodiacItem sign={sign} label={label} link={link} dates="Feb 19 - Mar 20" icon={assets.Zodiac_Piscis} />

        case "aries": return <ZodiacItem sign={sign} label={label} link={link} dates="Mar 21 - Abr 20" icon={assets.Zodiac_Aries} />

        case "taurus": return <ZodiacItem sign={sign} label={label} link={link} dates="Abr 21 - May 21" icon={assets.Zodiac_Tauro} />

        case "gemini": return <ZodiacItem sign={sign} label={label} link={link} dates="May 22 - Jun 21" icon={assets.Zodiac_Geminis} />

        case "cancer": return <ZodiacItem sign={sign} label={label} link={link} dates="Jun 22 - Jul 22" icon={assets.Zodiac_Cancer} />

        case "leo": return <ZodiacItem sign={sign} label={label} link={link} dates="Jul 23 - Ago 23" icon={assets.Zodiac_Leo} />

        case "virgo": return <ZodiacItem sign={sign} label={label} link={link} dates="Ago 24 - Sep 23" icon={assets.Zodiac_Virgo} />

        case "libra": return <ZodiacItem sign={sign} label={label} link={link} dates="Sep 24 - Oct 23" icon={assets.Zodiac_Libra} />

        case "scorpio": return <ZodiacItem sign={sign} label={label} link={link} dates="Oct 24 - Nov 22" icon={assets.Zodiac_Escorpion} />

        case "sagittarius": return <ZodiacItem sign={sign} label={label} link={link} dates="Nov 23 - Dic 21" icon={assets.Zodiac_Sagitario} />

        case "capricorn": return <ZodiacItem sign={sign} label={label} link={link} dates="Dic 22 - Ene 20" icon={assets.Zodiac_Capricornio} />

        default: return "Invalid zodiac sign"
    }
}

const ZodiacItem = ({ sign, label, dates, icon, link }) => {
    return (
        <article className="grid grid-cols-1 px-2 text-center">
            <Link to={`/horoscopos/${link}`}>
                <div className="col-span-1"><img src={icon} className="mx-auto" alt={sign} /></div>
                <div className="col-span-1 mt-6 text-sm font-bold">{label}</div>
                <div className="col-span-1 mt-2 text-xs">{dates}</div>
            </Link>
        </article>
    )
}
// #endregion

export default HoroscopeOfDay