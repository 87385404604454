import React, { useEffect, useState } from "react"
import MessageSuccess from "../../Components/Payment/MessageSuccess"
import {
    useParams
} from "react-router-dom";
const PaymentSuccessful = () => {
    const { orderId } = useParams()
    return <MessageSuccess orderId={orderId} />
}

export default PaymentSuccessful