
const Checkbox = ({ listChecked, label, onChangeSelect, type, value }) => {
   return (
      <div className="flex flex-row items-center content-center my-2 cursor-pointer select-none py-1" onClick={() => onChangeSelect(value, type)}>
         <span className="flex flex-col">
            {listChecked.find(select => select.value === value && select.type === type) ?
               <input key={label} type="checkbox" className={`w-6 h-6 `} checked /> :
               <input key={label + "-checked"} type="checkbox" className={`w-6 h-6 `} />}
         </span>
         <span className="flex-col text-left pl-2 text-sm">
            {label}
         </span>
      </div>
   )
}

export default Checkbox