import React, { useEffect, useState } from "react";
import SecondaryLinkButton from "../../Components/Button/SecondaryLinkButton"
import CardExpert from '../CardExpert'
import { LinkButton } from "../Button";
import expertIntegration from "../../integrations/expert"
import assets from "../../Assets"
import Loader from "../../Components/Loader"

const Recommended = () => {
    const [experts, setExperts] = useState([])
    const [loading, setLoading] = useState(true)

    useEffect(async () => {
        setLoading(true)
        try {
            const expertsApi = await expertIntegration.getExperts(4, 1, '', [], "available", 'desc')
            setExperts(expertsApi?.data?.payload?.data)
            setLoading(prev => !prev)
        } catch (error) {
            setLoading(prev => !prev)
            setExperts([])
        }
    }, [])
    return <div className="pb-20 bg-white lg:pt-20 px-6">
        <div className="max-w-md pt-3 mx-auto lg:max-w-7xl sm:max-w-3xl sm:px-0">
            <h1 className="mt-2 text-3xl font-bold text-gray-900 sm:text-3xl">
                Quizás podrían interesarte...
            </h1>
            <div className="md:flex md:flex-row md:justify-between items-center">
                <p className="max-w-prose mt-3.5 text-gray-500 text-xl tracking-normal">
                    Los mejores expertos en el tema te ayudarán
                </p>
                <SecondaryLinkButton className="flex flex-row px-6 py-2 mt-5" url={`/expertos`} justify="end">
                    <span>Ver más</span><img className="ml-2 md:ml-3 w-2" src={assets.Icon_ChevronRight} alt="Right" />
                </SecondaryLinkButton>
            </div>
            <div className="mt-4">
                {
                    loading ? <Loader /> : (
                        <>
                            <div className="items-stretch grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4">
                                {
                                    experts?.map((expert, i) => {
                                        return <>
                                            <CardExpert
                                                id={expert._id}
                                                expert={expert}
                                                available={expert.available}
                                                photo={expert.image}
                                                stars={expert.rating}
                                                name={expert.username}
                                                isRecComp={true}
                                                key={`expert-key-${expert._id}`}
                                            />
                                        </>
                                    })
                                }
                            </div>
                            <div className="flex justify-center pt-20 md:col-span-4 sm:col-span-2 sm:p-20 md:p-4">
                                <LinkButton className="rounded-md bg-primary px-6 py-4 font-bold tracking-widest" color="text-white" to='/expertos'>
                                    Ver todos
                                </LinkButton>
                            </div>
                        </>
                    )
                }
            </div>
        </div>
    </div>
}
export default Recommended