import React, { useState, useRef, useContext } from "react";
import Icon from "../../Assets/Icon"
import PersonalData from "./PersonalData";
import Adress from "./Adress";
import Payment from "./Payment";
import Confirmation from "./Confirmation";
import SlipitCard from "../../Layouts/SlipitCard";
import authIntegration from "../../integrations/auth"
import Success from "./Success"
import _ from "lodash"
import { setAxiosToken } from "../../utils/axiosConfig";
import myLocalStorage, { lsKeys } from "../../utils/localStorage";
import { Context, StoreActions } from "../../store/useGlobalState";

const STATUSES = {
    selected: 'selected',
    completed: 'completed',
    disbled: 'disbled'
}

const TabItem = ({ label, status }) => {
    const calculateBarColor = (status) => {
        switch (status) {
            case STATUSES.selected:
                return 'bg-secondary h-1'
            case STATUSES.completed:
                return 'bg-secondary-light h-px'
            default:
                return 'bg-trueGray-300 h-px'
        }
    }

    return <div className="flex flex-col items-center flex-1">
        <div className={`flex items-center mb-5 ${status === STATUSES.disbled ? 'text-trueGray-500' : 'text-secondary'}`}>
            <Icon
                name={status === STATUSES.completed ? "chk-box-fill" : "chk-box"}
                className="w-5 h-5 mr-4"
            />
            <span className="text-xs" >{label}</span>
        </div>
        <div className={`${calculateBarColor(status)} w-full`} />
    </div>
}

const RegisterPage = () => {
    const [succes, setSucces] = useState(false)
    const [paymentMetod, setPaymentMetod] = useState(null)
    const formsData = useRef({})
    const { globalState, globalDispatch } = useContext(Context)
    const [selectedTab, setSelectedTab] = useState(globalState.user ? 2 : 0)

    const registerUser = async () => {
        const response = await authIntegration.register(
            _.omit(formsData.current, ['genderSelect'])
        )
        globalDispatch({ type: StoreActions.LOGIN, payload: response })
        setAxiosToken(response.token)
        myLocalStorage.save(lsKeys.sesion, response)
    }

    if (succes)
        return <Success />

    const calculateStatus = (position) => {
        if (position === selectedTab)
            return STATUSES.selected
        else if (position < selectedTab)
            return STATUSES.completed
        else
            return STATUSES.disbled
    }

    return <div className="flex flex-col pt-4">
        {/* top tabs */}
        <div className="flex mb-12 overflow-x-auto">
            <TabItem
                label='Datos personales'
                status={calculateStatus(0)}
            />
            <TabItem
                label='Datos domiciliarios'
                status={calculateStatus(1)}
            />
            <TabItem
                label='Métodos de pago'
                status={calculateStatus(2)}
            />
            <TabItem
                label='Confirmación de la orden'
                status={calculateStatus(3)}
            />
        </div>

        {/* screens */}
        <SlipitCard>
            <PersonalData
                toPrevious={() => { console.log('no previous') }}
                toNext={() => { setSelectedTab(1) }}
                hidden={selectedTab !== 0}
                saveData={(data) => { formsData.current = { ...formsData.current, ...data } }}
            />
            <Adress
                toPrevious={() => { setSelectedTab(selectedTab - 1) }}
                toNext={() => { setSelectedTab(2) }}
                hidden={selectedTab !== 1}
                saveData={data => { formsData.current.address = { ...formsData.current.address, ...data } }}
                postData={registerUser}
            />
            <Payment
                toPrevious={() => { setSelectedTab(selectedTab - 1) }}
                toNext={() => { setSelectedTab(3) }}
                isUser={globalState.user}
                hidden={selectedTab !== 2}
                setPaymentMetod={setPaymentMetod}
                paymentMetod={paymentMetod}
            />
            <Confirmation
                toPrevious={() => { setSelectedTab(selectedTab - 1) }}
                toNext={() => { setSucces(true) }}
                hidden={selectedTab !== 3}
                paymentMetod={paymentMetod}
            // saveData = {}
            />
        </SlipitCard>
    </div>
}
export default RegisterPage
