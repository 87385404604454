import React, { useState, useEffect } from "react";

const RigthPanel = () => {
    const [selectedTab, setSelectedTab] = useState(0)
    const [animate, setAnimate] = useState(false)
    const [timeId, setTimeId] = useState(-1)

    useEffect(()=> {
        setAnimate(true)
        
        if (timeId>0) {
            try{
                clearTimeout(timeId)
            }catch(err){}
        }

        setTimeId(window.setTimeout(()=> {
            setTabTo((selectedTab+1)%4)
        }, 10000))
    }, [selectedTab])

    const contentStyle = {
        ...{
            opacity: '0',
            transition: 'opacity 0.8s linear'
        },
        ...animate?{opacity:'1'}:{}
    }

    const setTabTo = (tab)=> {
        setAnimate(false)
        setSelectedTab(tab)
    }

    return (
        <div className='relative pt-12 text-sm flex-5 bg-superLight p-7'>
            {selectedTab === 0 && <div style={contentStyle}>
                <h1 className="pb-5 text-xl">Nuestros expertos</h1>
                <p className="mb-2">
                    <strong>Portal Místico</strong> es una comunidad confiable de asesores
                    espirituales que se dedican a empoderar vidas ayudando
                    a las personas a descubrir respuestas a sus preguntas
                    más urgentes, lograr un cierre, encontrar su propósito
                    en la vida y más. No hay dos expertos en nuestra comunidad
                    iguales, ya que cada uno tiene su propio toque personal
                    cuando se trata de brindar servicios intuitivos y espirituales.
                </p>
                <p>
                    Solo aceptamos a psíquicos que realmente tengan dones y estén
                    dedicados a ayudar a los demás, y ponemos a todos los
                    que aplican a un proceso riguroso de selección.
                    Nos orgullece nuestra comunidad de psíquicos atentos, compasivos,
                    con gran experiencia y conocimiento.
                </p>
                <h2 className="mt-5">
                    Obtén las respuestas que necesitas y haz que las cosas sucedan.
                </h2>
                <p>
                    Sigue adelante con confianza. Vive la experiencia de una consulta psíquica ahora mismo.
                </p>
            </div>}
            {selectedTab === 1 && <div style={contentStyle}>
                <h1 className="pb-5 text-xl">¿Cómo funciona?</h1>
                <p>
                    En <strong>Portal Místico</strong> tu controlas cuanto gastas, y solo te
                    cobramos por el tiempo que hables con el experto. No hay costos
                    escondidos y cargos sorpresa.
                </p>
                <h2>
                    Hacer tu consulta es muy fácil:
                </h2>
                <p className="mb-2"><strong>Paso 1:</strong> Regístrate y abre una cuenta.</p>
                <p className="mb-2"><strong>Paso 2:</strong> Visita la página de nuestros psíquicos y elige al de tu preferencia.</p>
                <p className="mb-2"><strong>Paso 3:</strong> Haz clic en el botón “Llamar” o “Videollamar” dependiendo del servicio que prefieras.</p>
                <p className="mb-2"><strong>Paso 4:</strong> Haz tu consulta. Recibirás una llamada o se abrirá una ventana del chat para iniciar la consulta con el consejero.</p>
                <p className="mb-2"><strong>Paso 5:</strong> Termina la consulta. Tú decides cuando quieres terminar la consulta. Solo tienes que colgar o cerrar el chat.</p>
                <p>
                    También puedes agendar previamente una cita. La disponibilidad de horario varía de acuerdo con la agenda del experto que selecciones.
                </p>
            </div>}
            {selectedTab === 2 && <div style={contentStyle}>
                <h1 className="pb-5 text-xl">Garantía de satisfacción</h1>
                <p className="mb-2">
                    Nuestra garantía de satisfacción es simple: ¡Si no estas contento con tu ultima consulta es gratis!
                    Queremos que cada conversación en nuestra plataforma cumpla con tus expectativas. Si no es así, nos comprometemos a compensarlo.
                    Si tuviste un problema con la plataforma, puedes ser elegible para recibir un
                    crédito en tu cuenta, hasta un límite de $500 pesos de Portal Místico, que puede usarse en futuras conversaciones.
                </p>
                <p>
                    Llámanos a nuestro centro de servicio al cliente o envíanos un correo electrónico a 
                    hola@portalmistico.com. Navegar el mundo psíquico no siempre es fácil, usar nuestros 
                    servicios si lo debe ser. Tu satisfacción es nuestra prioridad, por favor compártenos 
                    tus comentarios, preguntas o problemas.
                </p>
                <h2>
                    Estamos muy contentos de que estés aquí. 
                </h2>
                <p>
                    Gracias por elegirnos.
                </p>
            </div>}
            {selectedTab === 3 && <div style={contentStyle}>
                <h1 className="pb-5 text-xl">Estamos para ayudarte</h1>
                <p className="mb-2">
                    En <strong>Portal Místico</strong> estamos dedicados a ofrecerte la mejor atención y servicio, creemos de 
                    todo corazón que cada experiencia debe ser impecable, y estamos comprometidos a lograrlo.
                </p>
                <p className="mb-2">
                    Nuestra prioridad es conectarte con los mejores expertos psíquicos en la industria mientras 
                    protegemos tu seguridad y garantizamos una experiencia de cliente de primer nivel. 
                </p>
                <p>
                    Nuestro centro de atención a clientes esta disponible las 24 horas los 7 días de la semana. 
                    Por favor compártenos tus comentarios, preguntas o problemas.
                </p>
            </div>}

            {/* tabs */}
            {<div className="absolute flex bottom-5 right-5">
                <div
                    className={`rounded-full h-2.5 ml-1 cursor-pointer ${selectedTab === 0 ? 'w-5 bg-secondary' : 'w-2.5 bg-secondary-light'}`}
                    onClick={() => setTabTo(0)} />
                <div
                    className={`rounded-full h-2.5 ml-1 cursor-pointer ${selectedTab === 1 ? 'w-5 bg-secondary' : 'w-2.5 bg-secondary-light'}`}
                    onClick={() => setTabTo(1)} />
                <div
                    className={`rounded-full h-2.5 ml-1 cursor-pointer ${selectedTab === 2 ? 'w-5 bg-secondary' : 'w-2.5 bg-secondary-light'}`}
                    onClick={() => setTabTo(2)} />
                <div
                    className={`rounded-full h-2.5 ml-1 cursor-pointer ${selectedTab === 3 ? 'w-5 bg-secondary' : 'w-2.5 bg-secondary-light'}`}
                    onClick={() => setTabTo(3)} />
            </div>}
        </div>
    )
}

const SlipitCard = ({ children }) => (
    <div className="flex flex-col w-full border-2 lg:flex-row border-superLight rounded-2xl min-h-44">
        {/* left */}
        <div className='flex flex-col w-full p-4 pb-8 lg:p-8 lg:pr-12 flex-8'>
            {children}
        </div>

        {/* rigth (purple) */}
        <RigthPanel />
    </div>
)

export default SlipitCard