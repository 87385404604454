import NavItem from "../NavItem"

export const MenuExperts = ({ mobile }) => {
    // #region Mobile item menu
    if (mobile === true) {
        return (
            <NavItem mobile={true} url="/expertos" label="Expertos" />
        )
    }
    //#endregion

    //#region Desktop submenu
    return (
        <NavItem className="flex-grow py-4" url="/expertos" label="Expertos" />
    )
    //#endregion
}

export default MenuExperts