import React, { useEffect } from 'react'
import { ToastInfo } from '../../Components/Toast'
import Articles from './Articles'
import ChoosePsychic from './ChoosePsychic'
import HomeHeading from './HomeHeading'
import HoroscopeOfDay from './HoroscopeOfDay'
import Testimonials from './Testimonials'
import WhyUs from './WhyUs'


console.log("RELEASE_DATE:", process.env.REACT_APP_RELEASE);
console.log("URL BACK:", process.env.REACT_APP_URL_BACK);

export const HomePage = (props) => {
    useEffect(() => {
        let pageState = props.location.state
        console.log('pageState', pageState);
        if (pageState) {
            if (pageState.authError != null) {
                ToastInfo(pageState.authMessage)
            }
        }
    }, [props.location.state])

    return (
        <main>
            <HomeHeading />
            <ChoosePsychic />
            <WhyUs />
            <HoroscopeOfDay />
            <Articles />
            <Testimonials />
        </main>
    )
}

export default HomePage
