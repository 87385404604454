import { IconLoader } from "../Icons";

const Loader = () => (
    <div className="flex justify-center my-30">
        <IconLoader
            className="fill-current text-secondary animate-spin"
            style={{ height: 40 }}
        />
    </div>
)
export default Loader
