import React, { useState } from 'react'
import { mergeClassName } from '../utils/misc'

const Dialog = ({ onClose, darkOverlay, render, dismisable, ...props }) => {
   const [visible, setVisible] = useState(true)
   const [loading, setLoading] = useState(false)
   
   const closeDialog = e => {
      e?.stopPropagation()
      onClose?.()
      setVisible(false)
   }
   
   props = mergeClassName('bg-white rounded-xl z-10', props)
   return (visible &&
      <div className="fixed inset-0 z-10 flex justify-center items-center">
         {/* overlay */}
         <div
            className={`fixed inset-0 z-10 ${darkOverlay && 'bg-modalScreen bg-opacity-modalScreen'}`}
            onClick={(!loading && dismisable) ? closeDialog : undefined}
         />

         {/* content */}
         <div  {...props}>
            {
               render({
                  closeDialog,
                  loading,
                  setLoading
               })
            }
         </div>
         
      </div>
   )
}
export default Dialog
