export const misticoConfigOptions = {
    specialitiesOptions: {
        label: "Especialidad",
        options: {
            professional_career: "Carrera Profesional",
            clairaudient: "Clariaudiente",
            clairsentence: "Clarisintiente",
            clairvoyant: "Clarividente",
            energy_management: "Manejo de la energía",
            intuitive_and_empathetic: "Intuición / Empatía",
            love: "Amor",
            medium: "Médium",
            spirituality: "Espiritualidad",
        }
    },
    themeOptions: {
        love_relation_family: {
            label: "Amor, relaciones y familia",
            options: {
                divorce: "Divorcio / Ruptura",
                infidelity: "Infidelidad / Indecisión",
                find_love: "Encontrar el amor / Almas gemelas",
                lgbt_relationships: "Relaciones LGBT",
                sexual_life: "Vida Sexual / Intimidad",
                family_problems: "Hermanos / Problemas familiares",
                socialmedia_influence: "Influencia de los medios sociales",
                toxic_relationships: "Relaciones tóxicas",
            }
        },
        career_finances: {
            label: "Carrera profesional y finanzas",
            options: {
                professional_changes: "Cambios en la Carrera profesional",
                coworkers_problems: "Problemas con compañeros de trabajo",
                financial_stress: "Tensión financiera",
                bullying: "Acoso",
                wealth_manifestation: "Manifestación de riquezas",
                new_job: "Trabajo nuevo",
                office_romance: "Romance en la oficina",
                work_life_balance: "Balance trabajo / vida",
            }
        },
        life_destiny_meaning: {
            label: "Vida, destino y significado",
            options: {
                auras: "Auras",
                cakras: "Chakras",
                dreams_interpretation: "Interpretación de sueños",
                acceptance_change: "Aceptación del cambio / Salir adelante",
                law_attraction: "Ley de atracción",
                life_coach: "Coach de vida",
                lost_objects: "Objetos perdidos",
                meditation: "Meditación",
                past_life_interpretations: "Interpretación de vidas pasadas",
                spiritual_guide: "Guías espirituales",
                spirituality: "Espiritualidad",
                discovery_psychic_abilities: "Descubrimiento de habilidades psíquicas",
                transcendental_reprogramming: "Reprogramación transcendental",
                biomagnetism_therapy: "Terapia de Biomagnetismo",
                decoding_trapped_emotions: "Descodificación de emociones atrapadas",
            }
        },
        loss_grief: {
            label: "Pérdida y duelo",
            options: {
                face_death: "Enfrentar a la muerte",
                pending_goodbyes: "Despedidas pendientes",
                pet_lost: "Duelo de mascotas",
                overcoming_past: "Superando el pasado",
                connection_signals: "Señales de conexión",
                bond_healing: "Sanación de lazos"
            }
        },
    },
    instrumentsOptions: {
        label: "Instrumentos",
        options: {
            angels: "Ángeles",
            astrology: "Astrología",
            consultation_without_instruments: "Consulta sin instrumentos",
            cartomancy: "Cartomancia o lectura de cartas",
            crystals: "Cristales",
            dowsing: "Radiestesia",
            numerology: "Numerología",
            reiki: "Reiki / Sanación",
            tarot: "Tarot",
            magnets: "Imanes",
        }
    },
    lectureStyleOptions: {
        label: "Estilo de lectura",
        options: {
            compassionate: "Compasivo",
            direct: "Directo",
            expressive: "Expresivo",
            reflexive: "Reflexivo",
            wise: "Sabio",
        }
    }
}

export const themeOptionsData = {
    love_relation_family: {
        showed: true,
        selectedAll: false,
        label: "Amor, relaciones y familia",
        options: {
            divorce: { label: "Divorcio / Ruptura", selected: false },
            infidelity: { label: "Infidelidad / Indecisión", selected: false },
            find_love: { label: "Encontrar el amor / Almas gemelas", selected: false },
            lgbt_relationships: { label: "Relaciones LGBT", selected: false },
            sexual_life: { label: "Vida Sexual / Intimidad", selected: false },
            family_problems: { label: "Hermanos / Problemas familiares", selected: false },
            socialmedia_influence: { label: "Influencia de los medios sociales", selected: false },
            toxic_relationships: { label: "Relaciones tóxicas", selected: false },
        }
    },
    career_finances: {
        showed: false,
        selectedAll: false,
        label: "Carrera profesional y finanzas",
        options: {
            professional_changes: { label: "Cambios en la Carrera profesional", selected: false },
            coworkers_problems: { label: "Problemas con compañeros de trabajo", selected: false },
            financial_stress: { label: "Tensión financiera", selected: false },
            bullying: { label: "Acoso", selected: false },
            wealth_manifestation: { label: "Manifestación de riquezas", selected: false },
            new_job: { label: "Trabajo nuevo", selected: false },
            office_romance: { label: "Romance en la oficina", selected: false },
            work_life_balance: { label: "Balance trabajo / vida", selected: false },
        }
    },
    life_destiny_meaning: {
        showed: false,
        selectedAll: false,
        label: "Vida, destino y significado",
        options: {
            auras: { label: "Auras", selected: false },
            cakras: { label: "Chakras", selected: false },
            dreams_interpretation: { label: "Interpretación de sueños", selected: false },
            acceptance_change: { label: "Aceptación del cambio / Salir adelante", selected: false },
            law_attraction: { label: "Ley de atracción", selected: false },
            life_coach: { label: "Coach de vida", selected: false },
            lost_objects: { label: "Objetos perdidos", selected: false },
            meditation: { label: "Meditación", selected: false },
            past_life_interpretations: { label: "Interpretación de vidas pasadas", selected: false },
            spiritual_guide: { label: "Guías espirituales", selected: false },
            spirituality: { label: "Espiritualidad", selected: false },
            discovery_psychic_abilities: { label: "Descubrimiento de habilidades psíquicas", selected: false },
            transcendental_reprogramming: { label: "Reprogramación transcendental", selected: false },
            biomagnetism_therapy: { label: "Terapia de Biomagnetismo", selected: false },
            decoding_trapped_emotions: { label: "Descodificación de emociones atrapadas", selected: false },
        }
    },
    loss_grief: {
        showed: false,
        selectedAll: false,
        label: "Pérdida y duelo",
        options: {
            face_death: { label: "Enfrentar a la muerte", selected: false },
            pending_goodbyes: { label: "Despedidas pendientes", selected: false },
            pet_lost: { label: "Duelo de mascotas", selected: false },
            overcoming_past: { label: "Superando el pasado", selected: false },
            connection_signals: { label: "Señales de conexión", selected: false },
            bond_healing: { label: "Sanación de lazos", selected: false }
        }
    },
}

export const themeOptionsLabels = {
    love_relation_family: false,
    career_finances: false,
    life_destiny_meaning: false,
    loss_grief: false
}