import axios from 'axios'

export const payment = {
   // Temporal
   getUserHistory: async (page = 1, limit = 5) => {
      const data = (await axios.get(`payments/list?page=${page}&perPage=${limit}`)).data;
      const { payload } = data;
      return payload;
   },

   getPaymentMethods: async () => {
      const { data: response } = await axios.get(
         '/stripe/payment-methods'
      )
      let { payload } = response
      return payload
   },

   addMethod: async (card, exp_month, exp_year, cvc, name) => {
      const { data: response } = await axios.post(
         '/stripe/payment-methods',
         {
            card,
            exp_month,
            exp_year,
            cvc,
            name
         }
      )
      return response
   },

   updateCardInfo: async (id, exp_month, exp_year, name) => {
      const { data: response } = await axios.put(
         `/stripe/payment-methods/${id}`,
         {
            exp_month,
            exp_year,
            name
         }
      )
      console.log('método de pago actualizado', response);
      return response
   },

   removeMethod: async (id) => {
      const { data: response } = await axios.delete(`/stripe/payment-methods/${id}`)
      console.log('método de pago agregado', response);
      return response

   },

   requestPaymentIntent: async (payload) => {
      console.log(payload)
      const { data: response } = await axios.post(
         '/stripe/create-payment-intent',
         payload
      )
      return response
   },
   requestPaymentIntentOxxo: async (payload) => {
      const { data: response } = await axios.post(
         '/stripe/oxxo/payment-intent',
         payload
      )
      return response?.payload?.order_id
   },
   requestPaymentIntentPaypal: async (payload) => {
      const { data: response } = await axios.post(
         '/paypal/payment-intent',
         payload
      )
      console.log("response", response)
      return response
   },
   getPricing: async (payload) => {
      const { data: response } = await axios.post(
         '/stripe/pricing',
         payload
      )
      return response
   },
   getRates: async ({ purchase }) => {
      const { data: response } = await axios.get(
         '/stripe/get-price',
         { purchase }
      )
      return response
   },
   validateCoupon: async (code) => {
      const { data: response } = await axios.get(
         `/coupons/validate/${code}`
      )

      if (response?.message!=='Valid coupon') throw Error("Coupon invalid")

      return response?.payload
   }
}

export default payment
