import React, { useEffect } from "react"
import { Link } from 'react-router-dom'
import EmailSuscribe from "../Horoscopes/Components/Emailsuscribe"
import assets from "../../Assets"

const Request = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])
    return <>
        <div className="w-full py-20 max-w-md px-6 pt-3 mx-auto sm:max-w-3xl lg:max-w-7xl px-6 lg:px-0">
            <section className="grid grid-cols-1 md:grid-cols-5">
                <div className="col-span-2 lg:pr-10 pb-3">
                    <div className="flex mb-8">
                        <Link to='/' className="font-semibold" style={{ color: '#505DD4' }}>Regresar</Link>
                    </div>
                    <h1 className="text-3xl font-bold mb-3">Nuestro proceso de solicitud</h1>
                    <sub className="block text-base mb-3">¿Te conectas con los clientes a un nivel profundo e intuitivo?</sub>
                    <sub className="block text-base mb-3">Únete a nuestra comunidad de profesionales holísticos e intuitivos, y consejeros psíquicos.</sub>
                    <sub className="block text-base mb-3">Usa tus dones para ayudar a más gente.</sub>
                    <sub className="block text-base mb-3">Nuestros expertos están comprometidos a ofrecer consultas psíquicas auténticas por teléfono y en línea.</sub>
                    <sub className="block text-base mb-3">Comparte tus dones con otros en un ambiente de respeto y comprensión.</sub>
                </div>
                <div className="col-span-3">
                    <img className="rounded mx-auto" src={assets.RequestHeader} />
                    <did className="block mt-5">
                        <b>¿Cómo puedes ser parte?</b>
                        <p className="my-6">
                            Escríbenos un email con tus datos a  hola@paradigmanetworks.com.
                            Nosotros te enviaremos un cuestionario y estaremos en contacto contigo para decirte los siguientes pasos. Revisaremos tu historial de trabajo, experiencia y tus dones, y te daremos la oportunidad de hacernos preguntas también. Es importante para nosotros que te sientas cómodo asociándote a Portal Místico y que nosotros seamos el “hogar” correcto para ti.
                            Si eres seleccionado, te contactaremos con una oferta, y entonces comenzaremos con la sesión de inducción y resolveremos todas las dudas sobre como comenzar.
                        </p>
                        <b>¿Por qué unirte a Portal Místico?</b>
                        <p className="my-6">
                            Portal Místico ofrece una plataforma sólida, herramientas innovadoras y una comunidad de apoyo a profesionales solidarios y expertos holísticos que buscan conectar con clientes por teléfono o en línea. Nosotros nos encargamos de la logística: marketing, soporte técnico, gestión financiera y todo lo demás, para que te puedas dedicar tu tiempo a ofrecer orientación y asesoramiento intuitivo y perspicaz.
                        </p>
                        <p className="my-6">
                            Esta es la oportunidad que estabas esperando.
                        </p>
                        <b>¡Bienvenido!</b>
                    </did>
                </div>
            </section>
        </div>
        <EmailSuscribe />
    </>
}

export default Request