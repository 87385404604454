import axios from 'axios'

const reviews = {
    store: async (formData) => {
        const { session } = formData;
        delete formData.session;

        const res = await axios.post(
            'reviews/set_review/' + session,
            formData
        );

        return res
    },
    getByExpertId: async (id, page = 1, perPage = 5) => {
        const response = await axios.get(`reviews/list/${id}?page=${page}&perPage=${perPage}`)
        return response?.data?.payload
    },
    getLastReviews: async () => {
        const response = await axios.get('/reviews/list_last_reviews')
        return response?.data?.payload
    }
}

export default reviews
