const ImagePaymentProcessed = (props) => (
   <svg viewBox="0 0 356 273" fill="none" {...props}>
      <g clipPath="url(#clip0)">
         <path
            opacity={0.1}
            d="M30.914 126.179c-4.926 1.611-9.524 5.51-10.766 10.917-1.409 6.213 1.836 12.491 2.3 18.862C23.96 176.868-3.54 191.62.38 212.169c1.56 8.155 7.86 14.167 14.07 19.114 9.94 7.926 20.969 14.924 33.17 16.903 18.535 3.001 37.905-5.828 55.925-.246 14.532 4.5 25.652 17.694 40.086 22.563 10.989 3.708 22.821 2.245 34.247.746 6.144-.805 12.601-1.739 17.57-5.733 9.251-7.435 9.81-22.116 15.997-32.638 9.547-16.242 30.48-19.414 48.034-16.259 17.553 3.156 34.588 10.937 52.372 10.279 3.799-.134 7.698-.697 11.008-2.712 4.03-2.452 6.756-6.768 9.163-11.041 15.002-26.626 23.36-57.598 23.954-88.777.102-5.388-.072-11.009-2.414-15.778-2.955-6.019-8.867-9.612-14.778-12.038-14.148-5.792-29.777-6.729-43.646-13.303-13.377-6.335-24.214-17.449-35.86-26.955-23.097-18.885-50.247-31.955-78.661-37.888-6.69-1.397-13.633-2.396-20.28-.782-7.658 1.86-14.259 7.024-20.194 12.58-15.793 14.792-28.447 33.03-42.996 49.235a251.358 251.358 0 01-32.674 30.436c-5.396 4.198-11.11 9.181-17.11 12.284-5.488 2.837-10.647 2.124-16.45 4.02z"
            fill="#6C63FF"
         />
         <path
            d="M333.192 136.831a6517.04 6517.04 0 00-36.385-7.492c-22.92-4.651-28.901 12.955-28.901 12.955 0 8.967-29.885 46.165-29.885 46.165l-8.913-19.986a9.403 9.403 0 004.098-.276c5.104-1.525 7.626-7.288 8.444-12.551a41.28 41.28 0 00-3.209-23.339c-1.52-3.333-3.491-6.469-4.713-9.92-5.152-14.484 3.774-31.248-1.625-45.676-2.23-5.953-6.677-10.781-9.751-16.343-6.897-12.508-6.601-28.155-14.122-40.305-7.054-11.39-20.956-18.141-34.273-16.636-7.852.888-13.465 3.695-20.782.329-6.177-2.834-13.593-6.003-19.954-1.539-5.426 3.807-6.348 11.305-9.311 17.232-5.202 10.41-17.544 17.205-18.995 28.753-.358 2.846 0 5.798-.831 8.546-2.371 7.768-12.697 10.062-16.926 16.995-4.142 6.785.042 17.044 7.747 19 3.284.835 6.818.329 10.099 1.213 3.077.822 6.062 3.701 5.53 6.709l-6.824 1.243-19.636 2.182-3.038.191v.148l-.164.02.16.131v1.078c-5.526 2.167-14.482 7.949-17.865 23.116a22.969 22.969 0 01-4.575 9.45c-5.034 6.213-13.39 18.346-12.923 29.993.657 16.606.657 23.25 22.588 18.598 5.54-1.173 11.567-3.579 17.482-6.518.345 6.923 1.241 13.711 3.113 18.806 5.98 16.275-6.644 73.734-6.644 73.734h121.917s-10.299-33.877-20.263-51.148c-7.057-12.235-4.45-19.634-2.328-22.912a30.778 30.778 0 004.069-9.079 60.706 60.706 0 001.015-4.806c2.243 1.838 4.637 3.488 7.346 4.474 2.509.911 5.363 1.161 7.902.457a1139.08 1139.08 0 0017.209 20.575c23.918 27.898 30.562 10.627 30.562 10.627s24.25-46.165 27.586-60.116c3.337-13.951 10.631-13.287 10.631-13.287 19.994 5.059 37.715-2.284 45.949-6.702a2.192 2.192 0 00.554-3.454 2.187 2.187 0 00-1.165-.635zm-246.678 8.346c1.668-1.903 3.101-4 4.27-6.246h.062a47.472 47.472 0 01-2.335 6.798c-1.862.358-2.986.546-2.986.546l.989-1.098z"
            fill="url(#paint0_linear)"
         />
         <path
            d="M132.351 5.168c-5.363 3.768-6.276 11.177-9.196 17.054-5.143 10.302-17.343 17.031-18.778 28.457-.355 2.817 0 5.74-.821 8.455-2.342 7.689-12.552 9.96-16.75 16.82-4.095 6.713.043 16.867 7.66 18.803 3.254.828 6.738.329 9.983 1.2s6.387 4.053 5.314 7.232c-.798 2.367-3.613 3.714-4.125 6.16-.447 2.104.985 4.109 2.371 5.746 1.76 2.071 3.639 4.109 5.993 5.466 6.477 3.735 14.556 1.493 22.024 1.246a39.77 39.77 0 0125.113 7.991c2.066 1.568 4.01 3.38 5.291 5.638 4.174 7.357.227 16.59 1.114 25.002 1.005 9.559 8.138 17.232 15.34 23.586 2.877 2.537 5.911 5.039 9.524 6.351 3.612 1.311 7.931 1.239 10.969-1.102 3.192-2.458 4.302-6.814 4.322-10.847.019-4.034-.828-8.047-.624-12.074.098-1.972.817-4.316 2.739-4.704.962-.194 1.937.197 2.814.638 6.128 3.083 12.007 9.122 18.575 7.162 5.048-1.508 7.554-7.212 8.348-12.422a40.885 40.885 0 00-3.172-23.089c-1.504-3.287-3.452-6.4-4.66-9.819-5.077-14.361 3.74-30.958-1.593-45.228-2.204-5.89-6.601-10.67-9.639-16.176-6.831-12.38-6.526-27.866-13.964-39.89-6.972-11.275-20.72-17.955-33.882-16.466-7.764.878-13.311 3.659-20.546.33-6.108-2.798-13.439-5.95-19.744-1.52z"
            fill="#40243E"
         />
         <path
            d="M184.404 109.299s21.347-6.574 29.886 31.557l21.675 48.651s29.557-36.817 29.557-45.692c0 0 5.911-17.422 28.572-12.82a6801.284 6801.284 0 0135.971 7.412 2.172 2.172 0 011.496 3.098c-.2.398-.517.724-.908.936-8.138 4.372-25.659 11.64-45.426 6.633 0 0-7.225-.657-10.509 13.149-3.285 13.806-27.259 59.498-27.259 59.498s-6.568 17.093-30.214-10.519c-23.645-27.612-41.38-51.28-41.38-51.28l8.539-50.623z"
            fill="#FFBCC8"
         />
         <path
            d="M184.404 113.901l-2.831 9.332-2.798 9.231-5.537 18.253-26.273 4.931-8.562-5.467-10.486-6.696-9.041-6.245-13.291-9.204-10.838-22.682s2.989-.529 12.306-2.225l12.982-2.377c8.966-1.643 12.355-8.323 13.389-15.12 1.238-8.14-.909-16.437-.909-16.437h33.169s3.711 5.589 3.13 15.24a38.591 38.591 0 01-1.488 8.428c-2.916 10.105 3.669 13.477 8.135 14.572 1.208.307 2.444.491 3.688.549l5.255 5.917z"
            fill="#FFBCC8"
         />
         <path
            opacity={0.1}
            d="M168.804 84.436a31.198 31.198 0 01-35.389 1.203c1.238-8.139-.91-16.436-.91-16.436h33.17s3.721 5.565 3.129 15.233z"
            fill="#000"
         />
         <path
            d="M150.249 89.573c17.231 0 31.199-13.98 31.199-31.228 0-17.246-13.968-31.228-31.199-31.228S119.05 41.1 119.05 58.345c0 17.247 13.968 31.228 31.199 31.228z"
            fill="#FFBCC8"
         />
         <path
            opacity={0.1}
            d="M184.404 113.901l-2.831 9.332-2.798 9.231-5.537 18.253-26.273 4.931-8.562-5.466-10.486-6.696-9.041-6.246-13.291-9.204-10.838-22.682s2.989-.529 12.306-2.225c6.568 18.293 14.877 25.104 22.375 27.313 4.482 1.315 8.673.986 12.013.493a30.984 30.984 0 0112.884.658c10.082 2.807 15.389-2.568 18.177-9.389 2.063-5.045 2.746-10.887 2.956-14.792 1.208.307 2.443.491 3.688.549l5.258 5.94z"
            fill="#000"
         />
         <path
            d="M81.94 273.002s12.48-56.868 6.568-72.975c-1.692-4.602-2.578-10.624-2.976-16.857-.985-15.538 1.005-32.451 1.005-32.451a54.88 54.88 0 002.539-5.759c7.038-18.954-1.718-31.573-6.158-36.399a22.093 22.093 0 00-2.299-2.212l25.124-2.794c6.604 18.97 15.074 25.968 22.693 28.21 4.483 1.315 8.674.986 12.013.493a30.983 30.983 0 0112.884.658c10.082 2.807 15.39-2.568 18.178-9.388 3.461-8.468 3.034-19.161 3.034-19.161l14.451 4.273s-1.275 7.922-2.427 18.333a370.004 370.004 0 00-.779 7.665c-.985 11.039-1.57 23.244-.407 31.528a63.844 63.844 0 01-1.238 24.548 30.465 30.465 0 01-4.02 8.987c-2.098 3.241-4.677 10.569 2.299 22.682 9.852 17.093 20.033 50.622 20.033 50.622l-120.518-.003z"
            fill="#BB3996"
         />
         <path
            d="M188.838 108.805s11.931 18.737-3.724 34.844c-15.656 16.107-9.413-6.575-9.413-6.575l3.941-26.297 9.196-1.972z"
            fill="#BB3996"
         />
         <path
            opacity={0.1}
            d="M82.918 108.561c.89-.282 1.803-.487 2.73-.612 4.269 2.038 6.633 7.695 7.917 13.603a30.617 30.617 0 01-4.49 23.424c7.039-18.97-1.717-31.59-6.157-36.415zM185.797 134.639c-7.012.463-16.171-.359-16.171-.359 1.234 5.654-1.314 10.072-3.941 12.952a8.957 8.957 0 01-8.099 2.837c-15.153-2.63-38.194 11.176-38.194 11.176s-16.48 13.323-33.86 21.932c-.985-15.538 1.005-32.451 1.005-32.451.282-.549.548-1.095.805-1.643 7.021-1.315 32.532-6.509 37.619-14.135.756-1.134 1.971-2.196 3.482-3.182 4.345-2.814 11.297-5.02 17.908-6.631a71.45 71.45 0 0125.166-1.597 69.316 69.316 0 0110.693 2.087c1.531.424 3.009.881 4.365 1.358 3.718 1.315 6.535 2.784 7.025 4.349.66 2.104-3.087 2.998-7.803 3.307z"
            fill="#000"
         />
         <path
            opacity={0.1}
            d="M178.775 132.461c-5.055-.085-9.478-.483-9.478-.483 1.235 5.654-1.314 10.072-3.941 12.951a8.947 8.947 0 01-8.099 2.837c-5.711-.986-12.545.359-18.841 2.413l-10.499-6.696-9.042-6.246c2.628-1.41 4.661-2.958 5.758-4.602 2.916-4.378 12.479-7.636 21.389-9.812a70.899 70.899 0 0135.551.408l.309.082c5.711 1.594 10.722 3.57 11.389 5.707.959 3.073-7.389 3.566-14.496 3.441z"
            fill="#000"
         />
         <path
            d="M85.318 106.297s-17.18 1.749-22.187 24.039a23.1 23.1 0 01-4.565 9.467c-4.98 6.157-13.212 18.145-12.753 29.654.657 16.436.657 23.01 22.332 18.408 21.676-4.602 50.905-28.27 50.905-28.27s23.041-13.783 38.194-11.176a8.96 8.96 0 008.099-2.837c2.627-2.88 5.192-7.298 3.941-12.952 0 0 25.616 2.301 23.974-2.958-.657-2.137-5.678-4.112-11.39-5.703a70.933 70.933 0 00-35.859-.493c-8.926 2.179-18.473 5.437-21.39 9.812-5.911 8.875-39.41 14.463-39.41 14.463l.986-1.107a30.724 30.724 0 007.041-26.745c-1.28-5.907-3.635-11.564-7.918-13.602z"
            fill="#FFBCC8"
         />
         <path
            d="M80.79 106.177s-.986 39.775 13.793 33.858c14.778-5.917 3.284-33.858 3.284-33.858l-6.568-.657-10.51.657z"
            fill="#BB3996"
         />
         <path
            opacity={0.1}
            d="M125.017 58.884a145.12 145.12 0 0028.68-6.17c2.398-.776 5.002-1.808 6.122-4.063.289-.582.506-1.272 1.074-1.591.483-.273 1.08-.188 1.622-.063 3.202.76 5.951 2.768 9.002 4 3.051 1.233 6.936 1.523 9.281-.791 2.769-2.735 1.852-7.436.03-10.871-6.769-12.78-22.625-18.615-37.049-17.557-2.026.148-4.085.42-5.911 1.315-3.862 1.857-5.977 5.99-8.069 9.733-4.069 7.262-25.584 28.362-4.782 26.058z"
            fill="#000"
         />
         <path
            d="M125.017 57.9a145.12 145.12 0 0028.68-6.17c2.398-.776 5.002-1.809 6.122-4.064.289-.581.506-1.272 1.074-1.59.483-.273 1.08-.188 1.622-.063 3.202.76 5.951 2.768 9.002 4 3.051 1.233 6.936 1.522 9.281-.792 2.769-2.735 1.852-7.435.03-10.87-6.769-12.781-22.625-18.616-37.049-17.557-2.026.148-4.085.42-5.911 1.315-3.862 1.857-5.977 5.989-8.069 9.733-4.069 7.261-25.584 28.362-4.782 26.057z"
            fill="#40243E"
         />
         <path
            d="M336.702 92.7h-66.668a3.038 3.038 0 00-3.037 3.04v39.117a3.043 3.043 0 003.037 3.041h66.668a3.04 3.04 0 003.038-3.041V95.74a3.041 3.041 0 00-3.038-3.04z"
            fill="url(#paint1_linear)"
         />
         <path
            d="M334.975 93.23h-63.223a3.913 3.913 0 00-3.911 3.915v36.307a3.912 3.912 0 003.911 3.915h63.223a3.912 3.912 0 003.911-3.915V97.146a3.913 3.913 0 00-3.911-3.915z"
            fill="#6C63FF"
         />
         <path
            d="M338.886 100.957h-71.045v8.093h71.045v-8.093z"
            fill="#6C63FF"
         />
         <path
            opacity={0.3}
            d="M338.886 100.957h-71.045v8.093h71.045v-8.093z"
            fill="#000"
         />
         <path d="M332.147 115.055h-57.57v3.925h57.57v-3.925z" fill="#D6D6E3" />
         <path
            d="M279.355 134.426a5.638 5.638 0 005.635-5.641 5.638 5.638 0 00-5.635-5.64 5.638 5.638 0 00-5.636 5.64 5.638 5.638 0 005.636 5.641z"
            fill="#6C63FF"
         />
         <path
            opacity={0.3}
            d="M279.355 134.426a5.638 5.638 0 005.635-5.641 5.638 5.638 0 00-5.635-5.64 5.638 5.638 0 00-5.636 5.64 5.638 5.638 0 005.636 5.641z"
            fill="#000"
         />
         <path
            opacity={0.05}
            d="M83.088 147.922s-6.24 3.317-7.274 7.37c-1.034 4.053-2.25 4.792-2.25 4.792M236.129 189.34s-2.956 10.19 0 11.505V189.34zM88.671 200.847s25.617 5.588 44.665-5.917h8.21s24.631 12.491 36.454 8.875"
            fill="#000"
         />
         <path
            d="M270.514 181.082l-4.968 11.391 22.177 9.688 4.968-11.391-22.177-9.688z"
            fill="#8ED16F"
         />
         <path
            d="M283.72 193.808c.563-1.292-1.071-3.251-3.649-4.378-2.578-1.126-5.125-.993-5.688.299-.563 1.291 1.071 3.251 3.649 4.377 2.578 1.126 5.125.993 5.688-.298z"
            fill="#8ED16F"
         />
         <path
            opacity={0.1}
            d="M283.72 193.808c.563-1.292-1.071-3.251-3.649-4.378-2.578-1.126-5.125-.993-5.688.299-.563 1.291 1.071 3.251 3.649 4.377 2.578 1.126 5.125.993 5.688-.298z"
            fill="#000"
         />
         <path
            d="M303.037 140.496l-4.968 11.391 22.177 9.688 4.968-11.391-22.177-9.688z"
            fill="#8ED16F"
         />
         <path
            d="M316.242 153.222c.564-1.292-1.07-3.251-3.648-4.378-2.579-1.126-5.125-.993-5.688.299-.563 1.291 1.07 3.251 3.649 4.377 2.578 1.126 5.124.993 5.687-.298z"
            fill="#8ED16F"
         />
         <path
            opacity={0.1}
            d="M316.242 153.222c.564-1.292-1.07-3.251-3.648-4.378-2.579-1.126-5.125-.993-5.688.299-.563 1.291 1.07 3.251 3.649 4.377 2.578 1.126 5.124.993 5.687-.298z"
            fill="#000"
         />
         <path
            d="M342.429 181.723l-12.186 2.39 4.655 23.768 12.185-2.391-4.654-23.767z"
            fill="#8ED16F"
         />
         <path
            d="M339.489 199.836c1.381-.271 2.062-2.731 1.521-5.494-.541-2.763-2.1-4.783-3.481-4.512-1.381.271-2.062 2.73-1.521 5.493.541 2.764 2.1 4.784 3.481 4.513z"
            fill="#8ED16F"
         />
         <path
            opacity={0.1}
            d="M339.489 199.836c1.381-.271 2.062-2.731 1.521-5.494-.541-2.763-2.1-4.783-3.481-4.512-1.381.271-2.062 2.73-1.521 5.493.541 2.764 2.1 4.784 3.481 4.513z"
            fill="#000"
         />
         <path
            d="M298.59 236.839l-4.967 11.391 22.177 9.689 4.967-11.391-22.177-9.689z"
            fill="#8ED16F"
         />
         <path
            d="M311.803 249.565c.563-1.291-1.071-3.251-3.649-4.377-2.579-1.126-5.125-.993-5.688.298-.563 1.292 1.07 3.251 3.649 4.378 2.578 1.126 5.125.993 5.688-.299z"
            fill="#8ED16F"
         />
         <path
            opacity={0.1}
            d="M311.803 249.565c.563-1.291-1.071-3.251-3.649-4.377-2.579-1.126-5.125-.993-5.688.298-.563 1.292 1.07 3.251 3.649 4.378 2.578 1.126 5.125.993 5.688-.299z"
            fill="#000"
         />
         <path
            d="M243.794 226.074l-4.967 11.391 22.177 9.689 4.967-11.392-22.177-9.688z"
            fill="#8ED16F"
         />
         <path
            d="M257.003 238.804c.563-1.292-1.07-3.251-3.649-4.378-2.578-1.126-5.125-.993-5.688.299-.563 1.291 1.071 3.251 3.649 4.377 2.579 1.126 5.125.993 5.688-.298z"
            fill="#8ED16F"
         />
         <path
            opacity={0.1}
            d="M257.003 238.804c.563-1.292-1.07-3.251-3.649-4.378-2.578-1.126-5.125-.993-5.688.299-.563 1.291 1.071 3.251 3.649 4.377 2.579 1.126 5.125.993 5.688-.298z"
            fill="#000"
         />
      </g>
      <defs>
         <linearGradient
            id="paint0_linear"
            x1={190.292}
            y1={272.837}
            x2={190.292}
            y2={0.00184729}
            gradientUnits="userSpaceOnUse"
         >
            <stop stopColor="gray" stopOpacity={0.25} />
            <stop offset={0.54} stopColor="gray" stopOpacity={0.12} />
            <stop offset={1} stopColor="gray" stopOpacity={0.1} />
         </linearGradient>
         <linearGradient
            id="paint1_linear"
            x1={71680.8}
            y1={20624.7}
            x2={71680.8}
            y2={14409.4}
            gradientUnits="userSpaceOnUse"
         >
            <stop stopColor="gray" stopOpacity={0.25} />
            <stop offset={0.54} stopColor="gray" stopOpacity={0.12} />
            <stop offset={1} stopColor="gray" stopOpacity={0.1} />
         </linearGradient>
         <clipPath id="clip0">
            <path fill="#fff" d="M0 0H356V273H0z" />
         </clipPath>
      </defs>
   </svg>
)

export default ImagePaymentProcessed