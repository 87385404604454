import React, { useEffect, useState, useRef } from "react";
import Participant from "./Participant";
import { IconPhone, IconVideocallO, IconMicrophone } from "../../Components/Icons"
import { useContext } from "react"
import { Context } from '../../store/useGlobalState'
import { ToastInfo, ToastWarn } from "../Toast";
import appointmentsIntegration from '../../integrations/appointments'
import sessionIntegration from "../../integrations/session";
import moment from "moment";
import { Formik, Form, Field, ErrorMessage } from "formik";
import ModalDialog from "../ModalDialog";
import * as yup from 'yup';
import paymentApi from '../../integrations/payment';
import { IconLoader } from "../Icons";
import audioNotification from '../../Assets/Audio/notification-wrong.wav';
import { ToastSuccess, ToastError } from "../Toast";
import Loader from "../Loader";

var timeoutId = -1
var mustCloseTheCall = true

const Room = ({ roomName, room, handleLogout, replyCallRequest, calling, answerState, duration, expert, appointmentDuration }) => {
  const [participants, setParticipants] = useState([]);
  const [focusMe, setFocusMe] = useState(true);
  const [focusContact, setFocusContact] = useState(room.localParticipant.sid);
  const [mute, setMute] = useState(false);
  const [camera, setCamera] = useState(false);
  const [showExtendModal, setShowExtendModal] = useState(false)
  const [paymentMethods, setPaymentMethods] = useState([])
  const [extendingSession, setExtendingSession] = useState(false)
  const [loadingPaymentMethods, setLoadingPaymentMethods] = useState(false)

  const { globalState } = useContext(Context)
  const timerRef = useRef(null)
  var timeoutCall = null;
  var initTime = null;
  var initTimestamp = null;
  var timeoutTimer = null;
  var audio = new Audio(audioNotification);
  audio.load();

  if (!window.prdgm) {
    window.prdgm = {
      showingExtendModal: false,
      extendingCallHandled: false,
      session: null,
      elapsedTime: 0
    }
  }

  useEffect(() => {
    if (room) {
      mustCloseTheCall = true
      timeoutId = setTimeout(() => {
        if (mustCloseTheCall) {
          ToastInfo("El experto no puede atender la sesión en este momento. Intenta de nuevo más tarde.")
          replyCallRequest(false);
          handleLogout(true)
        }
      }, 30000);

      const participantConnected = participant => {
        setFocusMe(false);
        answerState(true);
        mustCloseTheCall = false;
        initTime = (new Date()).getTime();

        window.prdgm.session = null; 
        window.prdgm.elapsedTime = 0;
        window.prdgm.showingExtendModal = false;
        window.prdgm.extendingCallHandled = false;
        if (window?.prdgm?.timeoutCall !== null) { clearTimeout(window.prdgm.timeoutCall) }
        
        updateAppointmentStatus();

        if (duration >= 1) {
          if (duration >= 1 && duration < 3) {
            window.prdgm.timeoutCall = setTimeout(() => {
              ToastWarn("Tu sesión terminará en un 1 minuto.");
              showExtendMeetingModal();
            }, (duration - 1) * 60000);
          }
          else {
            window.prdgm.timeoutCall = setTimeout(() => {
              ToastWarn("Tu sesión terminará en 3 minutos.");
              window.prdgm.timeoutCall = setTimeout(() => {
                ToastWarn("Tu sesión terminará en 1 minuto.");
                showExtendMeetingModal();
              }, 2 * 60000);
            }, (duration - 3) * 60000);
          }
        }
        else window.prdgm.timeoutCall = setTimeout(() => handleLogout(), duration * 60000);
        try {
          setFocusContact(participant.sid)
        }
        catch (ex) {

        }
        setParticipants(prevParticipants => [...prevParticipants, participant]);
      };

      const participantDisconnected = participant => {
        if (participants.filter(p => p !== participant).length <= 1) {
          clearTimeout(timeoutCall);
          if (window?.prdgm?.timeoutCall !== null) { clearTimeout(window.prdgm.timeoutCall) }
          handleLogout();
        }
        try {
          setFocusContact(participants.filter(p => p !== participant)[participants.filter(p => p !== participant).length - 1].sid)
        } catch (ex) {

        }
        setParticipants(prevParticipants =>
          prevParticipants.filter(p => p !== participant)
        );
      };

      room.on("participantConnected", participantConnected);
      room.on("participantDisconnected", participantDisconnected);
      room.participants.forEach(participantConnected);

      initTimestamp = 0
      timeoutTimer = setInterval(() => {
        if (timerRef && timerRef.current && timerRef.current.innerHTML) {
          if (initTimestamp != undefined && initTime != undefined) {
            initTimestamp += 1;
            window.prdgm.elapsedTime = initTimestamp;

            if (initTimestamp === 1) {
              updateSessionData(1, initTimestamp);
            } else if (initTimestamp % 10 == 0) {
              updateSessionData(10, initTimestamp);
            }

            const diffSecs = initTimestamp % 60
            const diffMins = Math.floor(initTimestamp / 60) % 60
            const diffHours = Math.floor(initTimestamp / (60 * 60)) % 24

            let secStr = diffSecs.toString()
            if (secStr.length < 2) {
              secStr = '0' + secStr
            }

            let minStr = diffMins.toString()
            if (minStr.length < 2) {
              minStr = '0' + minStr
            }

            let hourStr = diffHours.toString()
            if (hourStr.length < 2) {
              hourStr = '0' + hourStr
            }

            timerRef.current.innerHTML = `${hourStr}:${minStr}:${secStr}`
          }
        }
      }, 1000)

      return () => {
        if (timeoutId > 0) {
          window.clearTimeout(timeoutId)
          timeoutId = -1
        }

        clearTimeout(timeoutCall);
        clearInterval(timeoutTimer);
        room.off("participantConnected", participantConnected);
        room.off("participantDisconnected", participantDisconnected);
        clearTimeout(window?.prdgm?.timeoutCall);
      };
    }

    return () => {
      if (timeoutId > 0) {
        window.clearTimeout(timeoutId);
        timeoutId = -1;
      }
      
      clearTimeout(window?.prdgm?.timeoutCall);
    }
  }, [room]);

  const updateAppointmentStatus = async () => {
    const appointmentId = localStorage.getItem(process.env.REACT_APP_CURRENT_APNT_KEY)

    if (appointmentId) {
      try {
        await appointmentsIntegration.setAppointmentStatus(appointmentId, 'finalized')
        localStorage.removeItem(process.env.REACT_APP_CURRENT_APNT_KEY)
      } catch (error) { }
    }
  }

  const updateSessionData = async (eta, etaDuration) => {
    const dataToSend = {
      expert: calling.id,
      service: "Psiquico",
      date: moment().format("YYYY/MM/DD"),
      sessionType: "video_call",
      duration: etaDuration,
      eta
    }

    if (window.prdgm?.session && window.prdgm.session?._id) {
      dataToSend.idSession = window.prdgm.session._id
    }

    try {
      const dataRes = await sessionIntegration.storeSession(dataToSend);
      window.prdgm.session = dataRes?.payload;
      return dataRes?.payload;
    } catch (error) { }

    return null;
  }

  const handleMute = () => {
    room.localParticipant.audioTracks.forEach(publication => {
      if (!mute) {
        publication.track.disable();
      }
      else {
        publication.track.enable();
      }
    });
    setMute(muted => !muted);
  };

  const handleVideo = () => {
    room.localParticipant.videoTracks.forEach(({ track }) => {
      if (!camera) {
        ToastInfo("Nota: La luz indicadora de uso de cámara de tu dispositivo permanecerá encendida durante la sesión.", 5000);
        ToastInfo("Tu tienes el control de activar y desactivar la transmisión de la cámara con el botón del ícono de cámara que aparecerá en tu pantalla.", 8000);
        track.disable();
      }
      else {
        track.enable();
      }
    });
    setCamera(muted => !muted);
  };

  const reverseFocus = (sid) => {
    setFocusContact(sid)
  };

  const showExtendMeetingModal = async () => {
    clearTimeout(window.prdgm.timeoutCall);
    window.prdgm.timeoutCall = setTimeout(() => handleLogout(), 60000);
    window.prdgm.lastDuration = 1 + (window.prdgm.elapsedTime / 60);

    if (appointmentDuration != -1 && (appointmentDuration <= window.prdgm.lastDuration)) {
      hideExtendMeetingModal();
      return;
    }

    window.prdgm.showingExtendModal = true;
    setMute(true);
    setShowExtendModal(true);

    if (audio) { try { audio.play(); } catch (error) { } }

    setLoadingPaymentMethods(true)

    try {
      const res = await paymentApi.getPaymentMethods()
      setPaymentMethods(res?.payment_methods || [])
    } catch (error) { }

    setLoadingPaymentMethods(false)
  }

  const hideExtendMeetingModal = () => {
    setShowExtendModal(false);
    window.prdgm.showingExtendModal = false;
  }

  const extendMeeting = async (values, actions) => {
    setExtendingSession(true)

    if (window.prdgm.extendingCallHandled) return;
    window.prdgm.extendingCallHandled = true;

    const data = {
      credits: values.credits,
      payment_method: values.method
    }

    try {
      const lastDuration =  window.prdgm.lastDuration;
      const res = await paymentApi.requestPaymentIntent(data);

      const timeToExtend = values.credits / expert.price_phonecall;
      const secondsExtended = Math.floor(timeToExtend * 60) % 60;
      const minutesExtended = Math.floor(timeToExtend);

      let timeExtended = timeToExtend + (lastDuration - (window.prdgm.elapsedTime/60));

      clearTimeout(window.prdgm.timeoutCall);

      let notExtendedByAppointment = false;
      if (appointmentDuration != -1) {
        const leftTimeToAppt = appointmentDuration - lastDuration;

        if (leftTimeToAppt < timeExtended) {
          timeExtended = leftTimeToAppt;
          window.prdgm.timeoutCall = setTimeout(()=> handleLogout(), timeExtended);
          notExtendedByAppointment = true;
        } else {
          window.prdgm.timeoutCall = setTimeout(() => {
            ToastWarn("Tu sesión terminará en un 1 minuto.");
            showExtendMeetingModal();
          }, (timeExtended - 1) * 60000);
        }
      } else {
        window.prdgm.timeoutCall = setTimeout(() => {
          ToastWarn("Tu sesión terminará en un 1 minuto.");
          showExtendMeetingModal();
        }, (timeExtended - 1) * 60000);
      }

      ToastSuccess(`Tu sesión se ha extendido por ${minutesExtended} minutos, ${secondsExtended} segundos`);
    } catch (error) {
      ToastError("Ocurrió un error agregando el crédito. Inténtalo en un momento.")
      actions.setSubmitting(false)
    }

    actions.setSubmitting(false)
    setShowExtendModal(false)
    setExtendingSession(false)
    window.prdgm.showingExtendModal = false;
    window.prdgm.extendingCallHandled = false;
  }

  const remoteParticipants = participants.map((participant) => (
    <Participant
      key={participant.sid}
      focusMe={focusContact === participant.sid}
      participant={participant}
      name={calling.username}
      image={calling.image}
      reverseFocus={e => {
        setFocusMe(prev => !prev)
      }}
    />
  ));
  let width = 80;
  let height = 80;

  const Controls = () => (
    <div className="absolute bottom-5 self-center mt-3 ">
      <div>
        <h2 className="text-center py-1 rounded text-white" style={{ backgroundColor: 'rgba(10,11,12,0.3)' }} ref={timerRef}>00:00:00</h2>
      </div>
      <div className="flex flex-row">
        <div
          onClick={handleMute}
          className="px-2 py-2 mx-auto text-xs bg-superLight rounded-full cursor-pointer relative" >
          <IconMicrophone width="40" height="40" />
          {mute && <div className="absolute w-12 h-1 bg-secondary transform rotate-135 top-7 left-1"></div>}

        </div>
        <div className="px-2 py-2 text-xs bg-red-500 rounded-full mx-4 cursor-pointer transform rotate-135"
          onClick={() => { replyCallRequest(false); handleLogout(true) }}
        >
          <IconPhone width="40" height="40" />
        </div>
        <div
          onClick={handleVideo}
          className="px-2 py-2 mx-auto text-xs bg-superLight  cursor-pointer rounded-full relative" >
          <IconVideocallO width="40" height="40" />
          {camera && <div className="absolute w-12 h-1 bg-secondary transform rotate-135 top-7 left-1"></div>}
        </div>
      </div>
    </div>
  )

  const styleFocus = {
    backgroundColor: "gray", width: `${width}%`, height: `${height}%`, margin: "10px 10px", borderRadius: 10
  }
  const styleUnfocus = {
    backgroundColor: "gray", width: 140, height: 140, margin: "10px 10px", borderRadius: 10, position: "absolute", bottom: "10%", right: "10%", zIndex: 8
  }

  return (
    <div style={{
      backgroundColor: "black",
      width: "100%",
      height: "100%",
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
      alignItems: "center",
      flexWrap: "wrap",
      position: "relative"
    }}>
      <div style={{
        position: "absolute",
        bottom: "10%",
        zIndex: 9,
        width: 300,
        display: "flex",
        justifyContent: "space-evenly"
      }}>
        {Controls()}
      </div>

      <div style={focusMe ? styleFocus : styleUnfocus}>
        {room ? (
          <Participant
            key={room.localParticipant.sid}
            participant={room.localParticipant}
            image={globalState?.user?.image}
            name={remoteParticipants.length > 0 ? "Tú" : "Esperando al experto"}
          />
        ) : (
          ""
        )}
      </div>

      {remoteParticipants && remoteParticipants.length > 0 &&
        remoteParticipants.map(remote => {
          return <div style={!focusMe ? styleFocus : styleUnfocus} onClick={() => {
            setFocusMe(prev => !prev)
          }}>
            {remote}
          </div>
        })
      }
      {
        showExtendModal && (
          <ModalDialog visible={showExtendModal} close={hideExtendMeetingModal} >
            <div className="p-8">
              {
                loadingPaymentMethods ? (<Loader />) : (
                  <>
                    <h2 className="text-3xl">
                      Tu sesión terminará en un minuto
                    </h2>
                    <h2 className="text-2xl text-primary">
                      ¿ Deseas extender esta sesión ?
                    </h2>
                    <div>
                      Puedes agregar crédito a tu cuenta
                    </div>
                    <div className="mt-10">
                      <Formik
                        initialValues={{ method: -1, credits: null }}
                        validationSchema={
                          yup.object().shape({
                            method: yup.string("No válido").min(3, 'No válido').required("Debe seleccionar un método de pago"),
                            credits: yup.number("Debe agregar una cantidad").min(100, 'Debe ser mayor o igual a $100 pesos').required("Debe agregar una cantidad")
                          })
                        }
                        onSubmit={extendMeeting}>
                        {
                          ({ errors, isSubmitting, touched, submitForm }) => (
                            <Form>
                              <div>
                                <div>¿Cuánto deseas agregar a tu saldo?</div>
                                <Field
                                  name="credits"
                                  placeholder="$100"
                                  className={`text-sm text-right rounded-md w-full px-3 py-2 h-10 ${errors.credits && touched.credits ? 'border-red-500' : 'border-secondary'} border focus:outline-none`}
                                ></Field>
                                {
                                  (errors?.credits && touched?.credits) && (
                                    <span className="h-px text-xs text-red-500">Debe agregar una cantidad mayor o igual a $100 pesos</span>
                                  )
                                }
                              </div>
                              <div className="mt-4">
                                <div>Selecciona el método de pago</div>
                                <Field name="method" as="select"
                                  className={`text-sm text-right rounded-md w-full px-3 py-2 h-10 ${errors.method && touched.method ? 'border-red-500' : 'border-secondary'} border focus:outline-none`} >
                                  <option value="">Seleccionar</option>
                                  {
                                    paymentMethods.map((method, idx) => (
                                      <option key={`method-${idx}`} value={method.id}>
                                        {method?.name} | ***{method?.last4}
                                      </option>
                                    ))
                                  }
                                </Field>
                                <ErrorMessage name="method" render={(msg) => <span className="h-px text-xs text-red-500">{msg}</span>} />
                              </div>
                              <div className="flex items-center justify-center mt-4">
                                <button type="button" onClick={hideExtendMeetingModal}
                                  className="mr-1 flex items-center justify-center px-6 py-3 text-xs font-bold tracking-wider text-secondary rounded-md bg-secondary-lightest focus:outline-none">
                                  Cancelar
                                </button>
                                {extendingSession
                                  ? <button disabled={extendingSession} type="button"
                                    className="ml-1 flex items-center justify-center px-6 py-3 text-xs font-bold tracking-wider text-white rounded-md bg-primary-light focus:outline-none">
                                    <IconLoader
                                      className="text-white fill-current animate-spin"
                                      style={{ height: 20 }}
                                    />
                                  </button>
                                  : <button type="submit" className="ml-1 flex items-center justify-center px-6 py-3 text-xs font-bold tracking-wider text-white rounded-md bg-primary-light focus:outline-none"
                                    onClick={submitForm}
                                    disabled={extendingSession}>
                                    Agregar
                                  </button>
                                }
                              </div>
                            </Form>
                          )
                        }
                      </Formik>
                    </div>
                  </>
                )
              }
            </div>
          </ModalDialog>
        )
      }
    </div>
  );
};

export default Room;
