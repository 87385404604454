import React, { useEffect, useState } from "react"
import { PseudoLink } from "../../Components/Button"
//Components
import CardExpert from "../../Components/CardExpert"
import { Formik, Field, Form } from "formik"
import { IconChevronDown, IconChevronLeft, IconChevronRight, IconArrowLeft, IconClose, IconSearch } from "../../Components/Icons"
import Checkbox from "../../Components/Checkbox"
import expert from "../../integrations/expert"
import Loader from "../../Components/Loader"
import { themeOptionsData, themeOptionsLabels } from "../../utils/expertConfigOptions"
import storage from "../../utils/localStorage"

const newObject = (label, value) => {
    const obj = {}
    obj[label] = value
    return obj
}

const RenderThemesOptions = ({ onChangeSelect, onChangeSelectAll, filterSelectValues }) => {
    const [themesData, setThemesData] = useState(themeOptionsLabels)

    const toggleShowThemeLabelOptions = (themeLabel) => {
        setThemesData(prev => ({
            ...prev,
            ...newObject(themeLabel, !prev[themeLabel])
        }))
    }

    return (
        <>
            <Checkbox onChangeSelect={onChangeSelectAll} type="themes" listChecked={filterSelectValues} label={"Seleccionar Todos"} />
            {
                Object.keys(themesData).map(themeLabel => (
                    <>
                        <div className="cursor-pointer flex items-center justify-between py-3 border-b"
                            key={`themes-label-${themeLabel}`}>
                            <Checkbox onChangeSelect={onChangeSelectAll} type={themeLabel} listChecked={filterSelectValues} label={themeOptionsData[themeLabel]?.label} />
                            <span className="px-3" onClick={() => toggleShowThemeLabelOptions(themeLabel)}>
                                <IconArrowLeft className={`w-2 transform ${themesData[themeLabel] ? "rotate-90" : "-rotate-90"} cursor-pointer text-black`} />
                            </span>
                        </div>
                        {
                            themesData[themeLabel] ?
                                <div>
                                    {
                                        Object.keys(themeOptionsData[themeLabel]['options']).map(option => (
                                            <>
                                                <Checkbox onChangeSelect={onChangeSelect}
                                                    type={themeLabel}
                                                    listChecked={filterSelectValues}
                                                    value={option}
                                                    key={`themes-${themeLabel}-${option}`}
                                                    label={themeOptionsData[themeLabel]['options'][option].label} />
                                            </>
                                        ))
                                    }
                                </div> : ''
                        }
                    </>
                ))
            }
        </>
    )
}

const ExpertsPage = () => {
    const INITIAL_FORM_DATA = {}
    const [openSelect, setOpenSelect] = useState({ open: false, select: null })
    const [lastSearchText, setLastSearchText] = useState('')
    const [searchText, setSearchText] = useState('')
    const [orderBy, setOrderBy] = useState('available')
    const [order, setOrder] = useState('desc')
    const [pagination, setPagination] = useState({})
    const [loading, setLoading] = useState(true)
    const [filterSelectValues, setFilterSelectValues] = useState([])
    const [experts, setExperts] = useState([])

    useEffect(() => {
        const expertFlagData = storage.read("_app_experts_flag")

        if (expertFlagData && expertFlagData.horoscopesLinkedToExperts) {
            storage.delete("_app_experts_flag")
            setFilterSelectValues(prev => [...prev, { value: 'Astrology', type: 'instruments' }])
            return
        }

        filtersSearch()
    }, [filterSelectValues, orderBy])

    /*useEffect(() => {
        if (searchText) filtersSearch()
    }, [searchText])*/

    const findAvailable = (a, b) => {
        let ret = 1

        if (a?.available && (!b?.available)) ret = -1
        if (!(a?.available || b?.available)) ret = 0

        return ret
    }

    const sortAnSetExperts = (experts) => {
        //Rating,Available,Name,New,Price_High,Price_Low
        let finalExperts = experts || []

        try {
            if (orderBy === 'rating') {
                finalExperts = finalExperts.sort((a, b) => (b?.rating - a?.rating))
            } if (orderBy === 'available') {
                finalExperts = finalExperts.sort((a, b) => findAvailable(a, b))
            } if (orderBy === 'username') {
                finalExperts = finalExperts.sort((a, b) => b?.username?.toString().toLocaleLowerCase().localeCompare(a?.username?.toString().toLocaleLowerCase()))
            } if (orderBy === 'price_videocall') {
                finalExperts = finalExperts.sort((a, b) => (b?.price_videocall - a?.price_videocall))
            } if (orderBy === 'New') {
                finalExperts = finalExperts.sort((a, b) => (-1))
            }
        } catch (error) { }


        setExperts(finalExperts)
    }

    const getPagination = (experts, page) => {
        const { totalPages, currentPage } = experts?.data?.payload;
        let pages = []

        for (let i = 0; i < totalPages; i++) {
            pages.push(i + 1)
        }

        setPagination({
            prev: experts?.data?.payload?.prev,
            next: experts?.data?.payload?.next,
            pages: pages,
            current: currentPage
        })
    }

    const filtersSearch = async () => {
        setLoading(true)
        try {
            const experts = await expert.getExperts(12, 1, searchText?.toLocaleLowerCase(), filterSelectValues, orderBy, order)
            setExperts(experts?.data?.payload?.data || []);
            getPagination(experts)
            setLoading(false)
        } catch (error) {
            sortAnSetExperts([])
            setLoading(false)
        }
    }

    const goToPage = async (page) => {
        setLoading(true)
        try {
            const experts = await expert.getExperts(12, page, searchText?.toLocaleLowerCase(), filterSelectValues, orderBy, order)
            setExperts(experts?.data?.payload?.data || []);
            getPagination(experts, page)
            setLoading(false)
            window.scrollTo(0, 0);
        } catch (error) {
            setLoading(false)
        }
    }


    const handleSubmit = async (data, { setSubmitting, setFieldError }) => {
    }

    const handlePagination = async (url, next, page) => {
        if (next) {
            if (pagination.pages.length < pagination.current + 1)
                return
        }
        const experts = await expert.pagination(url)
        setExperts(experts?.data?.payload?.data || [])
        const { totalPages, currentPage } = experts?.data?.payload;
        let pages = []
        for (let i = 0; i < totalPages; i++) {
            pages.push(i + 1)
        }

        console.log(pages);
        setPagination(prevData => ({
            prev: experts?.data?.payload?.prev,
            next: experts?.data?.payload?.next,
            pages: pages,
            current: currentPage
        }))
    }

    const handleSearch = async () => {
        filtersSearch()
    }

    const handleCleanSearch = async () => {
        setSearchText('')
        setLoading(true)
        try {
            const experts = await expert.getExperts(12, 1, '', filterSelectValues, orderBy, order)
            setExperts(experts?.data?.payload?.data || []);
            getPagination(experts)
            setLoading(false)
        } catch (error) {
            sortAnSetExperts([])
            setLoading(false)
        }
    }

    const onChangeSearch = ({ target }) => {
        setLastSearchText(searchText)

        if (searchText && (!target?.value)) return handleCleanSearch()
        setSearchText(target?.value)
    }

    const onChangeSelect = (id, type) => {
        if (filterSelectValues.find(selectValue => selectValue.value === id && selectValue.type === type))
            setFilterSelectValues(prev => [...prev.filter(selectValue => selectValue.value !== id)])
        else {
            setFilterSelectValues(prev => [...prev, { value: id, type }])
        }
    }

    const onChangeSelectAll = (id, type) => {
        if (filterSelectValues.find(selectValue => selectValue.value === id && selectValue.type == type)) {
            let filter = filterSelectValues
            let currentList = []

            switch (type) {
                case "specialities":
                    currentList = arrayEspecialidad
                    break;
                case "instruments":
                    currentList = arrayInstrumentos
                    break;
                case "styles":
                    currentList = arrayLectura
                    break;
                case "themes":
                    currentList = [...Object.keys(themeOptionsData["love_relation_family"].options),
                    ...Object.keys(themeOptionsData["career_finances"].options),
                    ...Object.keys(themeOptionsData["life_destiny_meaning"].options),
                    ...Object.keys(themeOptionsData["loss_grief"].options)]
                    break;
                case "love_relation_family":
                    currentList = Object.keys(themeOptionsData[type].options)
                    break;
                case "career_finances":
                    currentList = Object.keys(themeOptionsData[type].options)
                    break;
                case "life_destiny_meaning":
                    currentList = Object.keys(themeOptionsData[type].options)
                    break;
                case "loss_grief":
                    currentList = Object.keys(themeOptionsData[type].options)
                    break;
            }

            currentList.map(esp => {
                filter = filter.filter(select => select.value !== esp)
            })

            filter = filter.filter(select => select.value !== id && select.type == type)
            setFilterSelectValues([...filter])
        }
        else {
            let arraySelect = []
            switch (type) {
                case "specialities":
                    arrayEspecialidad.map(esp => {
                        arraySelect.push({ value: esp, type })
                    })
                    break;
                case "instruments": {
                    arrayInstrumentos.map(esp => {
                        arraySelect.push({ value: esp, type })
                    })
                    break;
                }
                case "styles": {
                    arrayLectura.map(esp => {
                        arraySelect.push({ value: esp, type })
                    })
                    break;
                }
                case "themes": {
                    (["love_relation_family", "career_finances",
                        "life_destiny_meaning", "loss_grief"]).forEach(label => {
                            Object.keys(themeOptionsData[label].options).map(esp => {
                                arraySelect.push({ value: esp, type: label })
                            })

                            arraySelect.push({value: undefined, type:label})
                        })
                    break;
                }
                case "love_relation_family":
                    Object.keys(themeOptionsData[type].options).map(esp => {
                        arraySelect.push({ value: esp, type })
                    })
                    break;
                case "career_finances":
                    Object.keys(themeOptionsData[type].options).map(esp => {
                        arraySelect.push({ value: esp, type })
                    })
                    break;
                case "life_destiny_meaning":
                    Object.keys(themeOptionsData[type].options).map(esp => {
                        arraySelect.push({ value: esp, type })
                    })
                    break;
                case "loss_grief":
                    Object.keys(themeOptionsData[type].options).map(esp => {
                        arraySelect.push({ value: esp, type })
                    })
                    break;
            }

            arraySelect.push({ value: id, type })
            setFilterSelectValues(prev => [...prev, ...arraySelect])
        }
    }

    const handleClickSelect = (id) => {
        if (openSelect.open && openSelect.select === id)
            handleCloseSelect()
        else
            setOpenSelect({ open: true, select: id })
    }

    const handleCloseSelect = () => {
        setOpenSelect({ open: false, select: null })
    }

    const arrayEspecialidad2 = [
        "Clariaudiente",
        "Carrera profesional",
        "Clarividente",
        "Clarisintiente",
        "Manejo de la energía",
        "Intuitivo/Empático",
        "Amor",
        "Médium",
        "Espiritualidad"
    ]
    const arrayEspecialidad = ["clairaudient", "professional_career", "clairvoyant", "clairsentence", "energy_management", "intuitive_and_empathetic", "love", "medium", "spirituality"]
    const arrayInstrumentos2 = [
        "Ángeles",
        "Astrología",
        "Consulta sin instrumentos",
        "Cartomancia",
        "Cristales",
        "Radiestesia",
        "Numerología",
        "Reiki / Sanación",
        "Tarot",
        "Imanes"
    ]
    const arrayInstrumentos = ["angels", "astrology", "consultation_without_instruments", "cartomancy", "crystals", "dowsing", "numerology", "reiki", "tarot", "magnets"]
    //const arrayTema2 = ["Amor, relaciones y familia", "Carrera profesional y finanzas", "Vida, destino y significado", "Pérdida y duelo"]
    //const arrayTema = ["Relationships", "Career", "Destiny", "Lost"]
    const arrayLectura = ["compassionate", "direct", "expressive", "reflexive", "wise"]
    const arrayLectura2 = ["Compasivo", "Directo", "Expresivo", "Reflexivo", "Sabio"]

    return (
        <>

            <div className="pb-20 bg-white lg:pt-20">
                <div className="max-w-md px-6 pt-3 mx-auto text-center lg:max-w-7xl sm:max-w-3xl sm:px-0">
                    <h1 className="mt-2 text-3xl font-extrabold tracking-tight text-gray-900 sm:text-4xl">
                        Los mejores expertos
                    </h1>
                    <p className="max-w-prose mt-3.5 mx-auto text-gray-500 text-xl tracking-normal pb-3">
                        Conoce a todos nuestros especialistas que pueden ayudarte
                    </p>
                    <div>
                        <div>
                            <Formik
                                initialValues={INITIAL_FORM_DATA}
                                onSubmit={handleSubmit}
                            >
                                <Form name="searchBar">

                                    <div className="relative flex-row flex  align-center justify-center mb-10">
                                        <div className="relative" style={{ width: "100%", maxWidth: "25rem" }}>
                                            <input
                                                value={searchText}
                                                onChange={onChangeSearch}
                                                style={{ width: "100%", maxWidth: "25rem" }}
                                                className={`text-sm rounded-md  px-3 pl-2 pr-5 border border-primary-lightest h-10 focus:outline-none focus:border-secondary rounded-r-none`}
                                            />
                                            {searchText && <div className="absolute right-1 top-3" onClick={handleCleanSearch}>
                                                <IconClose stroke="rgba(113, 113, 122,0.5)" />
                                            </div>
                                            }
                                        </div>
                                        <PseudoLink onClick={handleSearch} className="px-3 py-2 bg-primary-light rounded rounded-l-none	">
                                            <IconSearch className="h-5 " />
                                        </PseudoLink>
                                    </div>


                                </Form>
                            </Formik>
                        </div>
                        <Formik
                            initialValues={INITIAL_FORM_DATA}
                            onSubmit={handleSubmit}
                        >

                            <Form className="mt-2 grid grid-cols-2 lg:grid-cols-4 xl:grid-cols-5  mb-2 md:gap-4 sm:gap-4 sm:grid-cols-2  
                           gap-x-3  gap-y-3">
                                { /* inputs */}
                                <div className="">
                                    <Field name="specialities">
                                        {({ field, meta: { error, touched } }) => (
                                            <>
                                                <div className="relative">
                                                    <div
                                                        onClick={() => handleClickSelect("specialities")}
                                                        className="flex flex-row items-center justify-between pl-2 bg-white border rounded-md text-sm h-9 pr-2">
                                                        <label className="m-none whitespace-nowrap overflow-hidden	overflow-ellipsis">{`Especialidad${filterSelectValues.find(select => select.type === "specialities") ?
                                                            `: Seleccionados (${filterSelectValues.filter(select => select.type === "specialities" && select.value != "Seleccionar Todos").length == arrayEspecialidad.length ?
                                                                "Todos" :
                                                                filterSelectValues.filter(select => select.type === "specialities" && select.value != "Seleccionar Todos").length
                                                            })` : ''
                                                            }`}</label>
                                                        <span>
                                                            <IconChevronDown className="text-white ml-2" style={{width:'13px', height:'13px'}} fill="red" />
                                                        </span>
                                                    </div>
                                                    {openSelect?.open && openSelect?.select === "specialities" &&
                                                        <div className="absolute p-3 top-10 bg-white border rounded-md m" 
                                                            style={{ minWidth: 220, zIndex: 100, maxHeight:'400px', overflowY:'auto' }}>
                                                            <Checkbox onChangeSelect={onChangeSelectAll} type="specialities" listChecked={filterSelectValues} label={"Seleccionar Todos"} />
                                                            {
                                                                arrayEspecialidad.map((especialidad, index) => (
                                                                    <Checkbox onChangeSelect={onChangeSelect} type="specialities" listChecked={filterSelectValues} value={especialidad} label={arrayEspecialidad2[index]} />
                                                                )
                                                                )
                                                            }
                                                        </div>}
                                                </div>

                                            </>
                                        )}
                                    </Field>
                                </div>
                                <div className="">
                                    <Field name="themes">
                                        {({ field, meta: { error, touched } }) => (
                                            <>
                                                <div className="relative">
                                                    <div
                                                        onClick={() => handleClickSelect("themes")}
                                                        className="flex flex-row items-center justify-between pl-2 bg-white border rounded-md text-sm h-9 pr-2">
                                                        <label className="m-none whitespace-nowrap overflow-hidden	overflow-ellipsis">{`Tema y especialización${filterSelectValues.find(select =>
                                                        (select.type === "themes" || select.type === "love_relation_family"
                                                            || select.type === "career_finances" || select.type === "life_destiny_meaning" ||
                                                            select.type === "loss_grief")) ?
                                                            `: Varios seleccionados ` : ''}`}</label>
                                                        <span>
                                                            <IconChevronDown className="w-3 text-white ml-2" style={{width:'13px', height:'13px'}} fill="red" />
                                                        </span>
                                                    </div>
                                                    {openSelect?.open && openSelect?.select === "themes" &&
                                                        <div className="absolute p-3 top-10 bg-white border rounded-md m" 
                                                            style={{ minWidth: 270, zIndex: 100, maxHeight:'400px', overflowY:'auto' }}>
                                                            <RenderThemesOptions onChangeSelect={onChangeSelect}
                                                                onChangeSelectAll={onChangeSelectAll}
                                                                filterSelectValues={filterSelectValues} />
                                                        </div>
                                                    }
                                                </div>
                                            </>
                                        )}
                                    </Field>
                                </div>
                                <div className="">
                                    <Field name="instruments">
                                        {({ field, meta: { error, touched } }) => (
                                            <>
                                                <div className="relative">
                                                    <div
                                                        onClick={() => handleClickSelect("instruments")}
                                                        className="flex flex-row items-center justify-between pl-2 bg-white border rounded-md text-sm h-9 pr-2">
                                                        <label className="m-none whitespace-nowrap overflow-hidden	overflow-ellipsis">{`Instrumentos${filterSelectValues.find(select => select.type === "instruments") ?
                                                            `: Seleccionados (${filterSelectValues.filter(select => select.type === "instruments" && select.value != "Seleccionar Todos").length == arrayInstrumentos.length ?
                                                                "Todos" :
                                                                filterSelectValues.filter(select => select.type === "instruments" && select.value != "Seleccionar Todos").length
                                                            })` : ''
                                                            }`}</label>
                                                        <span>
                                                            <IconChevronDown className="text-white ml-2" style={{width:'13px', height:'13px'}} fill="red" />
                                                        </span>
                                                    </div>
                                                    {openSelect?.open && openSelect?.select === "instruments" &&
                                                        <div className="absolute p-3 top-10 bg-white border rounded-md m" 
                                                            style={{ minWidth: 250, zIndex: 100, maxHeight:'400px', overflowY:'auto' }}>
                                                            <Checkbox onChangeSelect={onChangeSelectAll} type="instruments" listChecked={filterSelectValues} label={"Seleccionar Todos"} />
                                                            {
                                                                arrayInstrumentos.map((especialidad, index) => (
                                                                    <Checkbox onChangeSelect={onChangeSelect} type="instruments" listChecked={filterSelectValues} value={especialidad} label={arrayInstrumentos2[index]} />
                                                                )
                                                                )
                                                            }
                                                        </div>}
                                                </div>
                                            </>
                                        )}
                                    </Field>
                                </div>
                                <div className="">
                                    <Field name="styles">
                                        {({ field, meta: { error, touched } }) => (
                                            <div className="relative">
                                                <div
                                                    onClick={() => handleClickSelect("styles")}
                                                    className="flex flex-row items-center justify-between pl-2 bg-white border rounded-md text-sm h-9 pr-2">
                                                    <label className="m-none whitespace-nowrap overflow-hidden	overflow-ellipsis">{`Estilo de lectura${filterSelectValues.find(select => select.type === "styles") ?
                                                        `: Seleccionados (${filterSelectValues.filter(select => select.type === "styles" && select.value != "Seleccionar Todos").length == arrayLectura.length ?
                                                            "Todos" :
                                                            filterSelectValues.filter(select => select.type === "styles" && select.value != "Seleccionar Todos").length
                                                        })` : ''
                                                        }`}</label>
                                                    <span>
                                                        <IconChevronDown className="text-white ml-2" style={{width:'13px', height:'13px'}} fill="red" />
                                                    </span>
                                                </div>
                                                {openSelect?.open && openSelect?.select === "styles" &&
                                                    <div className="absolute p-3 top-10 bg-white border rounded-md m" 
                                                        style={{ minWidth: 250, zIndex: 100, maxHeight:'400px', overflowY:'auto' }}>
                                                        <Checkbox onChangeSelect={onChangeSelectAll} type="styles" listChecked={filterSelectValues} label={"Seleccionar Todos"} />
                                                        {
                                                            arrayLectura.map((especialidad, index) => (
                                                                <Checkbox onChangeSelect={onChangeSelect} type="styles" listChecked={filterSelectValues} value={especialidad} label={arrayLectura2[index]} />
                                                            )
                                                            )
                                                        }
                                                    </div>}
                                            </div>
                                        )}
                                    </Field>
                                </div>
                                <div className="col-span-2 xl:col-span-1 flex flex-row xl:justify-center align-center">
                                    <label
                                        className="block text-xs font-semibold text-gray-500 m-auto mx-0"
                                        htmlFor="sort">
                                        Ordenar:
                                    </label>
                                    <Field name="sort">
                                        {({ field, meta: { error, touched } }) => (
                                            <select
                                                onChange={e => {
                                                    setOrderBy(e.target.value)
                                                    switch(e.target.value) {
                                                        case 'rating': case 'available': case 'created_at': case 'price_videocall': setOrder('desc'); break;
                                                        case 'username': setOrder('asc');
                                                    }
                                                }}
                                                className={`h-9 bg-white lg:mx-2 rounded-md text-xs pl-2 truncate`}
                                            >
                                                <option value={"available"}>{"Disponibilidad"}</option>
                                                <option value={"rating"}>{"Rating"}</option>
                                                <option value={"username"}>{"Orden alfabético"}</option>
                                                <option value={"created_at"}>{"Nuevos"}</option>
                                                {/* <option value={"price_videocall"}>{"Tarifa (Baja a alta)"}</option> */}
                                                <option value={"price_videocall"}>{"Tarifa (Alta a baja)"}</option>
                                            </select>
                                        )}
                                    </Field>
                                </div>
                            </Form></Formik>
                    </div>
                    <div className="mt-4" onClick={handleCloseSelect}>
                        {
                            loading ? <Loader /> : <div className="items-stretch grid grid-cols-1 lg:grid-cols-3 xl:grid-cols-4 sm:gap-4 sm:grid-cols-2 xl:gap-x-14 xl:gap-y-10">

                                {experts.map((expert, index) => (
                                    <CardExpert
                                        id={expert._id}
                                        expert={expert}
                                        available={expert.available}
                                        photo={expert.image}
                                        stars={expert.rating}
                                        name={expert.username}
                                        key={`expert-key-${expert._id}`}
                                    />
                                ))}
                            </div>
                        }
                    </div>
                    <div className="flex text-gray-700 justify-end self-end mt-10">
                        {
                            (pagination?.pages?.length > 1) && (
                                <>
                                    {pagination?.prev && <div onClick={() => handlePagination(pagination.prev, false)}
                                        className="h-12 w-12 mr-1 flex justify-center items-center cursor-pointer">
                                        <IconChevronLeft className="h-5 text-white ml-2 " style={{ width: 8, height: 12 }} />

                                    </div>}
                                    <div className="flex flex-row justify-center items-center ">
                                        {pagination?.pages?.map(page => {
                                            return <div
                                                className="m-1 text-gray-500 cursor-pointer font-semibold mx-4"
                                                style={{ color: pagination?.current == page ? "#505DD5" : "rgba(113, 113, 122, var(--tw-text-opacity))" }}
                                                onClick={() => {
                                                    goToPage(page)
                                                }
                                                }
                                            >
                                                {page}
                                            </div>
                                        })}
                                    </div>
                                    {pagination?.next && <div onClick={() => handlePagination(pagination.next, true)}
                                        className="h-12 w-12 mr-1 flex justify-center items-center cursor-pointer">
                                        <IconChevronRight className="h-5 text-white ml-2 " style={{ width: 8, height: 12 }} />
                                    </div>}
                                </>
                            )
                        }
                    </div>
                </div>
            </div>
        </>
    )
}

export default ExpertsPage