import { mergeClassName } from "../../utils/misc"

const CTASecondaryButton = ({inverted, ...props}) => {
    const calculateColors = () => {
        let temp = ''

        if (props.disabled)
            temp += 'text-gray-500 '
        else
            temp += inverted ? 'text-secondary ' : 'text-white '

        temp += inverted ? 'bg-secondary-lightest ' : 'bg-secondary '

        return temp
    }


    const baseClasName = `${calculateColors() } focus:outline-none flex items-center justify-center font-bold  rounded-md`
    props = mergeClassName(baseClasName, props)

    return (
        <button
            type="submit"
            {...props}
            >
            {props.label ? props.label : props.children}
        </button>
    )
}
export default CTASecondaryButton
