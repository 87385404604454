import axios from 'axios'

const Contact = {
    send: async (contactData) => {
        const { data: response } = await axios.post(
            '/contacts/store',
            contactData
        )
        return response
    },
}

export default Contact
