import React, { useEffect, useState, useRef } from "react"
import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import {
    ZodiacAquarius,
    ZodiacAries,
    ZodiacCancer,
    ZodiacCapricorn,
    ZodiacGemini,
    ZodiacLeo,
    ZodiacLibra,
    ZodiacPisces,
    ZodiacSagittarius,
    ZodiacScorpio,
    ZodiacTaurus,
    Zodiacvirgo,
    IconArrowLeft
} from "../../../Components/Icons"

const ZODIAC_DICTIONARY = {
    "aquarius": 'Acuario',
    "pisces": 'Piscis',
    "aries": 'Aries',
    "taurus": 'Tauro',
    "gemini": 'Géminis',
    "cancer": 'Cáncer',
    "leo": 'Leo',
    "virgo": 'Virgo',
    "libra": 'Libra',
    "scorpio": 'Escorpión',
    "sagittarius": 'Sagitario',
    "capricorn": 'Capricornio'
}

const zodiacText = {
    "aquarius": "acuario",
    "pisces": "piscis",
    "aries": "aries",
    "taurus": "tauro",
    "gemini": "geminis",
    "cancer": "cancer",
    "leo": "leo",
    "virgo": "virgo",
    "libra": "libra",
    "scorpio": "escorpion",
    "sagittarius": "sagitario",
    "capricorn": "capricornio"
}

const zodiacList = [
    "aquarius",
    "pisces",
    "aries",
    "taurus",
    "gemini",
    "cancer",
    "leo",
    "virgo",
    "libra",
    "scorpio",
    "sagittarius",
    "capricorn",
    "aquarius",
    "pisces",
    "aries",
    "taurus",
    "gemini",
    "cancer",
    "leo",
    "virgo",
    "libra",
    "scorpio",
    "sagittarius",
    "capricorn"
];

const ZodiacCarousel = () => {
    const [selectedItem, setSelectedItem] = useState(undefined)
    const [cursor, setCursor] = useState(2);
    const { zodiacsign } = useParams();

    const orderZodiacList = () => {
        if (zodiacsign) {
            var len = 0;
            let label = Object.keys(zodiacText).find(key => zodiacText[key] === zodiacsign);
            while (zodiacList.findIndex((item) => label === item) !== 5 && len < 20) {
                const splice_array = zodiacList.splice(zodiacList.length - 1, 1)
                zodiacList.splice(0, 0, splice_array[0])
                len++
            }
        }
    }

    orderZodiacList()

    useEffect(() => {
        setSelectedItem(Object.keys(zodiacText).find(key => zodiacText[key] === zodiacsign))
    }, [zodiacsign])

    const changeCursor = (position) => {
        if (position == 1) {
            setCursor((cursor + 1) < 12 ? (cursor + 1) : 2)
        } else {
            setCursor((cursor - 1) < 2 ? 12 : (cursor - 1))
        }
    }

    const renderItems = () => {
        return zodiacList.map((x, i) =>
            <ZodiacIcon sign={x} selected={selectedItem} key={`zodiac-carousel-item-${i}`}
                cursor={cursor} />
        )
    }

    return <div className="flex flex-row items-center my-20 relative">
        <IconArrowLeft onClick={() => changeCursor(-1)}
            color="stroke-blue" className="cursor-pointer absolute"
            style={{ width: '30px', padding: '8px' }} />
        <div className="flex items-center overflow-x-hidden mx-5">
            <div className="flex items-center zodiac-items-container"
                style={{ position: 'relative', width: '100%', flexWrap: 'nowrap' }} >
                {
                    renderItems()
                }
            </div>
        </div>
        <IconArrowLeft onClick={() => changeCursor(1)}
            color="stroke-blue" className="cursor-pointer transform rotate-180 absolute right-1"
            style={{ width: '30px', padding: '8px', boxSizing: 'border-box' }} />
    </div>
}

const ZodiacIcon = ({ sign, selected, cursor }) => {
    const size = selected === sign ? "90px" : "50px"
    const zodiacSigns = {
        aquarius: <CarouselItem sign={sign} text={zodiacText[sign]} dates="Ene 21 - Feb 18"
            selected={selected === sign} cursor={cursor}
            icon={<ZodiacAquarius width={size} height={size} className="stroke-blue mx-auto border rounded-full border-secondary" />} />,
        pisces: <CarouselItem sign={sign} text={zodiacText[sign]} dates="Feb 19 - Mar 20"
            selected={selected === sign} cursor={cursor}
            icon={<ZodiacPisces width={size} height={size} className="stroke-blue mx-auto border rounded-full border-secondary" />} />,
        aries: <CarouselItem sign={sign} text={zodiacText[sign]} dates="Mar 21 - Abr 20"
            selected={selected === sign} cursor={cursor}
            icon={<ZodiacAries width={size} height={size} className="stroke-blue mx-auto border rounded-full border-secondary" />} />,
        taurus: <CarouselItem sign={sign} text={zodiacText[sign]} dates="Abr 21 - May 21"
            selected={selected === sign} cursor={cursor}
            icon={<ZodiacTaurus width={size} height={size} className="stroke-blue mx-auto border rounded-full border-secondary" />} />,
        gemini: <CarouselItem sign={sign} text={zodiacText[sign]} dates="May 22 - Jun 21"
            selected={selected === sign} cursor={cursor}
            icon={<ZodiacGemini width={size} height={size} className="stroke-blue mx-auto border rounded-full border-secondary" />} />,
        cancer: <CarouselItem sign={sign} text={zodiacText[sign]} dates="Jun 22 - Jul 22"
            selected={selected === sign} cursor={cursor}
            icon={<ZodiacCancer width={size} height={size} className="stroke-blue mx-auto border rounded-full border-secondary" />} />,
        leo: <CarouselItem sign={sign} text={zodiacText[sign]} dates="Jul 23 - Ago 23"
            selected={selected === sign} cursor={cursor}
            icon={<ZodiacLeo width={size} height={size} className="stroke-blue mx-auto border rounded-full border-secondary" />} />,
        virgo: <CarouselItem sign={sign} text={zodiacText[sign]} dates="Ago 24 - Sep 23"
            selected={selected === sign} cursor={cursor}
            icon={<Zodiacvirgo width={size} height={size} className="stroke-blue mx-auto border rounded-full border-secondary" />} />,
        libra: <CarouselItem sign={sign} text={zodiacText[sign]} dates="Sep 24 - Oct 23"
            selected={selected === sign} cursor={cursor}
            icon={<ZodiacLibra width={size} height={size} className="stroke-blue mx-auto border rounded-full border-secondary" />} />,
        scorpio: <CarouselItem sign={sign} text={zodiacText[sign]} dates="Oct 24 - Nov 22"
            selected={selected === sign} cursor={cursor}
            icon={<ZodiacScorpio width={size} height={size} className="stroke-blue mx-auto border rounded-full border-secondary" />} />,
        sagittarius: <CarouselItem sign={sign} text={zodiacText[sign]} dates="Nov 23 - Dic 21"
            selected={selected === sign} cursor={cursor}
            icon={<ZodiacSagittarius width={size} height={size} className="stroke-blue mx-auto border rounded-full border-secondary" />} />,
        capricorn: <CarouselItem sign={sign} text={zodiacText[sign]} dates="Dic 22 - Ene 20"
            selected={selected === sign} cursor={cursor}
            icon={<ZodiacCapricorn width={size} height={size} className="stroke-blue mx-auto border rounded-full border-secondary" />} />,
    }
    return zodiacSigns[sign]
}

const CarouselItem = ({ sign, dates, icon, selected, cursor, text }) => {
    var selectedClass = selected ? " zodiac-element selected " : " zodiac-element ";
    selectedClass += `margin-${cursor}`;

    return (
        <div id={`carousel-item-${sign}`}
            className={`items-center cursor-pointer text-center flex-1 base-zodiac ${selectedClass}`} >
            <Link to={`/horoscopos/${text.toLowerCase()}`}>
                <div className="col-span-1">
                    {icon}
                </div>
                <div className="capitalize col-span-1 mt-6 text-md font-semibold">{ZODIAC_DICTIONARY[sign]}</div>
                <div className="col-span-1 mt-2 text-xs text-gray-500">{dates}</div>
            </Link>
        </div>
    )
}

export default ZodiacCarousel