import React from "react"
import Recommended from "../../Components/Recommended"
import { Link } from "react-router-dom"
import assets from "../../Assets"
import { ShieldCheck } from "../../Components/Icons"

const ClientService = () => {
    const sideMenuItems = [
        {
            title: "Manifiesto",
            url: "manifiesto"
        },
        {
            title: "Código de ética",
            url: "codigo-de-etica"
        },
        {
            title: "Satisfacción garantizada",
            url: "satisfaccion-garantizada"
        },
        {
            title: "Testimonios",
            url:"testimonios"
        },
        {
            title: "Contáctanos",
            url:"contacto"
        },
        {
            title: "Preguntas frecuentes",
            url:"preguntas-frecuentes"
        },
        {
            title: "Cómo funciona",
            url:"como-funciona"
        },
        {
            title: "Precios",
            url:"precios"
        }

    ]
    return <>
        <div className="px-3 max-w-md px-6 pt-3 mx-auto lg:max-w-7xl sm:max-w-3xl lg:px-0">
            <section className="grid grid-cols-1 md:grid-cols-5 gap-4">
                <div className="col-span-2 lg:pr-10 pb-3">
                    <h1 className="text-3xl font-bold mb-3">Centro de servicio al cliente </h1>
                    <sub className="block text-base">En Portal Místico estamos dedicados a ofrecerte la mejor atención y servicio, creemos de todo corazón que cada experiencia debe ser impecable, y estamos comprometidos a lograrlo.</sub>
                    <SideMenu src={sideMenuItems} />
                </div>
                <div className="col-span-3">
                    <img className="rounded" src={assets.ClientServiceHeader} />
                    <div className="block mt-5">
                        <div className="mb-5">
                            <b className="block mb-5">Gracias por darnos la oportunidad de servirte</b>
                            <p>
                                Portal Místico es una comunidad confiable de asesores espirituales que se dedican a empoderar vidas ayudando a las personas a descubrir respuestas a sus preguntas más urgentes, lograr un cierre, encontrar su propósito en la vida y más. No hay dos expertos en nuestra comunidad iguales, ya que cada uno tiene su propio toque personal cuando se trata de brindar servicios intuitivos y espirituales.
                            </p>
                        </div>
                        <div className="mb-5">
                            <p>
                                Nuestro equipo de servicio al cliente está disponible los siete días de la semana, las 24 horas del día, para hacer de tu experiencia en nuestra plataforma de psíquicos, simple, directa y satisfactoria siempre.
                            </p>
                        </div>
                        <div className="mb-5">
                            <b className="block mb-5">Seguridad y confianza</b>
                            <p>
                                Nuestra prioridad es conectarte con los mejores expertos psíquicos en la industria mientras protegemos tu seguridad y garantizamos una experiencia de cliente de primer nivel.
                                Portal Místico actúa como barrera entre tu y tu experto psíquico. Nos aseguramos de que tu número de teléfono, correo electrónico y cualquier otra información personal nunca sean revelados porque administramos la conexión entre tu y cualquier persona con la que contactes en la plataforma de psíquicos de Portal Místico. Al realizar las llamadas, el experto psíquico con el que estas conectando no puede ver tu número de teléfono, ni siquiera con el identificador de llamadas. Cada transacción es anónima y segura. No importa con cuantos psíquicos te conectes, ten la confianza de que tu cuenta es privada y segura. Cuando te registras solo necesitas ingresar tu información personal una sola vez y listo.
                            </p>
                        </div>
                        <div className="mb-5">
                            <p>
                                ¡Contáctanos cuando quieras! Estamos para servirte.
                                Llámanos al 3311421316 o envíanos un correo electrónico a hola@portalmistico.com
                            </p>
                        </div>
                    </div>
                    <div className="relative mt-20 w-full md:w-96 xl:w-full max-w-xs xl:max-w-lg rounded-lg h-24 md:h-28 xl:h-32" style={{ backgroundColor: "#EBEFFF" }}>
                        <div className="absolute right-0 inset-y-0 w-xs h-24 md:h-28 xl:h-32 w-40 xl:w-64" style={{ backgroundColor: "#E4E8FF", borderRadius: "36px 0.5rem 0.5rem 36px" }} />
                        <div className="absolute right-0 inset-y-0 w-xs h-24 md:h-28 xl:h-32 w-24 xl:w-40" style={{ backgroundColor: "#DEE2FF", borderRadius: "36px 0.5rem 0.5rem 36px" }} />
                        <div className="flex flex-row justify-between items-center absolute inset-y-0 inset-x-4 xl:px-3">
                            <div>
                                <h1 className="text-base xl:text-2xl">Satisfacción garantizada</h1>
                                <p className="text-base xl:text-xl text-secondary font-semibold">Te ofrecemos el mejor servicio</p>
                            </div>
                            <ShieldCheck className="w-10 xl:w-16" />
                        </div>
                    </div>
                </div>
            </section>
        </div>
        <Recommended />
    </>
}

const SideMenu = ({ src, ...props }) => {
    return <div className="mt-30 pr-10 lg:pr-0">
        {
            src.map((x, i) => {
                return <div className="mb-6" key={`sub-consultation-${i}`}>
                    <Link to={`/${x.url}`} className="pb-3 border-b block max-w-xs">{x.title}</Link>
                </div>
            })
        }
    </div>
}


export default ClientService