import axios from 'axios'

const offers = {
    getOffers: async () => {
        const response = await axios.get(`/offers/list`)
        return response?.data?.payload
    },
}

export default offers
