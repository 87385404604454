import axios from 'axios'

export const credit = {
   getCredit: async () => {
      const { data } = await axios.get('/profile/credit')
      return data
   },
}

export default credit
