import NumberList from "./NumericList"

const ExpMonthPicker = (props) => {
   let selectableMonths = []

   for (let m = 1; m <= 12; m++) {
      selectableMonths.push(m)
   }

   return <NumberList items={selectableMonths} {...props} maxLength={2} />
}

export default ExpMonthPicker