import React from "react"
import Recommended from "../../Components/Recommended"
import { Link } from "react-router-dom"
import assets from "../../Assets"
import { ShieldCheck } from "../../Components/Icons"

const Manifest = () => {
  const sideMenuItems = [
    {
      title: "Manifiesto",
      url: "manifiesto"
    },
    {
      title: "Código de ética",
      url: "codigo-de-etica"
    },
    {
      title: "Satisfacción garantizada",
      url: "satisfaccion-garantizada"
    },
    {
      title: "Testimonios",
      url:"testimonios"
    },
    {
      title: "Contáctanos",
      url:"contacto"
    },
    {
      title: "Preguntas frecuentes",
      url:"preguntas-frecuentes"
    },
    {
      title: "Cómo funciona",
      url:"como-funciona"
    },
    {
      title: "Precios",
      url:"precios"
    }

  ]
  return <>
    <div className="px-3 max-w-md px-6 pt-3 mx-auto lg:max-w-7xl sm:max-w-3xl lg:px-0">
      <section className="grid grid-cols-1 md:grid-cols-5">
        <div className="col-span-2 lg:pr-10 pb-3">
          <h1 className="text-3xl font-bold mb-3">Manifiesto</h1>
          <sub className="block text-base"></sub>
          <SideMenu src={sideMenuItems} />
        </div>
        <div className="col-span-3">
          <img className="rounded" src={assets.Manifest} alt="Manifest" />
          <div className="block mt-5">
            <div className="mb-5">
              <b className="block mb-5">Estamos muy contentos de que estés aquí y conozcas a nuestros psíquicos de confianza.</b>
              <p className="mb-2">
                En Portal Místico estamos dedicados a ofrecer servicios psíquicos seguros, confiables y profesionales.
                Los expertos pueden ayudarte en tu camino a la felicidad, el éxito y la realización. Cuando haces una consulta en Portal Místico, creas una conexión con uno de nuestros psíquicos expertos quien te ayudará a llegar a tus fuerzas internas y recursos para que logres tus metas.
                Nuestros expertos crean una atmósfera cálida y enriquecedora en la cual podrás discutir hasta las situaciones más íntimas de manera segura.

              </p>
              <ol className="mb-5 pl-4">
                <li className="list-disc">Seguro</li>
                <li className="list-disc">Profesional</li>
                <li className="list-disc">Confiable</li>
                <li className="list-disc">Escuchar</li>
                <li className="list-disc">Apoyo</li>
                <li className="list-disc">Conveniente</li>
                <li className="list-disc">Personal</li>
                <li className="list-disc">Auténtico</li>
                <li className="list-disc">Íntimo</li>
                <li className="list-disc">Perspectivas</li>
                <li className="list-disc">Comprensivo</li>
                <li className="list-disc">Amigable</li>
                <li className="list-disc">Orientación</li>
                <li className="list-disc">Confidencialidad</li>
                <li className="list-disc">Transparencia</li>
                <li className="list-disc">Pasión</li>
                <li className="list-disc">Compromiso</li>
                <li className="list-disc">Accesible</li>
                <li className="list-disc">Atento</li>
                <li className="list-disc">Compasivo</li>
                <li className="list-disc">Privacidad</li>
                <li className="list-disc">Comodidad</li>
                <li className="list-disc">Validación</li>
                <li className="list-disc">Respeto</li>
                <li className="list-disc">Cortesía</li>
                <li className="list-disc">Comunidad mundial</li>
                <li className="list-disc">Servicio</li>
                <li className="list-disc">Motivación</li>
                <li className="list-disc">Positivismo</li>
                <li className="list-disc">Amable</li>
              </ol>
            </div>
            <div className="mb-5">
              <p>
                Nos orgullece nuestra comunidad de psíquicos atentos, compasivos, con gran experiencia y conocimiento.
                Nuestros psíquicos están aquí para apoyarte, proporcionando recomendaciones precisas y directas, para motivarte a dar los siguientes pasos.

              </p>
            </div>
            <div className="mb-5">
              <b className="block mb-5">Obtén las respuestas que necesitas y haz que las cosas sucedan.</b>
              <p>
                Sigue adelante con confianza. Vive la experiencia de una consulta psíquica ahora mismo.
              </p>
            </div>
          </div>
          <div className="relative mt-20 w-full md:w-96 xl:w-full max-w-xs xl:max-w-lg rounded-lg h-24 md:h-28 xl:h-32" style={{ backgroundColor: "#EBEFFF" }}>
            <div className="absolute right-0 inset-y-0 w-xs h-24 md:h-28 xl:h-32 w-40 xl:w-64" style={{ backgroundColor: "#E4E8FF", borderRadius: "36px 0.5rem 0.5rem 36px" }} />
            <div className="absolute right-0 inset-y-0 w-xs h-24 md:h-28 xl:h-32 w-24 xl:w-40" style={{ backgroundColor: "#DEE2FF", borderRadius: "36px 0.5rem 0.5rem 36px" }} />
            <div className="flex flex-row justify-between items-center absolute inset-y-0 inset-x-4 xl:px-3">
              <div>
                <h1 className="text-base xl:text-2xl">Satisfacción garantizada</h1>
                <p className="text-base xl:text-xl text-secondary font-semibold">Te ofrecemos el mejor servicio</p>
              </div>
              <ShieldCheck className="w-10 xl:w-16" />
            </div>
          </div>
        </div>
      </section>
    </div>
    <Recommended />
  </>
}

const SideMenu = ({ src, ...props }) => {
  return <ul className="mt-30 pr-10 lg:pr-0">
    {
      src.map((x, i) => {
        return <li className="mb-6" key={`sub-consultation-${i}`}>
          <Link to={`/${x.url}`} className="pb-3 border-b block max-w-xs">{x.title}</Link>
        </li>
      })
    }
  </ul>
}


export default Manifest