import React from "react"
import { IconClose } from "../Icons"

const ModalDialog = ({ visible, close, ...props }) => {
    return (visible && <div className="fixed flex h-full justify-center items-center cursor-auto w-full top-0 left-0 right-0 bottom-0 z-10" style={{ width: "100vw", height: "100%" }}>
        {/*transparent background that hides modal when clicked*/}

        <div
            className={"fixed flex inset-0 z-10 bg-black opacity-70 w-full"}
            style={{}}
            onClick={() => close()}
        />
        {/*actual modal*/}
        <div className="absolute z-10 pt-5 pb-2 bg-white border rounded-2xl shadow-lg w-4/5 md:w-full p-3 md:p-10 relative"
            style={{ maxWidth: 600, maxHeight:'90%' }}>
            <span className="inline-block rounded-full w-8 h-8 flex items-center justify-center bg-white absolute -top-2 -right-2 cursor-pointer"
                onClick={() => close()}>
                <IconClose stroke="black" />
            </span>
            {props.children}
        </div>
    </div>
    )
}

export default ModalDialog