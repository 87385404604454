import React, { useContext } from "react"
import { Formik, Field, Form, ErrorMessage } from "formik"
import { EnvelopeBGIcon, PhoneBGIcon, IconLoader, ShieldCheck, ContactMessageImage } from "../../Components/Icons"
import * as yup from 'yup'
import { SecondaryButton } from "../../Components/Button";
import contact from "../../integrations/contact"
import { ToastError, ToastSuccess } from "../../Components/Toast";
import { Context } from '../../store/useGlobalState'

const schema = yup.object().shape({
    firstName: yup.string().min(2, "Mínimo dos caracteres").required("Campo requerido"),
    lastName: yup.string().min(2, "Mínimo dos caracteres").required("Campo requerido"),
    email: yup.string().email('Correo no válido').required("Campo requerido"),
    comments: yup.string().min(2, "Mínimo dos caracteres").required("Campo requerido"),
})
const Contact = () => {
    const { globalState } = useContext(Context)
    const INITIAL_FORM_DATA = {
        firstName: '',
        lastName: '',
        email: '',
        comments: ""
    }
    const handleSubmit = async (values, actions) => {
        try {
            const res = await contact.send(values)
            console.log("res", res)
            actions.resetForm({ values: INITIAL_FORM_DATA })
            ToastSuccess('Solicitud enviada.')
        } catch (error) {
            ToastError('Se produjo un error, intente más tarde.')
        }
        actions.setSubmitting(false);
    }
    const MyErrMsg = msg => <span className="h-px text-xs text-red-500">{msg}</span>

    return <div className="w-full pt-20 max-w-md px-6 pt-3 mx-auto lg:max-w-7xl sm:max-w-3xl lg:px-0">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <div className="col-span-1">
                <div className="hidden xl:block absolute left-64 md:z-0  md:top-32 rounded" style={{ width: 325, height: 151, backgroundColor: "#F4F6FD" }} />

                <FloatingSqare width={14} height={14} left={38} top={10} color="#FCEBFB" />
                <FloatingSqare width={18} height={18} left={37} top={13} color="#BB3996" />
                <FloatingSqare width={14} height={14} left={34} top={14} color="#FCEBFB" />
                <FloatingSqare width={12} height={12} left={36} top={18} color="#BB3996" />

                <ContactMessageImage className="w-full md:absolute md:left-0 md:z-0 md:max-w-xs lg:max-w-lg" />
                <h1 className="text-3xl font-bold mb-20 mt-8 md:mt-32 lg:mt-64">Información de contacto </h1>
                <div className="flex flex-row items-center mb-8">
                    <PhoneBGIcon className="mr-5" style={{ width: 32, height: 31 }} />
                    <div>
                        <p className="mb-1 font-semibold">Teléfono</p>
                        <a href={`tel:${globalState.contactNumber}`}>{globalState.contactNumber}</a>
                    </div>
                </div>
                <div className="flex flex-row items-center">
                    <EnvelopeBGIcon className="mr-5" style={{ width: 32, height: 31 }} />
                    <div>
                        <p className="mb-1 font-semibold">Correo de servicio al cliente</p>
                        <a href={`mailto:${globalState.contactEmail}`}>{globalState.contactEmail}</a>
                    </div>
                </div>
                <div className="relative mt-20 w-full md:w-96 xl:w-full max-w-xs xl:max-w-lg rounded-lg h-24 md:h-28 xl:h-32" style={{ backgroundColor: "#EBEFFF" }}>
                    <div className="absolute right-0 inset-y-0 w-xs h-24 md:h-28 xl:h-32 w-40 xl:w-64" style={{ backgroundColor: "#E4E8FF", borderRadius: "36px 0.5rem 0.5rem 36px" }} />
                    <div className="absolute right-0 inset-y-0 w-xs h-24 md:h-28 xl:h-32 w-24 xl:w-40" style={{ backgroundColor: "#DEE2FF", borderRadius: "36px 0.5rem 0.5rem 36px" }} />
                    <div className="flex flex-row justify-between items-center absolute inset-y-0 inset-x-4 xl:px-3">
                        <div>
                            <h1 className="text-base xl:text-2xl">Satisfacción garantizada</h1>
                            <p className="text-base xl:text-xl text-secondary font-semibold">Te ofrecemos el mejor servicio</p>
                        </div>
                        <ShieldCheck className="w-10 xl:w-16" />
                    </div>
                </div>
            </div>
            <div className="col-span-1">
                <h1 className="text-3xl font-bold mb-5">Ponte en contacto con nosotros </h1>
                <p>¿Tienes alguna queja o comentario para nosotros?</p>
                <p>Llena el siguiente formulario y nuestro equipo se podrá en contacto contigo.</p>
                <div className="mt-10">
                    <Formik
                        initialValues={INITIAL_FORM_DATA}
                        onSubmit={(values, actions) => { handleSubmit(values, actions) }}
                        validationSchema={schema}
                    >
                        {({ isSubmitting, errors, touched, resetForm, dirty, values }) => (
                            <Form>
                                <div className="grid grid-cols-1 md:grid-cols-2 gap-5">
                                    <div className="col-span-1">
                                        <label className="block mb-1 text-xs font-semibold"
                                            htmlFor="firstName">Nombre(s)</label>
                                        <ErrorMessage name="firstName" render={MyErrMsg} />
                                        <Field
                                            className={`relative text-sm rounded-md w-full px-3 py-2 border ${errors.firstName && touched.firstName ? 'border-red-500' : ''} h-10 focus:outline-none focus:border-secondary`}
                                            name="firstName"
                                            disabled={isSubmitting}
                                        />
                                    </div>
                                    <div className="col-span-1">
                                        <label className="block mb-1 text-xs font-semibold"
                                            htmlFor="lastName">Apellido(s)</label>
                                        <ErrorMessage name="lastName" render={MyErrMsg} />
                                        <Field
                                            className={`relative text-sm rounded-md w-full px-3 py-2 border ${errors.lastName && touched.lastName ? 'border-red-500' : ''} h-10 focus:outline-none focus:border-secondary`}
                                            name="lastName"
                                            disabled={isSubmitting}
                                        />
                                    </div>
                                    <div className="col-span-1 md:col-span-2">
                                        <label className="block mb-1 text-xs font-semibold"
                                            htmlFor="email">Correo</label>
                                        <ErrorMessage name="email" render={MyErrMsg} />
                                        <Field
                                            className={`relative text-sm rounded-md w-full px-3 py-2 border ${errors.email && touched.email ? 'border-red-500' : ''} h-10 focus:outline-none focus:border-secondary`}
                                            name="email"
                                            disabled={isSubmitting}
                                        />
                                    </div>
                                    <div className="col-span-1 md:col-span-2">
                                        <label className="block mb-1 text-xs font-semibold"
                                            htmlFor="comments">Comentarios</label>
                                        <ErrorMessage name="comments" render={MyErrMsg} />
                                        <Field
                                            className={`h-30 relative text-sm rounded-md w-full px-3 py-2 border ${errors.comments && touched.comments ? 'border-red-500' : ''} h-10 focus:outline-none focus:border-secondary`}
                                            name="comments"
                                            as="textarea"
                                            disabled={isSubmitting}
                                        />
                                    </div>
                                </div>
                                {isSubmitting
                                    ? <SecondaryButton
                                        type="button"
                                        className="px-5 py-3 text-sm w-26 ml-auto">
                                        <IconLoader
                                            className="text-white fill-current animate-spin"
                                            style={{ height: 20 }}
                                        />
                                    </SecondaryButton>
                                    : <SecondaryButton
                                        className="px-5 py-3 text-sm text-white ml-auto mt-10"
                                        label="Enviar comentario"
                                    />
                                }
                            </Form>
                        )}
                    </Formik>
                </div>
            </div>
        </div>
    </div>
}

const FloatingSqare = ({ width, height, left, top, color, ...props }) => {
    return <div className="rounded hidden xl:block absolute md:z-0" style={{
        width,
        height,
        left: `${left}em`,
        top: `${top}em`,
        backgroundColor: color
    }} {...props} />
}

export default Contact