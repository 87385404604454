import { UserProfileIcon } from "../Icons";


const ExpertAvatar = ({ src = null, className = "w-30 h-30" }) => {
    if (!src || src === "") {
        return <UserProfileIcon className={`cursor-pointer ${className}`} />
    }
    return <div className={`bg-no-repeat bg-cover bg-center rounded-full ${className}`} style={{ backgroundImage: `url(${src})` }} />
}

export default ExpertAvatar