import React, { Component } from 'react';
import { Route, Switch } from 'react-router-dom';
import PublicLayout from '../Layouts/PublicLayout';
import routes from './Routes';

class RouterConfig extends Component {
    render() {
        return (
            <Switch>
                {routes.map((route) => {
                    let Layout = route.layout ? route.layout : PublicLayout
                    return <Route
                        key={route.id}
                        path={route.path}
                        exact={route.exact}
                        render={(props) => (
                            <Layout {...props}>
                                <route.component  {...props} />
                            </Layout>
                        )}
                    />
                })}
            </Switch>
        );
    }
}
export default RouterConfig;