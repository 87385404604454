import { Link } from "react-router-dom"

const SitemapComponent = () => {
    return (
        <div>
            <h1 className="text-xl">Mapa del sitio</h1>
            <div className="mt-10 grid grid-cols-3">
                <div>
                    <div>
                        <h2>Inicio</h2>
                        <div className="flex flex-col">
                            <Link to="" className="text-blue-500">Página inicial</Link>
                            <Link to="/registrarse" className="text-blue-500">Regístrate</Link>
                            <Link to="/peticion-de-expertos" className="text-blue-500">Solicitudes de expertos</Link>
                        </div>
                    </div>
                    <div className="mt-4">
                        <h2>Servicios</h2>
                        <div className="flex flex-col">
                            <Link to="/articulos" className="text-blue-500">Artículos</Link>
                            <Link to="/consultas" className="text-blue-500">Consultas</Link>
                            <Link to="/expertos" className="text-blue-500">Expertos</Link>
                            <Link to="/ofertas" className="text-blue-500">Ofertas</Link>
                        </div>
                    </div>
                </div>
                <div>
                    <div>
                        <h2>Horóscopos</h2>
                        <div className="flex flex-col">
                            <Link to="/horoscopos" className="text-blue-500">Ver todos</Link>
                            <Link to="/horoscopos/acuario" className="text-blue-500">Acuario</Link>
                            <Link to="/horoscopos/piscis" className="text-blue-500">Piscis</Link>
                            <Link to="/horoscopos/aries" className="text-blue-500">Aries</Link>
                            <Link to="/horoscopos/tauro" className="text-blue-500">Tauro</Link>
                            <Link to="/horoscopos/geminis" className="text-blue-500">Géminis</Link>
                            <Link to="/horoscopos/cancer" className="text-blue-500">Cáncer</Link>
                            <Link to="/horoscopos/leo" className="text-blue-500">Leo</Link>
                            <Link to="/horoscopos/virgo" className="text-blue-500">Virgo</Link>
                            <Link to="/horoscopos/libra" className="text-blue-500">Libra</Link>
                            <Link to="/horoscopos/escorpion" className="text-blue-500">Escorpión</Link>
                            <Link to="/horoscopos/sagitario" className="text-blue-500">Sagitario</Link>
                            <Link to="/horoscopos/capricornio" className="text-blue-500">Capricornio</Link>
                        </div>
                    </div>
                </div>
                <div>
                    <div>
                        <h2>Otros</h2>
                        <div className="flex flex-col">
                            <Link to="/como-funciona" className="text-blue-500">¿Cómo funciona?</Link>
                            <Link to="/codigo-de-etica" className="text-blue-500">Código de ética</Link>
                            <Link to="/contacto" className="text-blue-500">Contacto</Link>
                            <Link to="/manifiesto" className="text-blue-500">Manifiesto</Link>
                            <Link to="/politicas-de-privacidad" className="text-blue-500">Políticas de privacidad</Link>
                            <Link to="/precios" className="text-blue-500">Precios</Link>
                            <Link to="/preguntas-frecuentes" className="text-blue-500">Preguntas frecuentes</Link>
                            <Link to="/recomendaciones-tecnicas" className="text-blue-500">Recomendaciones técnicas</Link>
                            <Link to="/servicio-al-cliente" className="text-blue-500">Servicio al cliente</Link>
                            <Link to="/satisfaccion-garantizada" className="text-blue-500">Satisfacción garantizada</Link>
                            <Link to="/terminos-y-condiciones" className="text-blue-500">Términos y condiciones</Link>
                            <Link to="/testimonios" className="text-blue-500">Testimonios</Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SitemapComponent