import React, { useState, useContext, useEffect } from "react";
import Icon from "../Assets/Icon"
import { IconVideocallO, IconPhoneO } from "../Components/Icons"
import ModalCall from "../Components/ExpertModal/ModalCall"
import { SecondaryButton, LinkButton, PseudoLink } from "./Button"
import { useHistory } from "react-router-dom";
import ModalSchedule from "./ExpertModal/ModalSchedule";
import ModalScheduleSuccess from "./ExpertModal/ModalScheduleSuccess";
import experts from "../integrations/expert";
import appointments from "../integrations/appointments";
import moment from "moment-timezone"
import { Toast, ToastError } from "./Toast";
import { Context } from '../store/useGlobalState'
import timezoneList from "../utils/timezone"
import timeZoneParser from "../utils/timeZoneParser"
import { UserProfileIcon } from './Icons'
import ModalDialog from "./ModalDialog"
import ModalLoginForm from "../Pages/Experts/ModalLoginForm";

const timeFormat = "HH:mm";

// is between
const isBetween = function (a, b, c, closed = true) {
    const a_h = a.hour() * 100 + a.minute()
    const b_h = b.hour() * 100 + b.minute()
    const c_h = c.hour() * 100 + c.minute()
    if (closed) return (a_h >= b_h && a_h <= c_h)
    return (a_h > b_h && a_h < c_h)
}

const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

const FooterAvailable = (setOpenCall, expert, setOpenSchedule, setSelectedDate, handleChooseServiceType, user, available) => {
    const videoCallOnClick = async (serviceType = "video_call") => {
        handleChooseServiceType(serviceType);
        if (!user) {
            setOpenSchedule(true)
            return
        }

        setOpenCall(true);
        const res = await experts.getById(expert._id)
        if (!res?.available) {
            ToastError("El experto no está disponible");
            setOpenCall(false);
        }
        if (!res?.methods[serviceType]) {
            ToastError("El experto no tiene habilitada esta opción")
            setOpenCall(false);
        }
    }

    return <>
        {
            expert?.accept_appointments &&
            <>
                {
                    available &&
                        <SecondaryButton
                            onClick={() => {
                                setOpenSchedule(true)
                                setSelectedDate(new Date())
                            }}
                            label="Agendar cita" className="px-2 py-2 mx-auto text-xs bg-superLight w-25 rounded text-secondary my-3" />
                }
            </>

        }

        <div className="w-full px-2">
            <div className="flex justify-evenly align-center px-2 gap-4">
                <div className="w-full">
                    <p className="col-auto text-sm font-semibold tracking-tighter text-black-600">${Number(expert?.price_videocall).toFixed(2)} <span className="text-xs">MXN/min.</span></p>
                    <>
                        {
                            available ?
                                <div onClick={() => videoCallOnClick()} className="mr-2 px-3 py-2 bg-secondary-slight rounded cursor-pointer w-full">
                                    <IconVideocallO className="mx-auto" stroke="white" width="20" />
                                </div> :
                                <LinkButton className="mr-2 p-2 bg-superLight rounded" url={`/experto/${expert.username}`}>
                                    <IconVideocallO className="mx-auto" stroke="blue" width="20" />
                                </LinkButton>
                        }
                    </>

                </div>
                <div className="w-full">
                    <p className="col-auto text-sm font-semibold tracking-tighter text-black-600">${Number(expert?.price_phonecall).toFixed(2)} <span className="text-xs">MXN/min.</span></p>
                    <>
                        {
                            available ?
                                <PseudoLink onClick={() => videoCallOnClick("internet_call")} className="ml-2 py-2 bg-secondary-slight rounded w-full">
                                    <IconPhoneO stroke="white" width="20" style={{ stroke: 'white' }} />
                                </PseudoLink> :
                                <LinkButton className="ml-2 p-2 bg-superLight rounded" url={`/experto/${expert.username}`}>
                                    <IconPhoneO stroke="blue" width="20" />
                                </LinkButton>
                        }
                    </>
                </div>
            </div>
        </div>
    </>
}

const CardExpert = ({ id, photo, name, stars, title, available, expert, isRecComp=false }) => {
    const [openCall, setOpenCall] = useState(false)
    const [openSchedule, setOpenSchedule] = useState(false)
    const [openConfirm, setOpenConfirm] = useState(null)
    const [time, setTime] = useState('')
    const [timeToShow, setTimeToShow] = useState('')
    const [timezone, setTimezone] = useState('')
    const [serviceType, setServiceType] = useState('')
    const [selectDate, setSelectedDate] = useState(new Date())
    const [duration, setDuration] = useState(-1)
    const [sessionType, setSessionType] = useState("")
    const [calculatingInfo, setCalculatingInfo] = useState(false)
    const [confirmData, setConfirmData] = useState({})
    const [savingAppointment, setSavingAppointment] = useState(false)
    const [finalStarts, setFinalStarts] = useState(typeof stars === 'number' ? (Math.ceil(stars) % 6) : 0)
    const { globalState } = useContext(Context)
    const history = useHistory()

    useEffect(()=>{
        setTime('')
        setSessionType('')
        setDuration(-1)
    }, [selectDate])

    const userTimeZone = moment.tz.guess(true)
    let starsIcon = new Array(5).fill(0).map((it, idx) => ({
        filled: idx < finalStarts
    }))

    const handleConfirm = async () => {
        setSavingAppointment(true)

        const { user } = globalState
        if (time && timezone && sessionType && duration>0) {
            try {
                const isAvailable = await experts?.checkAvailable2(id)

                if (!isAvailable?.available) {
                    ToastError("El experto no está disponible en este momento")
                    setSavingAppointment(false)
                    return;
                }

                if (!isAvailable?.methods[sessionType]) {
                    ToastError("El experto desactivó el tipo de sesión seleccionado")
                    setSavingAppointment(false)
                    return;
                }
            } catch (error) {
                ToastError("El experto no está disponible en este momento")
                setSavingAppointment(false)
                return;
            }

            // get the time in local timezone format
            let expertTimeZone = timezoneList.find(x => x.value === expert?.schedule?.timezone)

            if (!(expertTimeZone && expertTimeZone.utc && expertTimeZone.utc[0])) {
                ToastError("Ocurrió un error obteniendo la zona horario del experto")
                return
            }

            expertTimeZone = expertTimeZone.utc[0]

            const localTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone

            if (!localTimezone) {
                ToastError("Ocurrió un error obteniendo la zona horaria local")
                return
            }

            const localMoment = moment.tz(selectDate, localTimezone)
            const localHourMoment = moment.tz(time, timeFormat, localTimezone)
            localMoment.set("hours", localHourMoment.hour())
                .set("minutes", localHourMoment.minute())
                .set("seconds", 0)
            const expertMoment = localMoment.tz(expertTimeZone)

            setTimeToShow(time)

            const formData = {
                "time": expertMoment.format(timeFormat),
                "timeZone": timezone,
                "date": expertMoment.format("YYYY/MM/DD"),
                "expert": id,
                duration,
                sessionType
            }

            formData.timeZone = expertTimeZone

            try {
                const response = await appointments.store(formData)
                if (!response?.error) {
                    setOpenSchedule(false)
                    setOpenConfirm(true)
                    // Se vuelve a setear el dia para que vuelva a renderizar los horarios disponibles
                    setSelectedDate(selectDate)
                    setConfirmData(response)
                } else {
                    if (response?.message && response.message === 'Schedule blocked') {
                        ToastError('El experto se encuentra en sesión. Por el momento ' +
                            'su agenda no está disponible, por favor inténtalo más tarde.')
                    } else {
                        ToastError("Por favor completa la información")
                    }

                }
            } catch (error) {
                ToastError("Por favor completa la información")
            }
        }
        else if (!user) {
            ToastError("Debe iniciar sesión para agendar")
        }
        else {
            ToastError("Por favor completa la información")
        }

        setSavingAppointment(false)
    }

    const userTimeZoneObj = timezoneList.find(x => x.utc.includes(userTimeZone))
    let expertTimezone = timezoneList.find(x => x.value === expert?.schedule?.timezone)
    let ABBR = ""

    if (userTimeZoneObj && expertTimezone && expertTimezone.utc && expertTimezone.utc[0]) {
        //ABBR = expertTimezone.abbr
        expertTimezone = expertTimezone.utc[0]
    }

    const scheduleAvailability = () => {
        try {
            const now = moment.tz(userTimeZone).tz(expertTimezone)
            const workDays = expert?.schedule?.days
            const morningStart = expert?.schedule?.morning_shift?.start !== "na" ? moment.tz(expert?.schedule?.morning_shift?.start, timeFormat, expertTimezone) : null
            const morningEnd = expert?.schedule?.morning_shift?.end !== "na" ? moment.tz(expert?.schedule?.morning_shift?.end, timeFormat, expertTimezone) : null
            const afternoonStart = expert?.schedule?.afternoon_shift?.start !== "na" ? moment.tz(expert?.schedule?.afternoon_shift?.start, timeFormat, expertTimezone) : null
            const afternoonEnd = expert?.schedule?.afternoon_shift?.end !== "na" ? moment.tz(expert?.schedule?.afternoon_shift?.end, timeFormat, expertTimezone) : null

            let availability = ""
            if ((!workDays) || workDays.length === 0) {
                availability = "Horario no disponible"
            }
            else if (morningStart && ((now.hour() * 100 + now.minute()) < (morningStart.hour() * 100 + morningStart.minute()))) {
                availability = <>
                    <p>A partir de las {timeZoneParser(expert, expert.schedule.morning_shift.start, timeFormat)} {ABBR}</p>
                </>
            }
            else if (morningStart && morningEnd && isBetween(now, morningStart, morningEnd)) {
                availability = <>
                    <p>Hasta las {timeZoneParser(expert, expert.schedule.morning_shift.end, timeFormat)} {ABBR}</p>
                </>
            }
            else if (afternoonStart && ((now.hour() * 100 + now.minute()) < (afternoonStart.hour() * 100 + afternoonStart.minute()))) {
                availability = <>
                    <p>A partir de las {timeZoneParser(expert, expert.schedule.afternoon_shift.start, timeFormat)} {ABBR}</p>
                </>
            }
            else if (afternoonStart && afternoonEnd && isBetween(now, afternoonStart, afternoonEnd)) {
                availability = <>
                    <p>Hasta las {timeZoneParser(expert, expert.schedule.afternoon_shift.end, timeFormat)} {ABBR}</p>
                </>
            }
            else {
                let days = 1
                let nextWorkDay = now.clone()
                while (nextWorkDay.isSame(now)) {
                    let nextDay = now.clone().add(days, "days")
                    if (workDays.find(x => x === nextDay.locale("en").format("ddd"))) {
                        nextWorkDay = nextDay
                    }
                    days++
                }
                let startAt = ""
                if (morningStart) {
                    startAt = `A partir de las ${timeZoneParser(expert, expert.schedule.morning_shift.start, timeFormat)} ${ABBR}`
                }
                else if (afternoonStart) {
                    startAt = `A partir de las ${timeZoneParser(expert, expert.schedule.afternoon_shift.start, timeFormat)} ${ABBR}`
                }
                availability = <>
                    <p>Hasta el {capitalizeFirstLetter(nextWorkDay.locale("es").format("dddd DD/MM"))}</p>
                    <p>{startAt}</p>
                </>
            }
            return <div className="col-auto text-xs font-normal text-gray-500 text-center">{availability}</div>
            // Conectado hasta la 1:00 EST
        } catch (err) {
            return <p className="text-center">no definido</p>
        }
    }

    const videoCallOnClick = async (serviceType = "video_call") => {
        const res = await experts.getById(expert._id)
        if (!res?.available) {
            ToastError("El experto no está disponible")
        }
        if (!res?.methods[serviceType]) {
            ToastError("El experto no tiene habilitada esta opción")
        }
        else {
            handleChooseServiceType(serviceType)
            setOpenCall(true)
        }
    }

    const handleLoggedIn = () => {
        if (serviceType) {
            setOpenSchedule(false)
            videoCallOnClick(serviceType)
        }
    }

    const handleChooseServiceType = service => setServiceType(service)

    return (<div className="h-full">
        <div className="rounded-2xl mb-4 md:mb-0 border border-secondary border-opacity-25 hover:border-opacity-100 flex flex-col items-stretch justify-between h-full"
            style={{ minWidth: 235 }} key={id}>
            <div className="h-70 cursor-pointer"
                onClick={() => {
                    history.push(`/experto/${expert.username}`)
                }} >
                {photo ? <img className="w-full h-60 rounded-t-2xl " src={photo} title={name} alt={name} /> :
                    <div className="w-full h-60 flex flex-row items-center justify-center p-4">
                        <UserProfileIcon className="h-40" /></div>}
                <h3 className="text-base font-bold text-black-400 text-center">{name}</h3>
                <p className="flex pt-1 pb-2 text-yellow-500">
                    <span className="flex flex-row gap-x-0.5 mx-auto">
                        {
                            starsIcon.map((star, key) => (
                                <Icon className="flex-col inline-block w-4" name={star.filled ? 'filledStars' : 'unfilledStar'} key={key} />
                            ))
                        }
                    </span>
                </p>
                {available ? (<p className="col-auto text-xs font-semibold text-primary text-center">Disponible</p>) :
                        (<p className="col-auto text-xs font-normal text-gray-500 text-center">No disponible</p>)
                }
            </div>
            <div className="transition duration-500	flex flex-col pb-3 items-center content-between text-sm mt-4">
                <div className="cursor-pointer w-full pt-3" onClick={() => {
                    history.push(`/experto/${expert.username}`)
                }}>
                    {
                        (available && (!isRecComp)) ? scheduleAvailability() : ''
                    }

                </div>
                {
                    FooterAvailable(setOpenCall,
                        expert,
                        setOpenSchedule,
                        setSelectedDate,
                        handleChooseServiceType,
                        globalState?.user,
                        available) //: FooterOccuped(setOpenSchedule, expert, history, setSelectedDate)} 
                }
            </div>
        </div>
        <>
            {
                openSchedule ?
                    <>
                        {
                            globalState?.user ?
                                <ModalSchedule
                                    id={id}
                                    date={selectDate}
                                    expert={expert}
                                    time={time}
                                    timezone={timezone}
                                    setSelectedDate={setSelectedDate}
                                    setTimezone={setTimezone}
                                    setTime={setTime}
                                    sessionType={sessionType}
                                    setSessionType={setSessionType}
                                    duration={duration}
                                    setDuration={setDuration}
                                    visible={openSchedule}
                                    hideSelf={() => { setOpenSchedule(false) }}
                                    handleConfirm={handleConfirm}
                                    saving={savingAppointment}
                                    calculatingInfo={calculatingInfo}
                                    setCalculatingInfo={setCalculatingInfo}
                                /> :
                                <div>
                                    <ModalDialog visible={true} close={() => { setOpenSchedule(false) }}>
                                        <ModalLoginForm handleLoggedIn={handleLoggedIn} />
                                    </ModalDialog>
                                </div>
                        }
                    </> :
                    null
            }
        </>
        <ModalScheduleSuccess
            id={id}
            expert={expert}
            date={selectDate}
            time={timeToShow}
            visible={openConfirm}
            confirmation={confirmData}
            hideSelf={() => { setOpenConfirm(false) }}
        />
        <ModalCall
            id={id}
            expert={expert}
            visible={openCall}
            serviceType={serviceType}
            hideSelf={() => { setOpenCall(false) }}
        />
    </div>
    )
}
export default CardExpert