import { createContext, useReducer } from 'react'
import InitialState from './initialState'
import Actions from './storeActions'

// reducer function to manage and moify the state
const reducer = (state, action) => {
    //console.log(`reducer at: ${action.type}, payload:`, action.payload)

    switch (action.type) {
        case Actions.SET_TOKEN:
            return {
                ...InitialState,
                token: action.payload
            }
        case Actions.SET_USER:
            return {
                ...state,
                user: action.payload
            }
        case Actions.LOGIN:
            return {
                ...state,
                token: action.payload.token,
                user: action.payload.user
            }
        case Actions.SET_WS:
            return {
                ...state,
                ws: action.payload.ws,
            }
        case Actions.SET_TOKEN_VOICE:
            return {
                ...state,
                tokenVoice: action.payload.tokenVoice,
            }
        case Actions.SET_DEVICE:
            return {
                ...state,
                device: action.payload.device
            }
        case Actions.LOGOUT:
            return {
                ...state,
                token: null,
                tokenVoice: null,
                user: null,
                ws: null,
                device: null
            }

        case Actions.SET_GENERAL_CONFIGURATION: {
            return {
                ...state,
                mainBanner: action.payload.mainBanner,
                bannerTitle: action.payload.bannerTitle,
                bannerCaption: action.payload.bannerCaption,
                promotionalText: action.payload.promotionalText,
                contactNumber: action.payload.contactNumber,
                contactEmail: action.payload.contactEmail,
                requestsNav: action.payload.requestsNav
            }
        }

        case Actions.LINK_HOROSCOPES: {
            return {
                ...state,
                horoscopesLinkedToExperts: action.payload.horoscopesLinkedToExperts
            }
        }

        default:
            return state;
    }
}

// useGlobalState
export const useGlobalState = () => {
    const [globalState, globalDispatch] = useReducer(reducer, InitialState);

    return { globalState, globalDispatch }
}

// Context == store == global state
export const Context = createContext(InitialState)
export const StoreActions = Actions
export default useGlobalState
