import axios from 'axios'

const consultation = {
    getConsultations: async (limit, page, search, filter, order) => {
        const response = await axios.get(`/requests/list?perPage=${limit}&page=${page}`)
        return response?.data?.payload
    },
    getById: async (requestId, subrequestId = null) => {
        let query = requestId
        
        if (subrequestId) {
            query += `/${subrequestId}`
        }

        const response = await axios.get(`/requests/show/${query}`)

        if (subrequestId) {
            return response?.data?.payload?.subrequests[0]
        }

        return response?.data?.payload
    },
}

export default consultation