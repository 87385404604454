import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import Recommended from "../../Components/Recommended"
import ConsultationIndex from "./Components/ConsultationIndex"
import ConsultationInfo from "./Components/ConsultationInfo"

const ConsultationPage = () => {
    const { consultationId } = useParams()

    useEffect(() => {
        if (consultationId) {
            window.scrollTo(0, 0);
        }
    }, [consultationId])

    return <>
        {consultationId ? <ConsultationInfo /> : <ConsultationIndex />}
        <Recommended />
    </>
}

export default ConsultationPage