import React from "react";
import {
    useLocation,
} from "react-router-dom";

const NotFound = () => {
    let location = useLocation();


    return <div className="w-full py-20">
        No match for <code>{location.pathname}</code>
    </div>
}

export default NotFound