import React from "react"
import Recommended from "../../Components/Recommended"
import { Link } from "react-router-dom"
import assets from "../../Assets"
import { ShieldCheck } from "../../Components/Icons"

const Testimonials = () => {
  const sideMenuItems = [
    {
      title: "Manifiesto",
      url: "manifiesto"
    },
    {
      title: "Código de ética",
      url: "codigo-de-etica"
    },
    {
      title: "Satisfacción garantizada",
      url: "satisfaccion-garantizada"
    },
    {
      title: "Testimonios",
      url:"testimonios"
    },
    {
      title: "Contáctanos",
      url:"contacto"
    },
    {
      title: "Preguntas frecuentes",
      url:"preguntas-frecuentes"
    },
    {
      title: "Cómo funciona",
      url:"como-funciona"
    },
    {
      title: "Precios",
      url:"precios"
    }

  ]
  return <>
    <div className="px-3 max-w-md px-6 pt-3 mx-auto lg:max-w-7xl sm:max-w-3xl lg:px-0">
      <section className="grid grid-cols-1 md:grid-cols-5">
        <div className="col-span-2 lg:pr-10 pb-3">
          <h1 className="text-3xl font-bold mb-3">Testimonios</h1>
          <SideMenu src={sideMenuItems} />
        </div>
        <div className="col-span-3">
          <img className="rounded" src={assets.Testimonials} alt="Testimonials" />
          <div className="block mt-5">
            <div className="mb-5">
              <p>
                Los clientes muchas veces confían en la retroalimentación y evaluación de otros cuando toman decisiones de compra de un producto o servicio en línea. La comunidad de psíquicos en Portal Místico no es diferente. La retroalimentación de los expertos provee una perspectiva honesta de la experiencia de un usuario en base al nivel de satisfacción, lo cual te puede ayudar a encontrar el psíquico o psíquicos adecuados para ti. Te darás cuenta que las calificaciones son en tiempo real, así que no es raro que puedas leer retroalimentación de consultas que alguien tuvo hace pocos días.
              </p>
            </div>
            <div className="mb-5">
              <p>
                Al ser una buena forma para los miembros de Portal Místico de ayudarse entre si a encontrar y conectar con los mejores psíquicos, te pedimos muy atentamente que compartas tus experiencias. Por favor no incluyas detalles personales de tu consulta, como nombres, direcciones, números de teléfono, o menciones a cualquier otro psíquico que no sea el que te hizo la consulta. Así como abiertamente solicitamos, agradecemos y publicamos tanto retroalimentación positiva como negativa, también te pedimos que mantengas los comentarios de forma profesional; no publicaremos retroalimentaciones que contengan lenguaje o material inapropiado, o que sea difamatorio e irrespetuoso.
              </p>
            </div>
            <div className="mb-5">
              <p>
                Trata de ser lo más objetivo posible, sabemos que los miembros de Portal Místico lo valoran. Entre más retroalimentación y evaluaciones los expertos tienen, más información tienes tu y los demás miembros para ayudarte a decidir con cual psíquico hablar.
              </p>
            </div>

          </div>
          <div className="relative mt-20 w-full md:w-96 xl:w-full max-w-xs xl:max-w-lg rounded-lg h-24 md:h-28 xl:h-32" style={{ backgroundColor: "#EBEFFF" }}>
            <div className="absolute right-0 inset-y-0 w-xs h-24 md:h-28 xl:h-32 w-40 xl:w-64" style={{ backgroundColor: "#E4E8FF", borderRadius: "36px 0.5rem 0.5rem 36px" }} />
            <div className="absolute right-0 inset-y-0 w-xs h-24 md:h-28 xl:h-32 w-24 xl:w-40" style={{ backgroundColor: "#DEE2FF", borderRadius: "36px 0.5rem 0.5rem 36px" }} />
            <div className="flex flex-row justify-between items-center absolute inset-y-0 inset-x-4 xl:px-3">
              <div>
                <h1 className="text-base xl:text-2xl">Satisfacción garantizada</h1>
                <p className="text-base xl:text-xl text-secondary font-semibold">Te ofrecemos el mejor servicio</p>
              </div>
              <ShieldCheck className="w-10 xl:w-16" />
            </div>
          </div>
        </div>
      </section>
    </div>
    <Recommended />
  </>
}

const SideMenu = ({ src, ...props }) => {
  return <ul className="mt-30 pr-10 lg:pr-0">
    {
      src.map((x, i) => {
        return <li className="mb-6" key={`sub-consultation-${i}`}>
          <Link to={`/${x.url}`} className="pb-3 border-b block max-w-xs">{x.title}</Link>
        </li>
      })
    }
  </ul>
}


export default Testimonials