import axios from 'axios'

const experts = {
    getExperts: async (limit, page, search, filter, orderBy, desc) => {
        let query = ''
        filter?.map(value => {
            if (value.value !== "Seleccionar Todos")
                query += `&${value.type}=${value.value}`
        })
        const response = await axios.get(`/experts/list?limit=${limit}&page=${page}${search ? `&q=${search}` : ''}${query}&orderBy=${orderBy}&order=${desc}`);
        return response
    },
    pagination: async (url) => {
        const response = await axios.get(`/experts/list${url}`)
        return response
    },
    getById: async (id) => {
        const response = await axios.get(`/experts/${id}`)
        return response?.data?.payload?.expert
    },
    getAppointments: async (id, startDate, endDate) => {
        const response = await axios.get(`/experts/${id}/appointments?startDate=${startDate}&endDate=${endDate}`)
        return response?.data?.payload?.appointments
    },
    checkAvailable: async (id) => {
        const response = await axios.get(`/experts/${id}/availability`)
        return response?.data?.payload?.available
    },
    checkAvailable2: async (id) => {
        const response = await axios.get(`/experts/${id}/availability`)
        return response?.data?.payload
    },
    newAppointment: async (id, date) => {
        const formData = new FormData()
        formData.append(`date`, date)
        const response = await axios.post(`/experts/${id}/appointments?date=${date}`,{date})
        return response?.data?.payload
    },

}

export default experts
