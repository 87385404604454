import { useEffect, useState, useRef } from "react"
import assets from "../../Assets"
import CTAPrimaryButton from "../Button/CTAPrimaryButton"
import { IconOxxoPay } from "../Icons"
import { useReactToPrint } from 'react-to-print';
import { formatCurrency, multiply, sum, toNumber } from "../../utils/parsers"
import Barcode from "react-barcode"


const OxxoTicket = ({ amount, orderId, couponData }) => {
   const [_amount, setAmount] = useState(amount)
   const [printing, setPrinting] = useState(false)
   const [totalToPay, setTotalToPay] = useState(amount)
   const oxxoVoucherRef = useRef()

   useEffect(()=> {
      if(couponData && couponData.discountType && couponData.value) {
         let tempValue = 0

         if (couponData.discountType==='PRICE') {
            tempValue = amount-couponData.value
         } else {
            tempValue = Math.ceil(amount*(1- (couponData.value/100)))
         }

         setTotalToPay(tempValue>10? tempValue: 10)
      }
   }, [couponData, amount])

   const handlePrintVoucher = useReactToPrint({
      onBeforeGetContent: () => setPrinting(prev => !prev),
      content: () => oxxoVoucherRef.current,
      onAfterPrint: () => setPrinting(prev => !prev)
   });

   return (
      <div className="flex flex-row mt-12 mb-2" ref={oxxoVoucherRef}>
         <div className="flex flex-col w-full md:w-2/3 mx-auto border-2 rounded lg:w-4/5">
            <section className="flex flex-col px-4 lg:px-12">
               <div className="py-2 text-sm font-semibold text-center text-white bg-black">
                  <span>FICHA DIGITAL. NO ES NECESARIO IMPRIMIR</span>
               </div>
               <div className="grid grid-cols-3">
                  <div className="flex items-center content-center col-span-1 pr-4">
                     <img src={assets.Oxxo_Logo}></img>
                  </div>
                  <aside className="flex flex-col col-span-2 mt-8">
                     <span className="flex-row font-semibold text-gray-500">MONTO A PAGAR</span>
                     <big className="flex-row py-4 text-3xl font-bold">$ {formatCurrency(totalToPay, 'number')} <sup className="top-0">MXN</sup></big>
                     <legend className="flex-row text-sm text-gray-500">OXXO cobrará una comisión adicional al momento de realizar el pago.</legend>
                  </aside>
               </div>
               <div className="flex flex-col">
                  <span className="mt-4 font-bold text-gray-500">REFERENCIA</span>
                  <div className="flex justify-center">
                     <Barcode width={1} value={orderId} fontSize={0} />
                  </div>
                  <big className="py-2 mt-2 text-sm font-bold text-center border-2 rounded-md">{orderId}</big>
               </div>
            </section>
            <section className="flex flex-col px-4 pt-4 mt-6 text-gray-600 border-t-2 lg:px-10">
               <article className="flex flex-col">
                  <span className="flex-row mb-3 text-lg font-bold">INSTRUCCIONES</span>
                  <ol className="flex flex-col pl-4 list-decimal">
                     <li className="mb-1">Acude a la tienda OXXO más cercana. <a href="https://www.google.com/maps/search/oxxo/" className="text-secondary" target="_new">Encuéntrala aquí</a></li>
                     <li className="mb-1">Indica en caja que quieres realizar un pago de OXXOPay.</li>
                     <li className="mb-1">Dicta al cajero el número de referencia en esta ficha para que la teclee directamente en la pantalla de venta.</li>
                     <li className="mb-1">Realiza el pago correspondiente con dinero en efectivo.</li>
                     <li>Al confirmar tu pago, el cajero te entregará un comprobante impreso. En él podrás verificar que se haya realizado correctamente. Conserva este comprobante de pago.</li>
                  </ol>
                  <div className="flex-row py-2 my-4 text-center text-green-600 border-2 border-green-600 rounded-md">Al completar estos pasos recibirás un correo confirmando tu pago.</div>
               </article>
            </section>
            <section className="flex flex-col items-center my-4">
               <CTAPrimaryButton onClick={handlePrintVoucher} label="Imprimir" className="px-6 py-3 text-lg font-bold print:hidden" disabled={printing} />
            </section>
         </div>
      </div>
   )
}

export default OxxoTicket