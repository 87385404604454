import axios from 'axios'

const configurations = {
  generalConfigurations: async () => {
    const response = await axios.get(`/configurations/show`)
    return response?.data?.payload
  }
}

export default configurations;
