import React from "react"
import Recommended from "../../Components/Recommended"
import { Link } from "react-router-dom"
import assets from "../../Assets"
import { ShieldCheck } from "../../Components/Icons"

const HowWorks = () => {
  const sideMenuItems = [
    {
      title: "Manifiesto",
      url: "manifiesto"
    },
    {
      title: "Código de ética",
      url: "codigo-de-etica"
    },
    {
      title: "Satisfacción garantizada",
      url: "satisfaccion-garantizada"
    },
    {
      title: "Testimonios",
      url:"testimonios"
    },
    {
      title: "Contáctanos",
      url:"contacto"
    },
    {
      title: "Preguntas frecuentes",
      url:"preguntas-frecuentes"
    },
    {
      title: "Cómo funciona",
      url:"como-funciona"
    },
    {
      title: "Precios",
      url:"precios"
    }

  ]
  return <>
    <div className="px-3 max-w-md px-6 pt-3 mx-auto lg:max-w-7xl sm:max-w-3xl lg:px-0">
      <section className="grid grid-cols-1 md:grid-cols-5">
        <div className="col-span-2 lg:pr-10 pb-3">
          <h1 className="text-3xl font-bold mb-3">Cómo funciona</h1>
          <SideMenu src={sideMenuItems} />
        </div>
        <div className="col-span-3">
          <img className="rounded" src={assets.HowWorks} alt="HowWorks" />
          <div className="block mt-5">
            <div className="mb-5">
              <p>
                Los expertos de Portal Místico ayudan a los clientes con consejos sobre el amor y las relaciones, lecturas de tarot, preguntas de la vida, compatibilidad astrológica, y consultas psíquicas.
              </p>
            </div>
            <div className="mb-5">
              <p>
                Sin importar si ya eres cliente regular o nuevo, tenemos diversas formas de contactar a un experto de Portal Místico.
              </p>
            </div>
            <div className="mb-2">
              <p>
                Conéctate fácilmente con tus psíquicos favoritos:
              </p>
            </div>
            <div className="mb-5">
              <ol className="pl-4">
                <li className="list-decimal">Crea una cuenta</li>
                <li className="list-decimal">Agrega saldo</li>
                <li className="list-decimal">Elige al psíquico de tu preferencia</li>
                <li className="list-decimal">Haz tu consulta</li>
                <li className="list-decimal">Termina la consulta cuando tú quieras.</li>
              </ol>
            </div>
            <div className="mb-2">
              <p>
                Portal Místico está abierto 24/7 y nuestros expertos hacen lo posible por estar disponible en diversas zonas horarias, pero como son humanos y no computadoras, algunas veces, no están disponibles. Así que si has elegido a un experto y no está disponible, puedes programar una cita. No hay necesidad de que pierdas tu tiempo esperando si algún experto en específico se pone disponible. Simplemente programa una cita a la hora que te sea más conveniente.
              </p>
            </div>
          </div>
          <div className="relative mt-20 w-full md:w-96 xl:w-full max-w-xs xl:max-w-lg rounded-lg h-24 md:h-28 xl:h-32" style={{ backgroundColor: "#EBEFFF" }}>
            <div className="absolute right-0 inset-y-0 w-xs h-24 md:h-28 xl:h-32 w-40 xl:w-64" style={{ backgroundColor: "#E4E8FF", borderRadius: "36px 0.5rem 0.5rem 36px" }} />
            <div className="absolute right-0 inset-y-0 w-xs h-24 md:h-28 xl:h-32 w-24 xl:w-40" style={{ backgroundColor: "#DEE2FF", borderRadius: "36px 0.5rem 0.5rem 36px" }} />
            <div className="flex flex-row justify-between items-center absolute inset-y-0 inset-x-4 xl:px-3">
              <div>
                <h1 className="text-base xl:text-2xl">Satisfacción garantizada</h1>
                <p className="text-base xl:text-xl text-secondary font-semibold">Te ofrecemos el mejor servicio</p>
              </div>
              <ShieldCheck className="w-10 xl:w-16" />
            </div>
          </div>
        </div>
      </section>
    </div>
    <Recommended />
  </>
}

const SideMenu = ({ src, ...props }) => {
  return <ul className="mt-30 pr-10 lg:pr-0">
    {
      src.map((x, i) => {
        return <li className="mb-6" key={`sub-consultation-${i}`}>
          <Link to={`/${x.url}`} className="pb-3 border-b block max-w-xs">{x.title}</Link>
        </li>
      })
    }
  </ul>
}


export default HowWorks