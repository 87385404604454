import React, { useEffect, useState } from "react"
import assets from "../../../Assets"
import { PlayIcon, IconArrowLeft } from "../../../Components/Icons"
import ModalDialog from "../../../Components/ModalDialog"
import Loader from "../../../Components/Loader"
import recentVideo from "../../../integrations/recentVideo"

const RecentVideos = () => {
    const [videos, setVideos] = useState([])
    const [showVideo, setShowVideo] = useState(false)
    const [selectedVideo, setSelectedVideo] = useState(null)
    const [loading, setLoading] = useState(true)

    useEffect(async () => {
        try {
            const res = await recentVideo.getVideos(4, 1)
            console.log("videos",res)
            setVideos(res.data)
            setLoading(false)
        } catch (error) {
            setLoading(false)
            setVideos([])
        }
    }, [])

    const handleShowVideo = (video = null) => {
        setSelectedVideo(video)
        setShowVideo(prev => !prev)
    }
    if (loading) {
        return <Loader />
    }
    return <section>
        <h2 className="capitalize text-base font-bold text-2xl text-black-400 mb-10">Videos recientes</h2>
        {
            videos.map((x, i) => {
                return <VideoItem data={x}
                    openVideo={() => handleShowVideo(x)} key={`video-item-${i}`} />
            })
        }
        <ModalVideo data={selectedVideo} visible={showVideo} close={() => handleShowVideo()} />
    </section>
}

const VideoItem = ({ data, className, openVideo, ...props }) => {
    return <div className={`grid grid-cols-1 lg:grid-cols-2 ${className} mb-6 items-center xl:items-start`} {...props}>
        <div className="col-span-1 relative overflow-hidden p-2">
            <img src={data.imageCover} className="rounded-md w-full mb-2 h-60" />
            <div className="absolute inset-0 flex justify-center items-center">
                <PlayIcon className="cursor-pointer" onClick={() => openVideo()} style={{ width: 50, height: 50 }} />
            </div>
        </div>
        <div className="col-span-1 p-2">
            <span className="text-black-400 text-base font-semibold">{data.title}</span>
            <p className="">{data.description}</p>
        </div>
    </div>
}

const ModalVideo = ({ data, ...props }) => {
    const { visible } = props
    return visible && <ModalDialog {...props}>
        <h4 className="capitalize text-base font-bold text-2xl text-black-400 mb-10">{data.title}</h4>
        <iframe className="w-full" height="315" src={`https://www.youtube.com/embed/${data.youtubeId}`} title="YouTube video player"
            frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
    </ModalDialog>
}

export default RecentVideos