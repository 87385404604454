import React, { useEffect, useState, useContext } from "react";
import { PseudoLink, SecondaryButton } from "../../Components/Button"
import { IconArrowLeft, IconLoader } from "../../Components/Icons"
import { formatCurrency } from "../../utils/parsers"
import payment from "../../integrations/payment"
import { ToastError, ToastSuccess } from "../../Components/Toast";
import { parseErrorMsg } from "../../utils/misc";
import OxxoTicket from "../../Components/Payment/OxxoTicket";
import history from "../History"
import { Context } from '../../store/useGlobalState'
import { loadStripe } from '@stripe/stripe-js/pure';


const Loader = () => (
    <div className="flex justify-center my-30">
        <IconLoader
            className="fill-current text-secondary animate-spin"
            style={{ height: 40 }}
        />
    </div>
)

const Confirmation = ({ toPrevious, toNext, hidden, paymentMetod }) => {
    const { globalState } = useContext(Context)
    const [credits, setPurchse] = useState(0)
    const [showTicket, setShowTicket] = useState(false)
    const [total, setTotal] = useState(0)
    const [loading, setLoading] = useState(true)
    const [loadingPayment, setLoadingPayment] = useState(false)
    const [oxxoOrderId, setOxxoOrderId] = useState(null)

    useEffect(() => {
        console.log('[history]', history)
        const offer = history.location?.state?.offer
        
        if (offer) {
            const amount = offer.credits.toFixed(2);
            const total = offer.price.toFixed(2);
            setPurchse(amount);
            setTotal(total);
        }
        setLoading(false)
    }, [])

    const nameFormater = (str) => {
        const res = str.normalize("NFD").replace(/[\u0300-\u036f]/g, "")
        return res
    }
    const redirectTo = (url) => {
        window.location.href = url
    }

    const handePay = async () => {
        const { offer } = history.location?.state
        const { user } = globalState
        setLoadingPayment(true)
        if (!paymentMetod.id && paymentMetod !== 'oxxo' && paymentMetod !== 'paypal') {
            ToastError('no card selected')
            setLoadingPayment(false)
            return
        } else {
            try {
                if (paymentMetod === 'oxxo') {
                    const stripe = await loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);
                    const _paymentId = await payment.requestPaymentIntentOxxo({ credits: total, offerId: offer._id })
                    const res = await stripe.confirmOxxoPayment(_paymentId, {
                        payment_method: {
                            billing_details: {
                                name: nameFormater(`${user.name} ${user.lastname}`),
                                email: user.email
                            }
                        }
                    }, { handleActions: false });
                    if (res.error) {
                        setLoadingPayment(false)
                        return ToastError('Se produjo un error, intente más tarde');
                    }
                    const _orderId = res.paymentIntent.next_action.oxxo_display_details.number;
                    setOxxoOrderId(_orderId)
                    setShowTicket(true)
                }
                else if (paymentMetod === 'paypal') {
                    const res = await payment.requestPaymentIntentPaypal({
                        credits: total, offerId: offer._id
                    })
                    const { payment_url } = res.payload
                    redirectTo(payment_url)
                }
                else {
                    const res = await payment.requestPaymentIntent({
                        credits: total,
                        offerId: offer._id,
                        payment_method: paymentMetod.id,
                    })
                    toNext()
                    ToastSuccess('Pago realizado correctamente')
                }

            } catch (error) {
                ToastError(parseErrorMsg(error))
            }
            setLoadingPayment(false)
        }
    }

    if (!paymentMetod && !hidden)
        return <span>
            Tienes que seleccionar un método de pago
        </span>

    if (paymentMetod === 'oxxo' && showTicket) {
        return <OxxoTicket amount={total} orderId={oxxoOrderId} />
    }
    return <div className={`${hidden && 'hidden'} relative flex-1`}>
        <h1 className="text-xl leading-loose">Confirmación de pago</h1>
        <p className="font-semibold text-gray-500">Tu orden será procesada de acuerdo a la información inferior.</p>
        {loading
            ? <Loader />
            : (<div className="w-full px-2 lg:px-11 lg:py-8 pb-9">
                <div className="flex flex-col pt-4 gap-y-4">
                    <h2 className="font-semibold">Método de pago</h2>
                    <div className="flex flex-row justify-between text-sm">
                        <span className="capitalize">{paymentMetod?.id ? 'Tarjeta' : paymentMetod}</span>
                        <label>{paymentMetod?.last4 ? `**** ${paymentMetod.last4}` : ''}</label>
                    </div>
                    <hr />
                </div>

                <div className="flex flex-col pt-4 gap-y-4">
                    <h2 className="font-semibold">Resumen de pago</h2>
                    <div className="flex flex-row justify-between text-sm">
                        <label>Monto de la compra</label>
                        <span>{formatCurrency(credits)}</span>
                    </div>
                    <hr />
                </div>

                <div className="flex flex-col pt-4 gap-y-4">
                    <div className="flex flex-row justify-between text-sm">
                        <label>Total a pagar</label>
                        <span>{formatCurrency(total)}</span>
                    </div>
                </div>
            </div>
            )
        }

        {/* actions */}
        <div className="flex flex-row justify-between flex-1 w-full">
            <PseudoLink className="text-xs font-bold" onClick={toPrevious}>
                <IconArrowLeft
                    className="mr-7"
                    style={{ height: 10 }}
                />
                Regresar
            </PseudoLink>
            {loadingPayment
                ? <SecondaryButton className="w-26 px-5 py-3 text-sm" >
                    <IconLoader
                        className="text-white fill-current animate-spin"
                        style={{ height: 20 }}
                    />
                </SecondaryButton>
                : <SecondaryButton
                    disabled={loading}
                    className="w-26 px-5 py-3 text-sm"
                    onClick={() => { handePay() }}
                    label={paymentMetod === 'oxxo' ? "Finalizar" : "Pagar"} />
            }
        </div>
    </div>
}
export default Confirmation
