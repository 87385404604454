import React from "react"
import Recommended from "../../Components/Recommended"
import { Link } from "react-router-dom"
import assets from "../../Assets"
import { ShieldCheck } from "../../Components/Icons"

const Prices = () => {
  const sideMenuItems = [
    {
      title: "Manifiesto",
      url: "manifiesto"
    },
    {
      title: "Código de ética",
      url: "codigo-de-etica"
    },
    {
      title: "Satisfacción garantizada",
      url: "satisfaccion-garantizada"
    },
    {
      title: "Testimonios",
      url:"testimonios"
    },
    {
      title: "Contáctanos",
      url:"contacto"
    },
    {
      title: "Preguntas frecuentes",
      url:"preguntas-frecuentes"
    },
    {
      title: "Cómo funciona",
      url:"como-funciona"
    },
    {
      title: "Precios",
      url:"precios"
    }

  ]
  return <>
    <div className="px-3 max-w-md px-6 pt-3 mx-auto lg:max-w-7xl sm:max-w-3xl lg:px-0">
      <section className="grid grid-cols-1 md:grid-cols-5">
        <div className="col-span-2 lg:pr-10 pb-3">
          <h1 className="text-3xl font-bold mb-3">Precios</h1>
          <SideMenu src={sideMenuItems} />
        </div>
        <div className="col-span-3">
          <img className="rounded" src={assets.Prices} alt="Prices" />
          <div className="block mt-5">
            <div className="mb-5">
              <p>
                En Portal Místico siempre sabes cuanto estas pagando por tu consulta psíquica. Ya sea en línea o por teléfono, las tarifas de nuestros psíquicos están claramente marcadas y establecidas para tu conveniencia.
              </p>
            </div>
            <div className="mb-5">
              <b className="block mb-5">Oferta de cliente nuevo</b>
              <p>
                Para los clientes nuevos tenemos una tarifa especial, por única vez, en su primera compra. Debido a que esta oportunidad está disponible solo una vez, les recomendamos a nuestros clientes que aprovechen la tarifa especial en una consulta larga o varias cortas dependiendo de lo que les permita la cantidad de minutos que hayan comprado. Usa la oferta inicial para construir relaciones con los psíquicos de nuestra comunidad en Portal Místico.
              </p>
            </div>
            <div className="mb-5">
              <b className="block mb-5">Precios y tarifas regulares por minuto</b>
              <p>
                Después de que hayas usado tu oferta de cliente nuevo, la tarifa regular por minuto de los psíquicos con los que hablas hará efecto. Estas tarifas van desde $10 pesos hasta $30 pesos por minuto dependiendo del psíquico que elijas y la forma en que hagas tu consulta, ya sea por teléfono o por videollamada.
                Las tarifas por minuto de los psíquicos en nuestra comunidad están claramente  publicadas en nuestro sitio web de Portal Místico.
              </p>
            </div>
            <div className="mb-5">
              <p>
                Siempre hay oportunidades de ahorrar en tus consultas psíquicas.
                Suscríbete para recibir ofertas promocionales y las novedades de Portal Místico por email.
              </p>
            </div>
          </div>
          <div className="relative mt-20 w-full md:w-96 xl:w-full max-w-xs xl:max-w-lg rounded-lg h-24 md:h-28 xl:h-32" style={{ backgroundColor: "#EBEFFF" }}>
            <div className="absolute right-0 inset-y-0 w-xs h-24 md:h-28 xl:h-32 w-40 xl:w-64" style={{ backgroundColor: "#E4E8FF", borderRadius: "36px 0.5rem 0.5rem 36px" }} />
            <div className="absolute right-0 inset-y-0 w-xs h-24 md:h-28 xl:h-32 w-24 xl:w-40" style={{ backgroundColor: "#DEE2FF", borderRadius: "36px 0.5rem 0.5rem 36px" }} />
            <div className="flex flex-row justify-between items-center absolute inset-y-0 inset-x-4 xl:px-3">
              <div>
                <h1 className="text-base xl:text-2xl">Satisfacción garantizada</h1>
                <p className="text-base xl:text-xl text-secondary font-semibold">Te ofrecemos el mejor servicio</p>
              </div>
              <ShieldCheck className="w-10 xl:w-16" />
            </div>
          </div>
        </div>
      </section>
    </div>
    <Recommended />
  </>
}

const SideMenu = ({ src, ...props }) => {
  return <ul className="mt-30 pr-10 lg:pr-0">
    {
      src.map((x, i) => {
        return <li className="mb-6" key={`sub-consultation-${i}`}>
          <Link to={`/${x.url}`} className="pb-3 border-b block max-w-xs">{x.title}</Link>
        </li>
      })
    }
  </ul>
}


export default Prices