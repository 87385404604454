import axios from 'axios';

export const setAxiosToken = (token) => {
    // console.log("token", token)
    if (!token)
        axios.defaults.headers = {}
    else
        axios.defaults.headers = {
            'Authorization': `Bearer ${token}`,
        }
}
