import axios from 'axios'

const auth = {
    login: async (userName, passWord) => {
        const {data: response} = await axios.post(
            '/auth/login',
            { email: userName, password: passWord }
        )
        return response
    },

    shortRegister: async (formData) => {
        const {data: response} = await axios.post(
            '/auth/sign_up_short',
            formData
        )
        return response
    },

    register: async (formData) => {
        const {data: response} = await axios.post(
            '/auth/sign_up',
            formData
        )
        return response
    },

    isSignedUp: async ({email, username}) => {
        const {data: response} = await axios.get(
            `/auth/signed_up/?email=${encodeURIComponent(email)}&username=${encodeURIComponent(username)}`,
        )
        return response
    },

    requestNewPassword: async (email) => {
        const {data: response} = await axios.get(
            `/profile/reset-password/?email=${encodeURIComponent(email)}`,
        )
        return response
    },

    changePassword: async ({token, password}) => {
        const {data: response} = await axios.patch(
            `/profile/change-password?token=${token}`,
            {password}
        )
        return response
    },

    facebook: process.env.REACT_APP_URL_BACK + '/auth/facebook',
}
export default auth
